@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-item);

.#{$root} {
  @include font-size(12px, calc(16 / 12), 600);

  margin: 0;
  padding: 10px 12px;
  color: #7a7a7a;
  text-transform: uppercase;

  @include tablet {
    padding: 6px 8px;
  }

  @include mobile {
    text-align: center;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-tree-horizontal);
$sticky-opening: ns(sticky-opening);
$is-sticky-body: ns(filter-tree-horizontal-sticky-body);
$content: el($root, content);
$tooltip: ns(tooltip);
$tooltip-content: el($tooltip, content);
$tooltip-arrow: el($tooltip, arrow);
$option-list: el($root, option-list);
$option-button: el($root, button);
$filter-option: ns(filter-option);
$content-inner: el($filter-option, content-inner);
$content-inner-scrollbar: el($filter-option, content-inner--scrollbar);
$content-inner-viewmore-horizontal: el($filter-option, content-inner--viewMoreHorizontal);
$list-item: el($filter-option, listitem);
$filter-option-label: el($filter-option, label);
$filter-option-title: el($filter-option, title);
$filter-option-item-selected: el($filter-option, mobile-items-selected);
$button: ns(button);
$toolbar: ns(toolbar);
$toolbar-content: el($toolbar, content);
$viewas: ns(view-as);

.#{$sticky-opening} {
  &.#{$is-sticky-body} {
    overflow: hidden !important;

    .#{$filter-option} {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        .#{$tooltip-content} {
          top: auto !important;
          transform: translate(calc(-50% + 10.5px), -5px) rotate(0deg) !important;
          margin-top: 10px;
        }

        .#{$tooltip-arrow} {
          top: -4px;
          bottom: auto;
          transform: rotate(-135deg);
        }
      }
    }

    .#{$option-button} {
      @include tablet {
        padding-bottom: get-theme-var(filter-tree-horizontal, button-padding-bottom-expand);
        border-bottom: none;
      }
    }

    .#{$button} {
      #{mod(scroll-to-top)} {
        visibility: hidden;
      }
    }

    .#{$option-list} {
      #{mod(expand)} {
        &::before {
          @include tablet {
            display: none;
          }
        }
      }
    }
  }

  .#{$content} {
    @include tablet {
      margin: 0 auto;
      max-width: get-theme-var(general, page-width-desktop);
    }
  }

  .#{$option-list} {
    @include tablet {
      display: flex;
      flex-flow: row wrap;
    }
  }

  .#{$filter-option-title} {
    #{mod(opening)} {
      &::after {
        display: none;
      }
    }
  }
}

.#{$is-sticky-body} {
  overflow-x: hidden;

  .#{$content} {
    @include tablet {
      background-color: get-theme-var(filter-tree-horizontal, background-color) !important;
    }
  }

  .#{$toolbar-content} {
    border-bottom-color: transparent;
  }

  .#{$viewas} {
    .#{$tooltip-content} {
      transform: translate(calc(-50% + 14px), 28px) !important;
    }
  }

  &:not(.#{$sticky-opening}) {
    .#{$content} {
      &::before {
        @include pseudo(100vw, 100%, block);

        top: 0;
        left: calc(50% - 16px);
        right: 0;
        margin-left: calc(-50vw + 8px);
        background: inherit;
      }
    }

    .#{$filter-option} {
      .#{$tooltip-content} {
        z-index: 3;
        transform: translate(calc(-50% + 14px), 28px) !important;
      }

      .#{$tooltip-arrow} {
        top: -4px;
        bottom: auto;
        transform: rotate(-135deg);
      }
    }
  }

  .#{$content-inner} {
    &:not(.#{$content-inner-scrollbar}):not(.#{$content-inner-viewmore-horizontal}) {
      max-height: 50vh;
      padding-right: 10px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}

.#{$root} {
  margin-bottom: get-theme-var(filter-tree-horizontal, margin-bottom);

  @include desktop {
    width: 100%;
  }

  #{mod(block)} {
    display: block;

    .#{$filter-option} {
      position: relative;

      &:first-of-type {
        @include mobile {
          padding-top: 0;
        }
      }
    }

    .#{$filter-option-label} {
      padding: 0;
    }

    .#{$filter-option-item-selected} {
      display: none;
    }
  }

  #{mod(hidden)} {
    height: 0;
    margin-bottom: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;

    &.#{$root} {
      #{mod(block)} {
        display: none;
      }
    }
  }

  #{mod(scroll)} {
    .#{$filter-option} {
      padding: 16px 20px;
    }

    .#{$filter-option-label} {
      padding: 0;
    }
  }
}

.#{$option-list} {
  @include tablet {
    position: relative;
    padding-top: 16px;
    padding-bottom: 10px;
    margin: get-theme-var(filter-tree-horizontal, margin);
    border-top: get-theme-var(filter-tree-horizontal, border-top);
    border-bottom: get-theme-var(filter-tree-horizontal, border-bottom);
  }

  @include desktop {
    padding: get-theme-var(filter-tree-horizontal, padding);
  }

  #{mod(expand)} {
    @include tablet {
      margin: 0;
      border-top: none;
      border-bottom: none;

      &::before {
        @include pseudo(auto, 1px);

        right: 16px;
        top: 0;
        left: 16px;
        background-color: get-theme-var(filter-tree-horizontal, block-border-bottom-color);
      }
    }
  }
}

.#{$option-button} {
  @include tablet {
    display: block;
    width: 100%;
    padding-bottom: 40px;
    margin-bottom: get-theme-var(filter-tree-horizontal, button-margin-bottom);
    border-bottom: get-theme-var(filter-tree-horizontal, border-bottom);
    text-align: center;
  }

  .#{$button} {
    float: none;

    #{mod(clear)} {
      padding: 10px 24px;
    }
  }
}

.#{$content} {
  @include mobile {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

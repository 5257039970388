@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(loading-icon);
$default: el($root, default);

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.#{$root} {
  #{mod(filter)} {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    position: fixed;
    top: 50% !important;
    bottom: auto;
    left: 50%;
    right: auto;
    width: 56px;
    height: 56px;
    overflow: hidden;
    padding: 0;
    text-align: center;
    text-indent: -999em;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.302);
    border-radius: 3px;
    z-index: 9999999999;

    .#{$default} {
      position: absolute;
      left: 14px;
    }
  }

  .#{$default} {
    display: block;
    width: 28px;
    height: 28px;
    margin: 14px auto;
    border-radius: 50%;
    border-top: 3px solid #333;
    border-left: 2px solid rgba(51, 51, 51, 0.45);
    border-right: 3px solid rgba(51, 51, 51, 0.9);
    animation: loading 0.7s linear infinite;
  }
}

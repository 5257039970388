@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-item-multilevel-collections);
$first-level: el($root, firstlevel);
$listitem: el($root, listitem);

.#{$root} {
  padding: 0;

  #{mod(disabled)} {
    opacity: 0.3;
    pointer-events: none;
  }
}

.#{$listitem} {
  padding: 0;
  margin: 0;
  list-style: none;
}

.#{$first-level} {
  @include flex-space-between;

  margin-bottom: 12px;
}

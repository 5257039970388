@use 'sass:math';
@use 'theme-vars';

/// Displays
@mixin box($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-row {
  display: flex;
  flex-direction: row;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-center-column {
  @include flex-center;

  flex-direction: column;
}

@mixin flex-center-vertical {
  display: flex;
  align-items: center;
}

@mixin flex-center-horizontal {
  display: flex;
  justify-content: center;
}

@mixin display-table {
  display: table;
  width: 100%;
  height: 100%;
  table-layout: fixed;
}

@mixin display-table-cell {
  display: table-cell;
  vertical-align: middle;
}

/// Media queries
@mixin mobile {
  @media only screen and (max-width: theme-vars.$breakpointmobile) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (min-width: theme-vars.$breakpointtabletmin) {
    @content;
  }
}

@mixin tablet-down {
  @media only screen and (max-width: theme-vars.$breakpointtabletmax) {
    @content;
  }
}

@mixin tablet-only {
  @media (min-width: theme-vars.$breakpointtabletmin) and (max-width: theme-vars.$breakpointtabletmax) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: theme-vars.$breakpointdesktop) {
    @content;
  }
}

// ===== Usage =====
// h1 {
//   font-size: rem(20);
//   @include tablet {
//     font-size: rem(40);
//   }
// }

/// Font size
@mixin font-size($font-size, $line-height: normal, $font-weight: normal, $letter-spacing: normal) {
  font-size: $font-size;
  @if $line-height == normal {
    line-height: normal;
  } @else {
    line-height: $line-height;
  }
  @if $font-weight == normal {
    font-weight: 400;
  } @else {
    font-weight: $font-weight;
  }
  @if $letter-spacing == normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: $letter-spacing;
  }
}

/// Font face
@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
  @font-face {
    font-weight: $weight;
    font-style: $style;
    font-family: '#{$font-name}';
    src: url('#{$path}' + '.eot?#iefix') format('embedded-opentype'),
      url('#{$path}' + '.woff') format('woff'), url('#{$path}' + '.ttf') format('truetype'),
      url('#{$path}' + '.svg##{$font-name}') format('svg');
  }
}

// ===== Usage =====
// @include font-face(Roboto, "./assets/Roboto", normal, normal);

/// Cover Background
@mixin cover-background {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

// ===== Usage =====
// div {
//   background-image: url("cute-doggo.png");
//   @include cover-background;
// }

/// Pseudo
@mixin pseudo($width: 100%, $height: 100%, $display: inline-block, $pos: absolute, $content: '') {
  content: $content;
  position: $pos;
  display: $display;
  @include box($width, $height);
}

// ===== Usage =====
// div {
//   position: relative;
//   width: 200px;
//   height: 200px;
//   &:after {
//     @include pseudo(100px, 100px);
//   }
// }

/// Visibility
@mixin fade($type) {
  @if $type == 'hide' {
    opacity: 0;
    visibility: hidden;
    transition: visibility 1s, opacity 1s;
  } @else if $type == 'show' {
    opacity: 1;
    visibility: visible;
    transition: visibility 1s, opacity 1s;
  }
}

/// Truncate
@mixin truncate($white-space: nowrap, $overflow: hidden, $text-overflow: ellipsis) {
  white-space: $white-space;
  overflow: $overflow;
  text-overflow: $text-overflow;
}

// Scrollbars
@mixin scrollbars($size, $track-color, $thumb-color) {
  &::-webkit-scrollbar {
    width: $size;
  }

  &::-webkit-scrollbar-track {
    background: $track-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $thumb-color;
  }
}

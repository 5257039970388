@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

$root: ns(slider);

.#{$root} {
  position: relative;
  width: 100%;
  margin: 0 auto;

  #{mod(flex-column)} {
    display: flex;
    flex-direction: column;

    @include tablet {
      width: 398px;
      flex-direction: column-reverse;
    }
  }

  #{mod(thumb-vertical)} {
    .slick-slider {
      width: 300px;
      margin-left: 96px;

      @include desktop {
        width: 384px;
      }
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(pagination);

.#{$root} {
  @include flex-center;

  padding-top: get-theme-var(pagination, padding-top);
  padding-bottom: get-theme-var(pagination, padding-bottom);

  #{mod(left)} {
    justify-content: start;
  }

  #{mod(center)} {
    justify-content: center;
  }

  #{mod(right)} {
    justify-content: end;
  }
}

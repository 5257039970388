@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(breadcrumb);
$container: el($root, container);
$link: el($root, link);
$pagination: el($root, pagination);

.#{$root} {
  display: flex;
  position: absolute;
  z-index: 2;
  padding: get-theme-var(breadcrumb, padding-mobile);
  margin: 0 auto;
  max-width: get-theme-var(general, page-width-desktop);

  @include tablet {
    padding: get-theme-var(breadcrumb, padding);
  }

  #{mod(top-left)} {
    top: 0;
    left: 0;
  }

  #{mod(top-center)} {
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  #{mod(top-right)} {
    top: 0;
    right: 0;
  }

  #{mod(middle-left)} {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    justify-content: flex-start;
  }

  #{mod(middle-center)} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
  }

  #{mod(middle-right)} {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    justify-content: flex-end;
  }

  #{mod(bottom-left)} {
    left: 0;
    bottom: 0;
  }

  #{mod(bottom-center)} {
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }

  #{mod(bottom-right)} {
    bottom: 0;
    right: 0;
  }

  #{mod(on-top)} {
    position: static;
    transform: none;
  }
}

.#{$container} {
  position: relative;
  margin: get-theme-var(breadcrumb, margin);
  padding: get-theme-var(breadcrumb, padding-container);
  width: get-theme-var(breadcrumb, max-width);
}

.#{$link} {
  display: inline-block;
  position: relative;
  padding: get-theme-var(breadcrumb, padding-link);
  color: get-theme-var(breadcrumb, color);
  text-decoration: none;

  &:hover {
    color: get-theme-var(breadcrumb, hover-color);
  }

  &::before {
    @include pseudo(get-theme-var(breadcrumb, icon-size), get-theme-var(breadcrumb, icon-size));

    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(-45deg);
    border-right: 1px solid get-theme-var(breadcrumb, border-color);
    border-bottom: 1px solid get-theme-var(breadcrumb, border-color);
  }

  &:first-child {
    padding-left: 0;

    &::before {
      display: none;
    }
  }

  #{mod(no-hover)} {
    color: get-theme-var(breadcrumb, color-no-hover);

    &:hover {
      color: get-theme-var(breadcrumb, color-no-hover);
    }
  }
}

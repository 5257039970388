@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-vendor);

.#{$root} {
  @include font-size(
    get-theme-var(product-vendor, font-size),
    get-theme-var(product-vendor, line-height),
    get-theme-var(product-vendor, font-weight),
    get-theme-var(product-vendor, letter-spacing)
  );

  color: get-theme-var(product-vendor, color);
  font-family: get-theme-var(product-vendor, font-family);
  text-transform: get-theme-var(product-vendor, text-transform);
  text-align: get-theme-var(product-vendor, text-align);
  word-break: break-word;
  overflow-wrap: break-word;
  margin-bottom: get-theme-var(product-vendor, margin-bottom);
  width: 100%;

  &:hover {
    color: get-theme-var(product-title, color-hover);
    text-decoration: get-theme-var(product-title, text-decoration-hover);
  }
}

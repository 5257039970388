@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-item-multilevel-collections-thirdlevel);
$title: el($root, title);

.#{$root} {
  padding-left: 12px;
  margin: 0;
  list-style: none;
}

.#{$title} {
  padding-right: 0;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(in-collection-search);
$btn-group: el($root, btn-group);
$input: el($root, input);

.#{$root} {
  display: flex;
  position: relative;
  padding: get-theme-var(in-collection-search, padding-mobile);
  margin-top: get-theme-var(in-collection-search, margin-top-mobile);
  margin-bottom: get-theme-var(in-collection-search, margin-bottom-mobile);
  width: 100%;
  max-width: 100%;

  @include tablet {
    padding: get-theme-var(in-collection-search, padding);
    margin-top: get-theme-var(in-collection-search, margin-top-desktop);
    margin-bottom: get-theme-var(in-collection-search, margin-bottom-desktop);
  }

  #{mod(horizontal)} {
    margin: get-theme-var(in-collection-search, margin-horizontal);
    width: calc(100% - 32px);
    max-width: 592px;

    @include mobile {
      margin-bottom: get-theme-var(in-collection-search, horizontal-mobile-margin-bottom);
    }
  }
}

.#{$btn-group} {
  @include flex-center;

  position: absolute;
  top: 50%;
  right: get-theme-var(in-collection-search, button-group-right-mobile);
  height: 100%;
  transform: translateY(-50%);

  @include tablet {
    right: get-theme-var(in-collection-search, button-group-right);
  }
}

.#{$input} {
  @include font-size(
    get-theme-var(in-collection-search, font-size),
    calc(20 / 14),
    get-theme-var(in-collection-search, font-weight)
  );
  @include box(100%);
  @include truncate;

  min-height: get-theme-var(in-collection-search, input-min-height);
  padding: get-theme-var(in-collection-search, padding-input-mobile);
  margin: 0;
  background-color: get-theme-var(in-collection-search, background-color);
  border: 1px solid get-theme-var(in-collection-search, border-color);
  border-radius: get-theme-var(in-collection-search, border-radius);
  cursor: pointer;
  font-family: inherit;
  text-transform: none;

  @include tablet {
    padding: get-theme-var(in-collection-search, padding-input-desktop);
  }

  &::placeholder {
    @include truncate;

    color: get-theme-var(in-collection-search, placeholder-color);
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    outline-offset: 0;
    border-color: get-theme-var(in-collection-search, border-color-focus);
  }
}

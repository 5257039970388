@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-list-placeholder);
$item: el($root, item);
$image: el($root, image);
$text: el($root, text);

@keyframes place-holder-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.#{$root} {
  display: flex;
  flex-wrap: wrap;
  gap: get-theme-var(product-list, gap-mobile);
  margin-bottom: get-theme-var(product-list, margin-bottom);
  width: 100%;

  @include tablet {
    gap: get-theme-var(product-list, gap-desktop);
  }

  #{mod(list-col)} {
    .#{$item} {
      @include flex-row;

      width: 100%;
      align-items: center;

      .#{$image} {
        width: 120px;
        padding-top: 150px;
        margin-bottom: 0;
        flex-shrink: 0;

        @include tablet {
          width: 160px;
          padding-top: 200px;
        }
      }

      .#{$text} {
        @include flex-column;

        align-items: flex-start;
        flex-grow: 1;
        padding: 0 0 0 16px;
        width: calc(100% - 120px);

        @include tablet {
          width: calc(100% - 160px);
        }
      }
    }
  }
}

.#{$image} {
  animation: place-holder-animation 2s infinite;
  background: rgba(0, 0, 0, 0.04);
  margin-bottom: 18px;
  padding-top: 100%;

  &:empty {
    display: block;
    min-height: 220px;
  }
}

@for $i from 1 through 6 {
  .#{$root}--#{$i}-col {
    .#{$item} {
      width: calc(
        (100% / $i) -
          (get-theme-var(product-list, gap-mobile) - get-theme-var(product-list, gap-mobile) / $i)
      );

      // Follow setting desktop in admin from tablet breakpoint
      @include tablet {
        width: calc(
          (100% / $i) -
            (
              get-theme-var(product-list, gap-desktop) - get-theme-var(product-list, gap-desktop) /
                $i
            )
        );
      }
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-info);
$product-swatch: ns(product-swatch);

.#{$root} {
  display: flex;
  flex-wrap: wrap;
  gap: get-theme-var(product-info, gap);
  padding: get-theme-var(product-info, padding);

  .#{$product-swatch} {
    margin-top: get-theme-var(product-swatch, margin-top);
    margin-bottom: get-theme-var(product-swatch, margin-bottom);
  }
}

// width of autocomplete
$mobile-width-1: 302px;
$mobile-width-2: 320px;
$desktop-width-1: 800px;
$desktop-width-2: 850px;

// width of others and product columns
$column-width-1: 224px;
$column-width-2: 302px;
$column-width-3: 576px;
$column-width-4: 238px;
$column-width-5: 612px;
$column-width-6: 400px;

// product item image sizes
$product-image-size-1: calc(96 / 72) * 100%;
$product-image-size-2: calc(209.33 / 157) * 100%;
$product-image-size-3: calc(168 / 126) * 100%;
$product-image-size-4: calc(112 / 84) * 100%;
$product-image-size-5: calc(224 / 168) * 100%;

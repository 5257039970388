@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-tree-wrapper);

.#{$root} {
  #{mod(vertical)} {
    display: block !important;

    @include tablet {
      width: get-theme-var(filter-tree-vertical, width-tablet);
    }

    @include desktop {
      width: get-theme-var(filter-tree-vertical, width-desktop);
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(cta-buttons);

.#{$root} {
  z-index: 0;
  opacity: 1;
  width: 100%;
  display: flex;
  column-gap: 2px;
  align-items: flex-start;

  #{mod(hide)} {
    opacity: 0;

    @include mobile {
      opacity: 1;
    }
  }

  #{mod(column)} {
    flex-direction: column;
    row-gap: 2px;
    transition: right 0.3s ease-out;
    column-gap: unset;
  }
}

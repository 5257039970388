@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(format-currency);

.#{$root} {
  display: inline-flex;
  align-items: center;

  > span {
    display: inline-flex;
    align-items: center;
  }

  sup {
    font-size: 62.5%;
    top: auto;
    padding: get-theme-var(format-currency-sup, padding);
    vertical-align: get-theme-var(format-currency-sup, vertical-align);
  }

  #{mod(text-line-through)} {
    text-decoration: line-through;
  }

  #{mod(price-compare)} {
    @include font-size(
      get-theme-var(product-compare-price, font-size),
      inherit,
      get-theme-var(product-compare-price, font-weight)
    );

    color: get-theme-var(product-compare-price, color);
    font-family: get-theme-var(product-compare-price, font-family);
    font-style: get-theme-var(product-compare-price, font-style);
    text-decoration: line-through !important;

    .money {
      color: get-theme-var(product-compare-price, color);
      text-decoration: line-through !important;
    }
  }

  #{mod(price-sale)} {
    @include font-size(
      get-theme-var(product-sale-price, font-size),
      inherit,
      get-theme-var(product-sale-price, font-weight)
    );

    color: get-theme-var(product-sale-price, color);
    font-family: get-theme-var(product-sale-price, font-family);
    font-style: get-theme-var(product-sale-price, font-style);
    padding-right: get-theme-var(product-sale-price, padding-right);
  }

  .money {
    font-size: unset !important;
    font-weight: unset !important;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(button);
$icon: el($root, icon);
$text: el($root, text);

.#{$root} {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: get-theme-var(button, margin);
  width: initial;
  height: 100%;
  min-height: auto;
  min-width: auto;
  padding: get-theme-var(button, padding);
  border: none;
  border-radius: get-theme-var(button, border-radius);
  outline: none;
  cursor: pointer;
  transition: get-theme-var(button, transition);

  &:hover,
  &:active,
  &:focus {
    color: inherit;
    background-color: inherit;
    outline: none !important;
  }

  &[disabled] {
    border: 1px solid get-theme-var(button-disabled, border-color) !important;
    background-color: get-theme-var(button-disabled, background-color) !important;
    color: get-theme-var(button-disabled, color) !important;
    cursor: default;
  }

  #{mod(full-width)} {
    width: 100%;
  }

  #{mod(circle)} {
    border-radius: 50%;

    &::after {
      content: '';
      padding-bottom: 100%;
    }
  }

  #{mod(square)} {
    &::after {
      content: '';
      padding-bottom: 100%;
    }
  }

  #{mod(round)} {
    padding: get-theme-var(button-round, padding);
    border-radius: get-theme-var(button-round, radius);
  }

  #{mod(secondary)} {
    flex: 1;
    border: 1px solid get-theme-var(button-secondary, border-color);
    background-color: get-theme-var(button-secondary, background-color);
    color: get-theme-var(button-secondary, color);

    &:hover,
    &:active,
    &:focus {
      border-color: get-theme-var(button-secondary, border-color-hover);
      background-color: get-theme-var(button-secondary, background-color-hover);
      color: get-theme-var(button-secondary, color-hover);
    }
  }

  #{mod(primary)} {
    flex: 1;
    border: 1px solid get-theme-var(button-primary, border-color);
    background-color: get-theme-var(button-primary, background-color);
    color: get-theme-var(button-primary, color);

    &:hover,
    &:active,
    &:focus {
      border-color: get-theme-var(button-primary, border-color-hover);
      background-color: get-theme-var(button-primary, background-color-hover);
      color: get-theme-var(button-primary, color-hover);
    }
  }

  #{mod(as-quantity)} {
    @include flex-center;
    @include box(100%);

    z-index: 1;
    flex-shrink: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
    font-size: 15px;
    font-family: unset;
    line-height: calc(18 / 15);
    -webkit-text-fill-color: get-theme-var(button-as-quantity, fill-color);

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    .#{$text} {
      margin: 0;
    }
  }

  #{mod(as-remove-item-side-cart)} {
    @include box(24px);

    position: absolute;
    top: -3px;
    right: 0;
    border: none;
    background: none;
  }

  #{mod(as-search)} {
    @include flex-center;

    width: auto;
    height: get-theme-var(button-as-search, height);
    border: get-theme-var(button-as-search, border);
    position: absolute;
    left: auto;
    right: 1px;
    min-width: 68px;
    padding: 0;
    background-color: get-theme-var(button-as-search, background-color);
    border-radius: get-theme-var(button-as-search, border-radius);
    cursor: pointer;
    box-shadow: none;

    &:hover,
    &:focus {
      background-color: get-theme-var(button-as-search, background-color);
    }

    svg {
      @include box(auto);

      path {
        fill: get-theme-var(search-button-icon, fill);
      }
    }
  }

  #{mod(no-padding)} {
    padding: 0;
  }

  #{mod(as-in-collection-search)} {
    padding: get-theme-var(in-collection-search, icon-search-padding);
    background: transparent;
    border: 0;
    cursor: default;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }
  }

  #{mod(result)} {
    @include font-size(
      get-theme-var(button-result, font-size),
      get-theme-var(button-result, line-height)
    );

    padding: 14px 20px;
    width: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    border-radius: 0;
    color: #fff;
    background-color: #222;

    &:hover,
    &:active,
    &:focus {
      color: #fff;
      background-color: #222;
    }

    .#{$text} {
      line-height: inherit;
    }
  }

  #{mod(clear)} {
    @include font-size(
      get-theme-var(button-clear, font-size),
      get-theme-var(button-clear, line-height),
      get-theme-var(button-clear, font-weight)
    );

    display: inline-block;
    width: auto;
    height: auto;
    border: none;
    background: transparent;
    padding: 0;
    box-shadow: none;
    border-radius: 0;
    text-align: left;
    color: var(--boost-sd-app-filter-option-text-color, #222);
    text-decoration: none;
    cursor: pointer;
    float: right;
    word-break: break-word;
    transition-duration: 0s;

    &:hover,
    &:active,
    &:focus {
      color: var(--boost-sd-app-filter-option-text-color, inherit);
      background: transparent;
    }

    .#{$text} {
      line-height: inherit;
      margin: 0;

      @include tablet {
        white-space: normal;
      }
    }
  }

  #{mod(apply)} {
    @include font-size(
      get-theme-var(button-apply, font-size),
      get-theme-var(button-apply, line-height),
      get-theme-var(button-apply, font-weight)
    );

    display: inline-block;
    padding: 10px 42px;
    box-shadow: none;
    text-align: right;
    color: get-theme-var(button-apply, color);
    background-color: get-theme-var(button-apply, background-color);
    border: 1px solid get-theme-var(button-apply, border-color);
    border-radius: 50px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.4s ease, color 0.4s ease;
    min-width: 120px;
    max-width: 100%;

    .#{$text} {
      line-height: inherit;
      margin: 0;
    }

    &:hover,
    &:active,
    &:focus {
      color: get-theme-var(button-apply, color-hover);
      background-color: get-theme-var(button-apply, background-color-hover);
      border-color: get-theme-var(button-apply, border-color-hover);
    }
  }

  #{mod(back)} {
    height: auto;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    border-radius: 0;
    z-index: 99992;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
    }

    .#{$text} {
      @include flex-center-vertical;

      line-height: inherit;
      margin: 0;
    }

    svg {
      width: auto;
      height: auto;
    }
  }

  #{mod(hidden)} {
    visibility: hidden;
  }

  #{mod(visible)} {
    visibility: visible;
  }

  #{mod(close)} {
    padding: 0;
    border: none;
    width: 40px;
    height: 40px;
    background: none;
    box-shadow: none;
    border-radius: 0;
    cursor: pointer;

    .#{$text} {
      margin: 0;
      line-height: normal;
      overflow: visible;

      svg {
        width: auto;
        height: auto;
      }
    }
  }

  #{mod(border)} {
    border: 1px solid get-theme-var(button-border, border-color);
  }

  #{mod(left)} {
    margin-right: auto;
  }

  #{mod(center)} {
    margin-left: auto;
    margin-right: auto;
  }

  #{mod(right)} {
    margin-left: auto;
  }

  #{mod(height-fit-content)} {
    height: fit-content;
  }

  #{mod(scroll-to-top)} {
    text-align: center;
    background-color: #000;
    z-index: 2;
    width: 45px;
    height: 45px;
    position: fixed;
    right: 30px;
    top: 85%;
    cursor: pointer;
    margin: 0;
    clear: both;
    overflow: hidden;
    font-size: 0;
    border: none;
    box-shadow: none;
    opacity: 1;

    &::before {
      color: #fff;
      background: none !important;
      font-size: 16px;
      text-rendering: auto;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '';
      width: 10px;
      height: 10px;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      display: inline-block;
      transition: all 0.1s ease-in-out;
      transform: rotate(-135deg);
      vertical-align: middle;
      margin-top: 4px;
      border-width: 2px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  #{mod(scroll-to-top-style1)} {
    background-color: #000;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: #000;
    }
  }

  #{mod(scroll-to-top-style2)} {
    background-color: #f0f0f0;
    transition: all 0.3s ease-in-out;

    &::before {
      border-color: #000;
    }

    &:hover {
      background-color: #222;
    }
  }

  #{mod(large)} {
    padding: get-theme-var(button-large, padding);
  }

  #{mod(half-width)} {
    width: 50%;

    @include mobile {
      width: 100%;
    }
  }

  #{mod(truncate)} {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  #{mod(show-on-hover)} {
    opacity: 0;

    @include mobile {
      opacity: 1;
    }
  }

  #{mod(hide-on-mobile)} {
    @include mobile {
      display: none;
    }
  }

  #{mod(no-minwidth)} {
    min-width: 0;
  }
}

.#{$icon} {
  @include flex-center;
  @include box(24px);

  line-height: 0;
  pointer-events: none;

  #{mod(is-image)} {
    display: block !important;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    padding: 0 12px;
  }

  #{mod(fill-current-color)} {
    svg {
      fill: currentcolor;
      @include box(24px);
    }
  }

  #{mod(on-right)} {
    order: 2;
  }
}

.#{$text} {
  margin: 0 8px;
  pointer-events: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-family: get-theme-var(button-text, font-family);
  font-size: get-theme-var(button-text, font-size);
  font-weight: get-theme-var(button-text, font-weight);
  line-height: get-theme-var(button-text, line-height);
}

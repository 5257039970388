@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(modal);
$backdrop: el($root, backdrop);
$container: el($root, container);
$wrapper: el($root, wrapper);
$last-child: el($root, last-child);

.#{$root} {
  @include box(100%);

  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: get-theme-var(modal, z-index);
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  *::-webkit-scrollbar-track {
    margin: 4px 0;
  }

  ::-webkit-scrollbar {
    width: 16px;
  }

  ::-webkit-scrollbar-thumb {
    padding: 0 4px;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
    border-radius: 6px;
    background-color: #ccc;
    background-clip: padding-box;
  }
}

.#{$backdrop} {
  @include box(100vw, 100%);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  background-color: get-theme-var(modal, background-color-backdrop);

  &:empty {
    display: block;
  }
}

.#{$container} {
  margin: auto;
  padding: 32px 16px;
  width: 100%;
  max-width: 100%;

  @include tablet {
    padding: 16px;
    width: auto;
  }
}

.#{$wrapper} {
  position: relative;
  max-width: 100%;
  background-color: get-theme-var(modal, wrapper-background-color);

  &:focus-visible {
    outline: none;
    outline-offset: 0;
    box-shadow: none;
  }
}

.#{$last-child} {
  &:empty {
    display: block;
  }
}

// Fix scrollbar issue (redundant scrollbar)
body[class*='modal-open'] {
  overflow: hidden;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-description);

.#{$root} {
  margin-top: get-theme-var(header-description, margin-top);
  font-family: get-theme-var(header-description, font-family);
  word-break: break-word;

  #{mod(as-main-4)} {
    padding: get-theme-var(header-main-4, header-description-padding);

    @include mobile {
      padding: get-theme-var(header-main-4, header-description-mobile-padding);
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

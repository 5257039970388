@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-tree-vertical);
$vertical-scroll: ns(filter-tree-vertical--scroll);
$is-sticky-body: ns(filter-tree-vertical-sticky-body);
$sticky-style-expand: ns(sticky-style-expand);
$sticky-opening: ns(sticky-opening);
$filter-option-list: el($root, option-list);
$close-filter: el($root, close-filter);
$sticky-overlay: el($root, sticky-overlay);
$mobile-toolbar: el($root, mobile-toolbar);
$content: el($root, content);
$tooltip: ns(tooltip);
$tooltip-content: el($tooltip, content);
$tooltip-arrow: el($tooltip, arrow);
$toolbar-middle: el($root, toolbar-middle);
$show-result: el($root, show-result);
$option-title: el($root, option-title);
$filter-option: ns(filter-option);
$filter-option-label: el($filter-option, label);
$filter-option-title: el($filter-option, title);
$filter-option-icon: el($filter-option, icon);
$filter-option-item-wrapper: el($filter-option, itemwrapper);
$filter-content-inner: el($filter-option, content-inner);
$filter-option-mobile-items-selected: el($filter-option, mobile-items-selected);
$button: ns(button);
$toolbar: ns(toolbar);
$toolbar-content: el($toolbar, content);
$widthMenuDesktop: 282px;
$maxWidthMenuMobileSticky: calc(100vw - 60px);
$widthMenuMobileSticky: min(375px, $maxWidthMenuMobileSticky);

.#{$is-sticky-body} {
  .#{$content} {
    @include tablet {
      z-index: 1 !important;
      background-color: get-theme-var(filter-tree-vertical, background-color) !important;
    }
  }

  .#{$toolbar-content} {
    border-bottom: none;
  }

  .#{$filter-option} {
    &:first-child {
      .#{$tooltip-content} {
        transform: translate(calc(-50% + 14px), 28px) !important;
      }

      .#{$tooltip-arrow} {
        top: -4px;
        bottom: auto;
        transform: rotate(-135deg);
      }
    }
  }
}

.#{$sticky-opening} {
  &.#{$sticky-style-expand} {
    @include mobile {
      overflow: hidden;
    }

    .#{$button} {
      #{mod(scroll-to-top)} {
        @include mobile {
          visibility: hidden;
        }
      }
    }
  }
}

.#{$root} {
  margin-top: 0;
  margin-right: get-theme-var(filter-tree-vertical, margin-right);
  border: get-theme-var(filter-tree-vertical, border);
  border-radius: get-theme-var(filter-tree-vertical, border-radius);

  @include tablet {
    width: get-theme-var(filter-tree-vertical, width-tablet);
  }
  @include desktop {
    width: get-theme-var(filter-tree-vertical, width-desktop);
  }

  // sticky default left
  #{mod(sticky)} {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: get-theme-var(filter-tree-vertical, sticky-background-color);
    transition: transform 0.4s ease;

    @include tablet {
      @include flex-column;

      justify-content: space-between;
      width: $widthMenuDesktop;
      padding: 0;
      margin: 0;
    }
  }

  #{mod(block)} {
    display: inline-block;

    @include mobile {
      width: 100%;
      border-bottom: 1px solid get-theme-var(filter-tree-vertical, block-border-bottom-color);
      padding: 16px 16px 0;
    }

    .#{$content} {
      @include mobile {
        overflow-y: visible;
        overflow-x: initial;
      }
    }

    .#{$filter-option} {
      position: relative;

      @include mobile {
        padding-top: 28px;
        padding-bottom: 28px;
      }

      &:first-of-type {
        @include mobile {
          padding-top: 0;
        }
      }
    }

    .#{$filter-option-title} {
      @include mobile {
        font-weight: 700;

        &::before {
          @include box(0);

          content: '';
          display: inline-block;
          margin: 0 10px -2px 0;
          border: 5px solid transparent;
          border-top-color: currentColor;
        }
      }

      .#{$filter-option-icon} {
        @include mobile {
          display: none;
        }
      }
    }

    .#{$filter-option-label} {
      padding: 0;

      @include mobile {
        border-bottom: none;
      }

      #{mod(collapsed)} {
        .#{$filter-option-title} {
          &::before {
            @include mobile {
              margin-bottom: 1px;
              border-top-color: transparent;
              border-left-color: currentColor;
            }
          }
        }
      }
    }

    .#{$filter-option-mobile-items-selected} {
      display: none;
    }
  }

  #{mod(left)} {
    left: 0;
    right: unset;

    .#{$content} {
      @include tablet {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 20px 16px;
      }
    }

    .#{$filter-option} {
      &:first-child {
        .#{$tooltip-content} {
          transform: translate(calc(-50% + 14px), 28px) !important;
        }

        .#{$tooltip-arrow} {
          top: -4px;
          bottom: auto;
          transform: rotate(-135deg);
        }
      }
    }
  }

  #{mod(right)} {
    right: 0;
    left: unset;
    width: $widthMenuMobileSticky;
    max-width: $maxWidthMenuMobileSticky;
    margin: 0;
  }

  #{mod(fullWidth)} {
    width: 100%;
    max-width: 100%;

    .#{$close-filter} {
      display: none;
    }
  }

  #{mod(hidden)} {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    height: 0;
    padding: 0;
    margin: 0;

    .#{$content} {
      @include box(0);

      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      padding: 0;
    }

    &.#{$root} {
      #{mod(sticky)} {
        height: auto;
      }

      #{mod(right)} {
        transform: translateX(100%);
      }

      #{mod(left)} {
        transform: translateX(-100%);
      }
    }
  }

  #{mod(scroll)} {
    .#{$filter-option} {
      padding: 16px 20px;
    }

    .#{$filter-option-mobile-items-selected} {
      display: none;
    }

    .#{$filter-option-label} {
      padding: 0;

      #{mod(collapsed)} {
        .#{$filter-option-mobile-items-selected} {
          display: block;
        }
      }
    }
  }

  #{mod(right)},
  #{mod(fullWidth)} {
    @include flex-column;

    .#{$filter-option-label} {
      flex-direction: column;
    }

    &:not(.#{$vertical-scroll}) {
      .#{$filter-option-list} {
        height: 100%;
      }

      .#{$filter-option} {
        display: flex;
        max-height: 100%;
        padding: 0;
        border-bottom: none;
      }

      .#{$filter-option-label} {
        flex: 100%;
        width: 100%;
        margin-bottom: 0;
        border-bottom: 1px solid get-theme-var(filter-tree-vertical, label-border-bottom-color);
      }

      .#{$filter-option-title} {
        width: 100%;
      }

      .#{$filter-option-icon} {
        transform: rotate(180deg);
      }

      .#{$filter-option-item-wrapper} {
        @include flex-column;

        align-items: flex-start;
        padding: 20px;

        #{mod(scrollable)} {
          padding-right: 20px;
          overflow: hidden;
          max-height: max-content;
        }
      }

      .#{$filter-content-inner} {
        width: 100%;

        #{mod(scrollbar)} {
          max-height: none;
        }
      }
    }
  }
}

.#{$close-filter} {
  position: absolute;
  top: 0;
  z-index: 99991;
  opacity: 1;
  visibility: visible;
  transition: right 0.4s;

  @include tablet {
    display: none;
  }

  #{mod(hidden)} {
    opacity: 0;
    visibility: hidden;
  }

  #{mod(left)} {
    @include tablet {
      display: block;
      right: -40px;
    }

    &.#{$close-filter} {
      #{mod(hidden)} {
        transform: translateX(-100%);
      }
    }
  }

  #{mod(right)} {
    left: -40px;

    @include tablet {
      left: unset;
      right: -40px;
    }

    &.#{$close-filter} {
      #{mod(hidden)} {
        transform: translateX(100%);
      }
    }

    &.#{$root} {
      #{mod(block)} {
        display: none;
      }

      #{mod(right)} {
        transform: translateX(100%);
      }

      #{mod(left)} {
        transform: translateX(-100%);
      }
    }
  }
}

.#{$sticky-overlay} {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  overflow: hidden;
  background-color: get-theme-var(filter-tree-vertical, sticky-overlay-background-color);

  @keyframes filter-tree-sticky-overlay-open {
    0% {
      opacity: 0;
      visibility: hidden;
    }

    100% {
      opacity: 1;
      visibility: visible;
    }
  }

  @keyframes filter-tree-sticky-overlay-close {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.#{$mobile-toolbar} {
  display: grid;
  grid-template-columns: 50px 1fr 70px;
  padding: 10px 16px 9px;
  background-color: get-theme-var(filter-tree-vertical, mobile-toolbar-background-color);
  border-bottom: 1px solid get-theme-var(filter-tree-vertical, mobile-toolbar-border-bottom-color);

  .#{$button} {
    #{mod(back)},
    #{mod(close)} {
      display: inline-block;
      width: 50px;
    }

    #{mod(clear)} {
      width: 70px;
      text-align: right;
    }

    #{mod(back)} {
      padding-left: 6px;
    }

    #{mod(close)} {
      height: auto;
      text-align: left;
    }
  }
}

.#{$toolbar-middle} {
  @include font-size(
    get-theme-var(filter-tree-vertical, toolbar-font-size),
    get-theme-var(filter-tree-vertical, toolbar-line-height),
    get-theme-var(filter-tree-vertical, toolbar-font-weight)
  );

  height: auto;
  padding-left: 10px;
  color: var(--boost-sd-app-filter-option-text-color, #222);
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
}

.#{$content} {
  @include mobile {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @include tablet {
    padding: get-theme-var(filter-tree-vertical, padding)
      get-theme-var(filter-tree-vertical, gutter);
  }
}

.#{$show-result} {
  width: 100%;

  @include tablet {
    width: $widthMenuDesktop;
    padding: 20px 16px;
    background-color: get-theme-var(filter-tree-vertical, show-result-background-color);
    box-shadow: 0 4px 16px get-theme-var(filter-tree-vertical, show-result-box-shadow);
  }
}

// important: cover sticky default - left side. Please don't remove it.
.boost-sd-left {
  position: relative;
}

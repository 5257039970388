@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(pagination-button);
$button-icon: el($root, icon);
$button-text: el($root, text);

.#{$root} {
  @include flex-center;
  @include font-size(
    get-theme-var(pagination-button, font-size),
    normal,
    get-theme-var(pagination-button, font-weight)
  );

  height: get-theme-var(pagination-button, height-mobile);
  min-height: auto;
  min-width: auto;
  padding: get-theme-var(pagination-button, padding-mobile);
  color: get-theme-var(pagination-button, color);
  background-color: get-theme-var(pagination-button, background-color);
  border: get-theme-var(pagination-button, border-width)
    get-theme-var(pagination-button, border-style) get-theme-var(pagination-button, border-color);
  font-family: get-theme-var(pagination-button, font-family);
  text-transform: get-theme-var(pagination-button, text-transform);
  transition: get-theme-var(button, transition);
  cursor: pointer;
  outline: none;

  @include tablet {
    padding: get-theme-var(pagination-button, padding);
    height: get-theme-var(pagination-button, height);
  }

  #{mod(load-more)} {
    height: max-content;
    width: get-theme-var(pagination-load-more, width-mobile);
    min-height: 40px;
    max-width: 100%;
    font-size: get-theme-var(pagination-load-more, font-size);
    font-family: get-theme-var(pagination-load-more, font-family);
    cursor: pointer;

    @include tablet {
      width: get-theme-var(pagination-load-more, width-desktop);
    }
  }

  #{mod(circle)} {
    width: get-theme-var(pagination-button, width-mobile);
    padding: get-theme-var(pagination-number, padding-circle);
    margin: get-theme-var(pagination-number, margin-circle);
    border: get-theme-var(pagination-number, border-circle);
    border-radius: 50%;

    &:hover,
    &:focus,
    &:active {
      color: get-theme-var(pagination-number, color-selected);
      background-color: get-theme-var(pagination-number, background-color-circle-hover);
    }

    @include tablet {
      width: get-theme-var(pagination-button, min-width);
    }
  }

  #{mod(square)} {
    width: get-theme-var(pagination-button, width-mobile);
    padding: get-theme-var(pagination-number, padding-square);
    margin: get-theme-var(pagination-number, margin-square);
    border: get-theme-var(pagination-number, border-square);

    &:hover,
    &:focus,
    &:active {
      color: get-theme-var(pagination-number, color-selected);
      background-color: get-theme-var(pagination-number, background-color-square-hover);
    }

    @include tablet {
      width: get-theme-var(pagination-button, min-width);
    }
  }

  #{mod(rounded-rectangle)} {
    padding: get-theme-var(button-round, padding);
    border-radius: get-theme-var(button-round, radius);
  }
}

.#{$button-icon} {
  @include flex-center;
  @include box(16px);

  line-height: 0;
  pointer-events: none;

  #{mod(right)} {
    order: 2;

    + .#{$button-text} {
      margin-right: 4px;
    }
  }

  #{mod(left)} {
    + .#{$button-text} {
      margin-left: 4px;
    }
  }

  svg {
    @include box(12px);

    fill: currentColor;
  }
}

.#{$button-text} {
  white-space: get-theme-var(pagination-button-text, white-space);
  overflow: get-theme-var(pagination-button-text, overflow);
  text-overflow: get-theme-var(pagination-button-text, text-overflow);
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(no-search-result);
$desc: el($root, desc);

.#{$root} {
  padding: 6px;
  background-color: #fff;

  @include tablet {
    position: relative;
    padding: 12px 24px;

    &::after {
      @include pseudo(auto, 1px);

      left: 24px;
      right: 24px;
      bottom: 0;
      background-color: #f5f5f5;
    }
  }

  #{mod(havePaddingBottom)} {
    padding: 20px 24px;
  }
}

.#{$desc} {
  @include font-size(14px, calc(22 / 14));

  margin: 0;
  color: #222;
  overflow: hidden;

  strong {
    word-break: break-all;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-item);
$button: ns(button);
$info: el($root, info);
$price: el($root, price);
$image: el($root, image);
$product-image-row: ns(product-image-row);

.#{$root} {
  display: flex;
  flex-direction: column;
  position: relative;
  border-width: get-theme-var(product-item, border-width);
  border-style: get-theme-var(product-item, border-style);
  border-color: get-theme-var(product-item, border-color);
  background-color: get-theme-var(product-item, background-color);
  color: get-theme-var(product-item, color);
  text-align: get-theme-var(product-item, text-align);
  gap: get-theme-var(product-item, gap) 0;

  .#{$button} {
    margin-top: get-theme-var(product-call-to-action, button-margin-top);
    text-transform: get-theme-var(product-call-to-action, text-transform);
  }

  &:hover {
    background-color: get-theme-var(product-item, background-color-hover);
    border-color: get-theme-var(product-item, border-color-hover);

    .#{$button} {
      #{mod(show-on-hover)} {
        opacity: 1;
      }
    }
  }

  #{mod(no-border)} {
    border-width: 0;
  }

  #{mod(no-padding)} {
    border-width: 1px;
    padding: get-theme-var(product-item, padding);
  }

  #{mod(border-has-padding)} {
    border-width: 1px;
    padding: get-theme-var(product-item, padding);
    margin-top: get-theme-var(product-item, margin-top-border-has-padding);
    box-shadow: get-theme-var(product-item, box-shadow);
  }

  #{mod(border-no-padding-image)} {
    border-width: 1px;
    padding: 0;

    .boost-sd__product-info {
      padding: 0 get-theme-var(product-item, padding) get-theme-var(product-list, gap-desktop);
    }
  }

  // This code part to handle spacing between buttons in sub-layouts 1.3, 2.8
  #{mod(button-with-spacing)} {
    .#{$button} {
      & + .#{$button} {
        margin-left: get-theme-var(product-item, button-margin-left);

        #{mod(full-width)} {
          margin-left: 0;
        }
      }

      #{mod(full-width)} {
        & + .#{$button} {
          margin-left: 0;
        }
      }

      // To avoid breakline in sub-layout 1.3
      #{mod(half-width)} {
        max-width: calc(50% - calc(get-theme-var(product-item, button-margin-left) / 2));
      }
    }

    // Not add spacing to button when align vertical
    .#{$product-image-row} {
      #{mod(vertical)} {
        .#{$button} {
          margin-left: 0;
        }
      }
    }
  }
}

.#{$image} {
  position: relative;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-swatch-item);
$button: ns(filter-option-item-button);
$amount: ns(filter-option-item-amount);
$img: el($root, img);

.#{$root} {
  .#{$button} {
    padding: 0;
  }

  .#{$amount} {
    margin-top: 6px;
    margin-left: 10px;
  }

  #{mod(disabled)} {
    opacity: 0.3;
    pointer-events: none;
  }
}

.#{$img} {
  @include box(28px);

  position: relative;
  transition: border-color 0.2s;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  background-size: cover;
  border: get-theme-var(filter-option-swatch, border);

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border: get-theme-var(filter-option-swatch, border);
    border-radius: 50%;
  }

  &:hover,
  #{mod(selected)} {
    border-color: #222;

    &::before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-width: 2px;
      border-color: #fff;
    }
  }

  #{mod(list)} {
    margin-right: 10px;

    &::before {
      display: none;
    }
  }

  #{mod(square)} {
    border-radius: 0;

    // reset before of selected when mod square
    &::before {
      display: none;
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(thumbs-container);
$thumbs: el($root, thumbs);
$thumbs-item: el($root, thumbs-item);
$prev-button: el($root, prev-button);
$next-button: el($root, next-button);
$button-icon: el($root, button-icon);
$product-image-row: ns(product-image-row);
$button: ns(button);

.#{$root} {
  @include flex-column;

  align-items: center;
  order: 2;

  @include tablet {
    order: unset;
  }

  #{mod('vertical')} {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    margin: get-theme-var(slider-thumb, margin);
  }

  #{mod('horizontal')} {
    flex-direction: row;
    width: 100%;
    padding: get-theme-var(slider-thumb, mobile-padding);

    @include tablet {
      padding: get-theme-var(slider-thumb, padding);
    }
  }
}

.#{$thumbs} {
  @include flex-column;

  justify-content: center;
  width: 100%;

  #{mod('vertical')} {
    gap: get-theme-var(slider-thumb, vertical-gap);
  }

  #{mod('horizontal')} {
    flex-direction: row;
    justify-content: center;
    gap: get-theme-var(slider-thumb, mobile-horizontal-gap);
    width: 100%;

    @include tablet {
      gap: get-theme-var(slider-thumb, horizontal-gap);
      width: 290px;
    }

    .#{$thumbs-item} {
      width: 20%;
    }
  }
}

.#{$thumbs-item} {
  width: 100%;
  min-width: 20%;
  border: 1px solid get-theme-var(slider-thumb, border-color-current);
  border-radius: get-theme-var(slider-thumb, border-radius);
  cursor: pointer;

  #{mod(selected)} {
    border: 1px solid get-theme-var(slider-thumb, border-color-selected);
  }

  .#{$product-image-row} {
    display: none;
  }
}

.#{$prev-button} {
  @include box(24px);

  margin: 0 0 8px;

  #{mod('vertical')} {
    margin: 14px 0 8px;
  }

  #{mod('horizontal')} {
    margin: 0 8px 0 0;
    transform: rotate(-90deg);
  }

  .#{$button} {
    background-color: get-theme-var(slider-thumb-button, background-color);
    border: 1px solid get-theme-var(slider-thumb-button, border-color);

    &[disabled] {
      background-color: get-theme-var(slider-thumb-button, background-color-disabled) !important;
      border: 1px solid get-theme-var(slider-thumb-button, border-color-disabled) !important;
      color: get-theme-var(slider-thumb-button, color-disabled) !important;
    }
  }
}

.#{$next-button} {
  @include box(24px);

  #{mod('vertical')} {
    margin: 8px 0 0;
    transform: rotateZ(180deg);
  }

  #{mod('horizontal')} {
    margin: 0 0 0 8px;
    transform: rotate(90deg);
  }

  .#{$button} {
    background-color: get-theme-var(slider-thumb-button, background-color);
    border: 1px solid get-theme-var(slider-thumb-button, border-color);

    &[disabled] {
      color: get-theme-var(slider-thumb-button, color-disabled) !important;
      background-color: get-theme-var(slider-thumb-button, background-color-disabled) !important;
      border: 1px solid get-theme-var(slider-thumb-button, border-color-disabled) !important;
    }
  }
}

.#{$button-icon} {
  @include box(10px);
}

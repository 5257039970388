@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns('g');
$scrollable: el($root, scrollable);
$no-scroll: el($root, no-scroll);
$hide: el($root, hide);
$flex-column: el($root, flexColumn);
$no-border-top: el($root, no-border-top);
$break-word: el($root, break-word);
$text-italic-center: el($root, text-italic-center);

.#{$no-scroll} {
  overflow: hidden !important;
}

.#{$scrollable} {
  overflow: auto;
}

.#{$hide} {
  display: none;
}

.#{$flex-column} {
  @include flex-column;
}

.#{$no-border-top} {
  border: 0 !important;
}

.#{$break-word} {
  word-break: break-word;
}

.#{$text-italic-center} {
  text-align: center;
  font-style: italic;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(cta-other-page-wrapper);

.#{$root} {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(placeholder-item);

@keyframes place-holder-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.#{$root} {
  display: inline-block;
  height: 20px;
  max-width: 100%;
  min-width: 50px;
  background: rgba(0, 0, 0, 0.07);
  border-radius: 50rem;
  animation: place-holder-animation 2s infinite;

  &:not(:last-child) {
    margin-bottom: 18px;
  }

  &:empty {
    display: block;
  }

  #{mod(width1)} {
    width: 40%;
  }

  #{mod(width2)} {
    width: 65%;
  }

  #{mod(width3)} {
    width: 80%;
  }

  #{mod(width4)} {
    width: 100%;
  }

  #{mod(in-collection)} {
    height: 38px;
  }

  #{mod(product-count)} {
    height: 27px;
  }

  #{mod(sorting)} {
    height: 20px;
    display: inline-block;
  }

  #{mod(text-toggle)} {
    height: 21px;
    display: inline-block;
  }

  #{mod(limit)} {
    width: 100px;
    max-width: 100%;
    height: 23px;
  }
}

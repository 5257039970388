@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-item-button);

.#{$root} {
  position: relative;
  display: block;
  width: 100%;
  border: none;
  background: none;
  line-height: get-theme-var(filter-option-item-button, line-height);
  color: var(--boost-sd-app-filter-option-text-color, #222);
  font-size: var(--boost-sd-app-filter-option-font-size, 14px);
  font-family: var(--boost-sd-app-filter-option-font-family, inherit);
  font-weight: inherit;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus,
  &:visited,
  &:link {
    color: var(--boost-sd-app-filter-option-text-color, #222);
    background-color: inherit;
    outline: none !important;
    border: none;
  }

  #{mod(selected)} {
    font-weight: get-theme-var(filter-option-item-button, font-weight-selected);
  }

  #{mod(as-link)} {
    text-decoration: none;
  }

  #{mod(as-button)} {
    vertical-align: inherit;
    min-width: 0;
    height: auto;
    min-height: 20px;
    margin: 0;
    padding: 0;
    box-shadow: none;
    text-align: left;
    white-space: initial;
  }

  #{mod(with-checkbox)} {
    position: relative;
    padding-left: 23px;

    &::before {
      @include box(15px);

      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      display: inline-block;
      border: 1px solid get-theme-var(filter-option-item-button, checkbox-color);
      border-radius: 1px;
      background: 0 0;
      box-shadow: none;
      transition: none;
    }

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 3px;
      display: none;
      width: 9px;
      height: 6px;
      border: 2px solid get-theme-var(filter-option-item-button, checkmark-color);
      border-top: none;
      border-right: none;
      background: 0 0;
      transform: rotate(-50deg);
    }

    &.#{$root} {
      #{mod(selected)} {
        &::before {
          border-color: get-theme-var(filter-option-item-button, checkbox-color-selected);
        }

        &::after {
          display: block;
        }
      }
    }
  }

  #{mod(items-center)} {
    @include flex-center;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-title);

.#{$root} {
  @include font-size(
    get-theme-var(header-title, font-size),
    get-theme-var(header-title, line-height),
    get-theme-var(header-title, font-weight)
  );

  font-family: get-theme-var(header-title, font-family);
  color: get-theme-var(header-title, color);
  display: block;
  width: 100%;
  margin: 0;
  text-transform: get-theme-var(header-title, text-transform);
  text-align: center;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-rating-item);
$presentation: el($root, presentation);
$icon-star: el($root, icon-star);

.#{$root} {
  margin-right: -10px;

  #{mod(disabled)} {
    opacity: 0.3;
    pointer-events: none;
  }
}

.#{$icon-star} {
  display: inline-block;

  &:last-child {
    margin-right: 5px;
  }

  &::before {
    @include font-size(get-theme-var(filter-option-rating, icon-font-size), inherit, 400);

    content: '\2605';
    margin-right: 2px;
    margin-left: 2px;
    color: get-theme-var(filter-option-rating, icon-color);
    font-style: normal;
    font-variant: normal;
    text-align: center;
    text-decoration: inherit;
    text-transform: none;
  }

  #{mod(active)} {
    &::before {
      color: inherit;
    }
  }
}

.#{$presentation} {
  @include font-size(
    get-theme-var(filter-option-rating, font-size),
    get-theme-var(filter-option-rating, line-height)
  );

  margin: 0 -2px;
  white-space: nowrap;
}

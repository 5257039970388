@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(inventory-status);

.#{$root} {
  width: 100%;
  display: block;
  word-break: break-word;
  line-height: normal;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(pagination);
$number: el($root, number);

.#{$number} {
  @include box(get-theme-var(pagination-number, width), get-theme-var(pagination-number, height));
  @include flex-center;

  position: relative;
  min-height: auto;
  min-width: auto;
  padding: get-theme-var(pagination-number, padding);
  margin: get-theme-var(pagination-number, margin);
  color: get-theme-var(pagination-number, color);
  background-color: get-theme-var(pagination-number, background-color);
  font-family: get-theme-var(pagination-number, font-family);
  font-size: get-theme-var(pagination-number, font-size);
  font-weight: get-theme-var(pagination-number, font-weight);
  border: get-theme-var(pagination-number, border-width)
    get-theme-var(pagination-number, border-style) get-theme-var(pagination-number, border-color);
  border-bottom: get-theme-var(pagination-number, border-bottom);
  border-radius: get-theme-var(pagination-number, border-radius);
  box-shadow: get-theme-var(pagination-number, box-shadow);
  cursor: pointer;

  &:hover,
  &:focus {
    color: get-theme-var(pagination-number, color-hover);
    background: none;
    border-bottom: get-theme-var(pagination-number, border-bottom-hover);
  }

  #{mod(active)} {
    pointer-events: none;
    color: get-theme-var(pagination-number, color-selected);
    border-bottom: get-theme-var(pagination-number, border-bottom-active);
  }

  #{mod(circle)} {
    margin: get-theme-var(pagination-number, margin-circle);
    padding: get-theme-var(pagination-number, padding-circle);
    border: get-theme-var(pagination-number, border-circle);
    border-radius: 50%;

    &:hover,
    &:focus {
      color: get-theme-var(pagination-number, color-selected);
      background-color: get-theme-var(pagination-number, background-color-circle-hover);
    }
  }

  #{mod(square)} {
    margin: get-theme-var(pagination-number, margin-square);
    padding: get-theme-var(pagination-number, padding-square);
    border: get-theme-var(pagination-number, border-square);

    &:hover,
    &:focus {
      color: get-theme-var(pagination-number, color-selected);
      background-color: get-theme-var(pagination-number, background-color-square-hover);
    }
  }

  #{mod(disabled)} {
    pointer-events: none;
    background-color: transparent !important;
    border-color: transparent !important;
    opacity: 0.5;
  }

  #{mod(regular)} {
    font-weight: normal;
  }

  #{mod(bold)} {
    font-weight: bold;
  }

  #{mod(mobile)} {
    @include box(
      get-theme-var(pagination-number, width-mobile),
      get-theme-var(pagination-number, height-mobile)
    );
  }
}

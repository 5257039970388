@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-widget-init);
$input: el($root, input);

.#{$input} {
  &[type='text'],
  &[type='number'],
  &[type='search'] {
    margin-bottom: 0;
  }

  &:focus,
  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

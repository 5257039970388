@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(pagination-load-more-container);
$item: el($root, item);
$product-count: el($root, product-count);
$button: el($root, button);

.#{$root} {
  > .#{$button} {
    &:first-child {
      margin-bottom: 30px;
    }
  }
}

.#{$item} {
  width: 100%;
  padding: get-theme-var(pagination-load-more, padding);
  text-align: center;
}

.#{$product-count} {
  width: 100%;
  color: get-theme-var(pagination-load-more, color-product-count);
  font-family: get-theme-var(pagination-load-more, font-family);
  font-size: get-theme-var(pagination-load-more, font-size);
}

.#{$button} {
  @include flex-center-horizontal;

  margin-top: get-theme-var(pagination-load-more, margin-top-button);
  margin-bottom: get-theme-var(pagination-load-more, margin-bottom-button);

  #{mod(left)} {
    justify-content: start;
  }

  #{mod(center)} {
    justify-content: center;
  }

  #{mod(right)} {
    justify-content: end;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(dots);
$item: el($root, item);

.#{$root} {
  width: 100%;
  margin: get-theme-var(slider-dots, margin-mobile);
  text-align: center;

  @include tablet {
    margin: get-theme-var(slider-dots, margin);
  }
}

.#{$item} {
  @include box(get-theme-var(slider-dots, item-width));

  display: inline-block;
  margin: get-theme-var(slider-dots, item-margin);
  background-color: get-theme-var(slider-dots, item-background-color);
  border-radius: 100%;
  cursor: pointer;

  #{mod(active)} {
    background-color: get-theme-var(slider-dots, item-background-color-active);
  }

  &:empty {
    // This is fixed for case hidden div:empty on Dawn theme
    display: inline-block;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search);
$autocomplete: el($root, autocomplete);
$no-product: el($root, no-product);
$no-other: el($root, no-other);
$results: el($root, results);
$no-result: ns(no-search-result);
$column: el($root, column);
$group: ns(suggestion-queries-group);
$item: ns(suggestion-queries-item);
$product: el($item, product);
$dym: ns(spelling-correction);
$product-list: el($root, product-list);
$product-items: el($root, product-list-items);
$loading: el($root, loading);

@import '../SearchContentResultVariables';

.#{$results} {
  // desktop style 2 - two column
  #{mod(two-column)} {
    position: relative;

    .#{$dym} {
      padding: 20px 24px;
      background-color: transparent;

      & + .#{$product-list} {
        margin-top: -14px;
      }
    }

    .#{$no-result} {
      background-color: transparent;
    }

    .#{$loading} {
      width: $desktop-width-1;
    }

    .#{$column} {
      #{mod(others)} {
        width: $column-width-1;
        background-color: #f8f8f8;
      }

      #{mod(products)} {
        width: $column-width-2;
      }

      .#{$item} {
        #{mod(product)} {
          &:hover {
            background-color: #f8f8f8;
          }
        }
      }
    }

    .#{$group} {
      &::after {
        background-color: #e4e4e9;
      }
    }

    .#{$autocomplete} {
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
      min-width: $column-width-1;

      &.#{$no-product} {
        background-color: #f8f8f8;

        .#{$column} {
          #{mod(others)} {
            width: $column-width-2;
          }

          #{mod(products)} {
            @include box(0);
          }
        }
      }

      &.#{$no-other} {
        .#{$column} {
          #{mod(others)} {
            @include box(0);
          }
        }
      }
    }

    &.#{$results} {
      #{mod(product-type-grid)} {
        .#{$loading} {
          width: $desktop-width-2;
        }

        .#{$column} {
          #{mod(others)} {
            width: $column-width-4;
          }

          #{mod(products)} {
            width: $column-width-5;
          }
        }

        .#{$autocomplete} {
          &.#{$no-product} {
            .#{$column} {
              #{mod(others)} {
                width: $column-width-2;
              }
            }
          }
        }

        .#{$item} {
          #{mod(product)} {
            flex: 0 0 25%;
            max-width: 25%;
          }
        }

        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-3;
            }
          }
        }
      }

      #{mod(product-type-list)} {
        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-1;
            }
          }
        }

        &.#{$results} {
          #{mod(product-per-row-2)} {
            .#{$column} {
              #{mod(products)} {
                width: $column-width-3;
                background-color: #fff;
              }
            }

            .#{$product-items} {
              display: flex;
              flex-flow: row wrap;
            }

            .#{$item} {
              #{mod(product)} {
                flex: 0 0 50%;
                max-width: 50%;
              }
            }
          }
        }
      }
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-swatch);
$title: el($root, title);
$options: el($root, options);
$option: el($root, option);
$more: el($root, more);

.#{$root} {
  flex-wrap: nowrap;
  max-width: 100%;

  #{mod(full-width)} {
    width: 100%;
  }
}

.#{$title} {
  @include font-size(
    get-theme-var(product-swatch-title, font-size),
    get-theme-var(product-swatch-title, line-height),
    get-theme-var(product-swatch-title, font-weight),
    get-theme-var(product-swatch-title, letter-spacing)
  );

  color: get-theme-var(product-swatch-title, color);
  font-family: get-theme-var(product-swatch-title, font-family);
  text-transform: get-theme-var(product-swatch-title, text-transform);
  word-break: break-word;
  margin: get-theme-var(product-swatch-title, margin);
}

.#{$options} {
  display: inline-flex;
  flex-wrap: wrap;
  gap: get-theme-var(product-swatch-options, gap);
  margin: get-theme-var(product-swatch-options, margin);
  max-width: 100%;

  #{mod(vertical)} {
    flex-direction: column;
  }
}

.#{$option} {
  max-width: 100%;
}

.#{$more} {
  @include font-size(
    get-theme-var(product-swatch-more, font-size),
    get-theme-var(product-swatch-more, line-height),
    get-theme-var(product-swatch-more, font-weight)
  );

  color: get-theme-var(product-swatch-more, color);
  font-family: get-theme-var(product-swatch-more, font-family);
  border-radius: get-theme-var(product-swatch-more, border-radius);
  border: get-theme-var(product-swatch-more, border);
  background-color: transparent;
  cursor: pointer;
  min-width: get-theme-var(product-swatch-more, min-width);
  height: get-theme-var(product-swatch-more, height);
  padding: get-theme-var(product-swatch-more, padding);

  &:hover {
    color: get-theme-var(product-swatch-more, color-hover);
    border: get-theme-var(product-swatch-more, border-hover);
    background-color: get-theme-var(product-swatch-more, background-color-hover);
  }

  #{mod(non-radius)} {
    border-radius: 0;
  }
}

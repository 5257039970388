@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-box-item);
$button: ns(filter-option-item-button);
$label: ns(filter-option-item-label);

.#{$root} {
  @include font-size(
    get-theme-var(filter-option-box-item, font-size),
    get-theme-var(filter-option-box-item, line-height)
  );

  color: get-theme-var(filter-option-box-item, color);
  background-color: get-theme-var(filter-option-box-item, background-color);
  border: 1px solid get-theme-var(filter-option-box-item, border-color);
  border-radius: get-theme-var(filter-option-box-item, border-radius);
  min-width: calc(33% - 16px);
  max-width: 100%;
  word-break: break-word;

  .#{$button} {
    &:hover {
      color: get-theme-var(filter-option-box-item, color-hover);
      background-color: get-theme-var(filter-option-box-item, background-color-hover);
      border-color: get-theme-var(filter-option-box-item, border-color-hover);
    }
  }

  #{mod(selected)} {
    background-color: get-theme-var(filter-option-box-item, background-color-hover);
    border-color: get-theme-var(filter-option-box-item, border-color-hover);

    .#{$label} {
      color: get-theme-var(filter-option-box-item, color-hover);
    }
  }

  #{mod(disabled)} {
    opacity: 0.3;
    pointer-events: none;
  }
}

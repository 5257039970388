@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-label);
$img: el($root, img);
$text: el($root, text);

.#{$root} {
  display: inline-block;
  padding: get-theme-var(product-label, padding);

  #{mod(sale)} {
    @include font-size(
      get-theme-var(product-sale-label, font-size),
      get-theme-var(product-sale-label, line-height),
      get-theme-var(product-sale-label, font-weight),
      get-theme-var(product-sale-label, letter-spacing)
    );

    background-color: get-theme-var(product-sale-label, background-color);
    color: get-theme-var(product-sale-label, color);
    text-transform: get-theme-var(product-sale-label, text-transform);
  }

  #{mod(soldout)} {
    @include font-size(
      get-theme-var(product-sold-out-label, font-size),
      get-theme-var(product-sold-out-label, line-height),
      get-theme-var(product-sold-out-label, font-weight),
      get-theme-var(product-sold-out-label, letter-spacing)
    );

    background-color: get-theme-var(product-sold-out-label, background-color);
    color: get-theme-var(product-sold-out-label, color);
    text-transform: get-theme-var(product-sold-out-label, text-transform);
  }

  #{mod(customLabelByTag)} {
    @include font-size(
      get-theme-var(product-custom-label, font-size),
      get-theme-var(product-custom-label, line-height),
      get-theme-var(product-custom-label, font-weight),
      get-theme-var(product-custom-label, letter-spacing)
    );

    background-color: get-theme-var(product-custom-label, background-color);
    color: get-theme-var(product-custom-label, color);
    text-transform: get-theme-var(product-custom-label, text-transform);
  }

  #{mod(circle)} {
    @include flex-center;
    @include box(get-theme-var(product-label, width));

    border-radius: 100%;
    padding: 2px;

    .#{$img} {
      @include box(100%, get-theme-var(product-label, width));

      border-radius: 100%;
    }

    .#{$text} {
      @include flex-center;

      height: get-theme-var(product-label, width);
      border-radius: 100%;
      white-space: break-spaces;
    }
  }

  #{mod(rounded-rectangle)} {
    border-radius: get-theme-var(product-label, border-radius);

    .#{$img} {
      border-radius: get-theme-var(product-label, border-radius);
    }
  }

  #{mod(rectangle)} {
    border-radius: 0;
  }

  #{mod(image)} {
    padding: 0;
    line-height: 0;
    background-color: transparent;

    .#{$img} {
      max-width: 40px;
      max-height: 40px;

      @include tablet {
        max-width: 48px;
        max-height: 48px;
      }
    }
  }
}

.#{$text} {
  @include truncate;

  max-width: get-theme-var(product-label, max-width);
  min-width: get-theme-var(product-label, min-width);
  display: block;
  text-align: center;
  color: inherit;

  span {
    color: inherit;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(refine-by-vertical);
$refine-by: el($root, refine-by);
$refine-by-item: el($root, refine-by-item);
$refine-by-clear: el($root, refine-by-clear);
$refine-by-title: el($root, refine-by-title);
$refine-by-heading: el($root, refine-by-heading);
$refine-by-value: el($root, refine-by-value);

.#{$refine-by} {
  padding-bottom: 28px;
  border-bottom: 1px solid get-theme-var(refine-by, border-bottom-color);
  margin-bottom: get-theme-var(refine-by, margin-bottom);

  @include tablet {
    display: inline-block;
    width: 100%;
  }
}

.#{$refine-by-title} {
  display: flow-root;
  margin-bottom: 12px;

  @include font-size(
    get-theme-var(refine-by, font-size-title),
    get-theme-var(refine-by, line-height-title)
  );

  &:last-child {
    margin-bottom: 0;
  }
}

.#{$refine-by-heading} {
  @include font-size(
    get-theme-var(refine-by, heading-font-size),
    get-theme-var(refine-by, heading-line-height),
    get-theme-var(refine-by, heading-font-weight)
  );

  display: inline-block;
  color: var(--boost-sd-app-filter-option-text-color, #222);
  text-transform: uppercase;
}

.#{$refine-by-item} {
  @include flex-space-between;
  @include box(100%, auto);
  @include font-size(
    get-theme-var(refine-by, item-font-size),
    get-theme-var(refine-by, item-line-height)
  );

  padding: 0;
  margin: 0 0 12px;
  background: none;
  border: none;
  color: var(--boost-sd-app-filter-option-text-color, #222);
  text-align: left;
  white-space: normal;
  word-break: break-word;
  overflow-wrap: break-word;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &:active,
  &:focus {
    color: var(--boost-sd-app-filter-option-text-color, inherit);
    background-color: inherit;
  }
}

.#{$refine-by-clear} {
  @include box(auto);

  min-width: 12px;
  margin-left: 10px;

  path {
    fill: var(--boost-sd-app-filter-option-text-color, #222);
  }
}

.#{$refine-by-value} {
  @include font-size(inherit, inherit, get-theme-var(refine-by, value-font-weight));

  word-break: break-word;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(popup-select-option);
$product-card-button: ns(product-card-button);
$container: el($root, container);
$backdrop: el($root, backdrop);

.#{$root} {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  background-color: get-theme-var(popup-select-option, background-color);
  max-height: 400px;
  overflow-y: auto;
  scrollbar-width: thin;

  @include mobile {
    z-index: 99;
  }

  &::-webkit-scrollbar {
    width: get-theme-var(popup-select-option, width);
  }

  &::-webkit-scrollbar-track {
    background: get-theme-var(popup-select-option, scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: get-theme-var(popup-select-option, scrollbar-thumb-color);
  }

  #{mod(fixed-bottom)} {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  + .#{$product-card-button} {
    display: none;
  }
}

.#{$container} {
  padding: 4px 24px 24px;
}

.#{$backdrop} {
  @include box(100vw, 100%);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  display: block !important;
  background-color: get-theme-var(popup-select-option, background-color-backdrop);
}

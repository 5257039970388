@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-item-multilevel-collections-secondlevel);
$title: el($root, title);

.#{$root} {
  padding-left: 12px;
}

.#{$title} {
  @include flex-space-between;
}

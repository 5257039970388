@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-item-amount);

.#{$root} {
  float: right;
  line-height: get-theme-var(filter-option-item-amount, line-height);
  color: var(--boost-sd-app-filter-option-text-color, #222);
  font-size: var(--boost-sd-app-filter-option-font-size, 14px);
  font-family: var(--boost-sd-app-filter-option-font-family, inherit);
  opacity: 0.6;

  #{mod(selected)} {
    font-weight: get-theme-var(filter-option-item-amount, font-weight-selected);
    opacity: 1;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(refine-by-horizontal);
$button: ns(button);
$sticky-opening: ns(sticky-opening);
$is-sticky-body: ns(filter-tree-horizontal-sticky-body);
$refine-by: el($root, refine-by);
$refine-by-list: el($root, refine-by-list);
$refine-by-item: el($root, refine-by-item);
$refine-by-title: el($root, refine-by-title);
$refine-by-heading: el($root, refine-by-heading);
$refine-by-value: el($root, refine-by-value);
$refine-by-clear: el($root, refine-by-clear);

.#{$refine-by} {
  padding: get-theme-var(refine-by-horizontal, padding);

  .#{$button} {
    @include font-size(
      get-theme-var(button-clear-horizontal, font-size),
      get-theme-var(button-clear-horizontal, line-height),
      get-theme-var(button-clear-horizontal, font-weight)
    );

    text-decoration: underline;
  }
}

.#{$refine-by-list} {
  @include flex-center-vertical;

  flex-wrap: wrap;
  margin: 8px 0;
}

.#{$refine-by-title} {
  @include flex-center-vertical;

  flex-wrap: wrap;
  width: 100%;
}

.#{$refine-by-heading} {
  @include font-size(
    get-theme-var(refine-by-horizontal, heading-font-size),
    get-theme-var(refine-by-horizontal, heading-line-height),
    get-theme-var(refine-by-horizontal, heading-font-weight)
  );

  margin-right: 16px;
  text-transform: uppercase;
}

.#{$refine-by-item} {
  @include flex-space-between;
  @include font-size(
    get-theme-var(refine-by-horizontal, item-font-size),
    get-theme-var(refine-by-horizontal, item-line-height)
  );
  @include box(auto);

  min-width: auto;
  min-height: auto;
  padding: 4px 10px;
  margin: 4px 16px 4px 0;
  color: get-theme-var(refine-by-horizontal, item-color);
  background-color: #f5f5f5;
  border-radius: 50px;
  border: none;
  text-align: left;
  cursor: pointer;

  &:hover,
  &:focus {
    color: get-theme-var(refine-by-horizontal, item-color);
    background-color: #f5f5f5;
  }
}

.#{$refine-by-clear} {
  @include box(8px);
}

.#{$refine-by-value} {
  @include font-size(inherit, inherit, get-theme-var(refine-by-horizontal, value-font-weight));

  margin-right: 8px;
  word-break: break-word;
}

.#{$sticky-opening} {
  &.#{$is-sticky-body} {
    .#{$refine-by} {
      margin-bottom: get-theme-var(refine-by-horizontal, margin-bottom);
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(quantity);
$input: el($root, input);
$error: el($root, error);
$btn-wrapper: el($root, btn-wrapper);

.#{$root} {
  @include box(get-theme-var(quantity, width), get-theme-var(quantity, height));

  border: 1px solid get-theme-var(quantity, border-color);
  border-radius: get-theme-var(quantity, border-radius);
  display: flex;
  position: relative;
  padding: get-theme-var(quantity, padding);

  #{mod(medium)} {
    width: get-theme-var(quantity, width-medium);
  }

  #{mod(small)} {
    @include box(get-theme-var(quantity, width-small), get-theme-var(quantity, height-small));

    border-radius: get-theme-var(quantity, border-radius-small);
    padding: get-theme-var(quantity, padding-small);
  }
}

.#{$input} {
  &[type='number'] {
    @include box(100%);

    flex-grow: 1;
    min-height: get-theme-var(quantity-input, min-height);
    margin: get-theme-var(quantity-input, margin);
    padding: get-theme-var(quantity-input, padding);
    border: 0;
    background-color: transparent;
    color: get-theme-var(quantity-input, color);
    font-size: get-theme-var(quantity-input, font-size);
    font-family: inherit;
    text-align: center;
    text-indent: 0;
    appearance: textfield;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    appearance: none;
  }

  #{mod(small)} {
    &[type='number'] {
      min-height: get-theme-var(quantity-input, min-height-small);
      padding: 0;
    }
  }

  &:focus,
  &:hover,
  &:active {
    border: 0;
    box-shadow: none;
    outline: 0;
    outline-offset: 0;
  }
}

.#{$btn-wrapper} {
  width: get-theme-var(quantity, btn-wrapper-width);

  #{mod(small)} {
    width: get-theme-var(quantity, btn-wrapper-width-small);
  }
}

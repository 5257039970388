@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(dropdown);
$selected-option: el($root, selected-option);
$option-list: el($root, option-list);
$option: el($root, option);
$option-text: el($root, option-text);

.#{$root} {
  width: 100%;
  position: relative;
  display: inline-block;

  #{mod(disabled)} {
    pointer-events: none;
    opacity: 0.5;
  }
}

.#{$selected-option} {
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.#{$option-text} {
  max-width: 100%;
  display: inline-block;
  word-wrap: break-word;
  margin-right: 20px;
}

.#{$option-list} {
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  display: none;
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-top-width: 0;
  border-radius: 0 0 4px 4px;
  background-color: get-theme-var(bundle-style, dropdown-background-color);
  max-height: 300px;
  overflow-y: auto;

  #{mod(open)} {
    display: block;
  }
}

.#{$option} {
  padding: 8px 12px;
  cursor: pointer;
  word-wrap: break-word;

  &:hover,
  #{mod(selected)} {
    background-color: #f0f0f0;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-price);
$content: el($root, content);
$wrapper: el($root, wrapper);
$compare: el($root, compare);
$flex-wrap: el($root, flex-wrap);

.#{$root} {
  @include font-size(
    get-theme-var(product-price, font-size),
    get-theme-var(product-price, line-height),
    get-theme-var(product-price, font-weight),
    get-theme-var(product-price, letter-spacing)
  );

  color: get-theme-var(product-price, color);
  font-family: get-theme-var(product-price, font-family);
  font-style: get-theme-var(product-price, font-style);
  text-transform: get-theme-var(product-price, text-transform);
  text-align: get-theme-var(product-price, text-align);
  word-break: break-word;
  width: 100%;
  margin-top: get-theme-var(product-price, margin-top);
  margin-bottom: get-theme-var(product-price, margin-bottom);

  .#{$compare} {
    width: 100%;
  }
}

.#{$wrapper} {
  display: inline-flex;
  align-items: get-theme-var(product-price, align-items);
  gap: 5px;
}

.#{$content} {
  display: inline-flex;
  align-items: center;
  gap: get-theme-var(product-price, gap);
  flex-wrap: wrap;

  #{mod(flex-wrap)} {
    flex-wrap: wrap;
  }

  #{mod(row-reverse)} {
    flex-direction: row-reverse;
  }

  #{mod(column-reverse)} {
    align-items: unset;
    flex-direction: column-reverse;
  }

  #{mod(column)} {
    align-items: unset;
    flex-direction: column;
  }

  #{mod(text-align-left)} {
    justify-content: left;
  }

  #{mod(text-align-center)} {
    justify-content: center;
  }

  #{mod(text-align-right)} {
    justify-content: right;
  }
}

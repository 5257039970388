@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search);
$autocomplete: el($root, autocomplete);
$results: el($root, results);
$column: el($root, column);
$group: ns(suggestion-queries-group);
$item: ns(suggestion-queries-item);
$product: el($item, product);
$vendor: el($item, vendor);
$header: ns(header-item);
$product-list: el($root, product-list);
$product-header: el($root, product-list-header);
$product-items: el($root, product-list-items);

@import 'SearchContentResultVariables';

// general style
.#{$autocomplete} {
  min-width: $mobile-width-1;
  padding: 0;
  margin: 0;
  border-radius: 2px;
  z-index: 9999;
  overflow-x: hidden;
  overflow-y: auto;

  @include tablet {
    background-color: #fff;
  }
}

.#{$results} {
  @include flex-column;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2), 0 6px 10px rgba(0, 0, 0, 0.1);

  .#{$column} {
    @include flex-column;

    position: relative;

    &:empty {
      display: none;
    }

    #{mod(products)} {
      justify-content: space-between;
    }
  }

  .#{$product-header} {
    @include tablet {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  // mobile
  #{mod(mobile)} {
    height: 100%;
    overflow-y: auto;

    .#{$autocomplete} {
      @include flex-column;

      width: 100%;
      max-width: 100%;
    }

    .#{$header} {
      padding-top: 0;
    }

    .#{$group},
    .#{$product-list} {
      padding: 0 8px;
      margin-bottom: 10px;

      &:first-child {
        .#{$header} {
          padding-top: 10px;
        }
      }
    }

    .#{$product-list} {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // desktop
  #{mod(product-type-grid)} {
    .#{$product-items} {
      display: flex;
      flex-flow: row wrap;
    }

    .#{$item} {
      #{mod(product)} {
        flex: 0 0 50%;
        max-width: 50%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .#{$product} {
      #{mod(left)} {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
        margin-bottom: 12px;
      }

      #{mod(right)} {
        width: 100%;
      }
    }
  }

  #{mod(product-position-left)} {
    .#{$column} {
      #{mod(others)} {
        order: 2;
      }

      #{mod(products)} {
        order: 1;
      }
    }
  }

  #{mod(product-position-right)} {
    .#{$column} {
      #{mod(others)} {
        order: 1;
      }

      #{mod(products)} {
        order: 2;
      }
    }
  }
}

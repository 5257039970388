@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(suggestion-queries-item);
$item-selected: el($root, selected);
$link: el($root, link);
$product: el($root, product);
$title: el($root, title);
$sku: el($root, sku);
$vendor: el($root, vendor);
$amount: el($root, amount);
$price: el($root, price);
$money: el($root, money);
$img: el($root, img);
$badge: el($root, badge);

.#{$root} {
  clear: both;
  width: 100%;
  padding: 6px 12px;
  margin: 0;
  background-color: inherit;
  list-style: none;
  word-break: break-word;

  @include tablet {
    padding: 6px 8px;
  }

  #{mod(product)} {
    padding: 10px;
    border-radius: 4px;
    box-shadow: none;

    @include tablet {
      height: min-content;
    }

    &:hover {
      background-color: #f8f8f8;
    }
  }
}

.#{$item-selected} {
  background-color: #e8e9eb;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
}

.#{$link} {
  display: block;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
}

.#{$product} {
  #{mod(left)} {
    display: block;
    position: relative;
    width: 72px;
    margin-right: 16px;
    border-radius: 4px;
    float: left;
    overflow: hidden;

    &::before {
      @include box(100%);

      content: '';
      display: block;
    }
  }

  #{mod(right)} {
    float: left;
    width: calc(100% - 88px);
  }
}

.#{$img} {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.#{$title} {
  @include font-size(14px, calc(20 / 14));

  margin: 0;
  color: #222;
}

.#{$price} {
  @include font-size(14px, calc(20 / 14), 600);

  margin: 0;
}

.#{$vendor} {
  @include font-size(12px, calc(16 / 12));

  margin-bottom: 4px;
  color: #7a7a7a;
  text-transform: uppercase;
}

.#{$sku} {
  @include font-size(12px, calc(20 / 12));

  color: #d3d3d3;
}

.#{$amount} {
  display: inline-flex;
  gap: 5px;

  #{mod(sale)} {
    color: #f30;

    .#{$money} {
      span {
        @include font-size(12px, calc(16 / 12));

        color: #7a7a7a;
        text-decoration: line-through;
      }
    }
  }

  #{mod(regular)} {
    color: #222;
  }
}

.#{$badge} {
  @include font-size(8px, calc(10 / 8), 500);

  position: absolute;
  top: 4px;
  left: 4px;
  z-index: 1;
  padding: 2px 4px;
  max-width: 60px;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
  border-radius: 2px;
  overflow: hidden;

  #{mod(sold-out)} {
    background-color: #1a1c1d;
  }

  #{mod(sale)} {
    background-color: #f30;
  }
}

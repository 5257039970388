@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(spelling-correction);
$desc: el($root, desc);
$link: el($root, suggest-link);

.#{$root} {
  position: relative;
  width: 100%;
  padding: 6px 8px 8px;
  background-color: #fff;
  word-break: break-word;

  &::after {
    @include pseudo(auto, 1px);

    left: 24px;
    right: 24px;
    bottom: 0;
    background-color: #f5f5f5;
    opacity: 0;
  }
}

.#{$desc} {
  @include font-size(14px, calc(20 / 14));

  margin: 0 0 4px;
  color: #222;

  #{mod(list)} {
    display: block;
    margin-bottom: 0;
  }
}

.#{$link} {
  display: inline-block;
  padding: 4px 10px;
  margin-right: 7px;
  color: #222;
  background-color: #f6f6f7;
  border-radius: 50px;
  text-decoration: none;

  &:first-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: #e8e9eb;
  }
}

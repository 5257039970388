@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-placeholder);
$list: el($root, list);
$item: el($root, item);
$img: el($root, img);

.#{$root} {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  background: transparent;
}

.#{$list} {
  padding: 0;
  margin: 10px 10px 0;
  list-style: none;
}

.#{$item} {
  position: relative;
  height: 45px;
  margin-bottom: 10px;

  &::marker {
    font-size: 0;
  }

  &::before {
    @include pseudo(calc(100% - 55px), 10px);

    top: 8px;
    left: 55px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, #eee),
      color-stop(18%, #e6e6e6),
      color-stop(33%, #eee)
    );
    background-color: #eee;
    border-radius: 50em;
    animation: place-holder-shimmer 1s linear infinite forwards;
  }

  &::after {
    @include pseudo(50%, 10px);

    top: 28px;
    left: 55px;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(8%, #eee),
      color-stop(18%, #e6e6e6),
      color-stop(33%, #eee)
    );
    background-color: #eee;
    border-radius: 50em;
    animation: place-holder-shimmer 1s linear infinite forwards;
  }
}

.#{$img} {
  @include box(30%, 100%);

  max-width: 45px;
  background-color: #f5f5f5;

  &:empty {
    display: block;
  }
}

/* Suggestion placeholder */
@keyframes place-holder-shimmer {
  0% {
    background-position: -150px 0;
  }

  100% {
    background-position: 150px 0;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(arrow-button);
$icon: el($root, icon);
$path: el($root, path);

.#{$root} {
  @include box(20px);

  display: flex;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  transition: transform 0.1s ease-in-out;

  &:hover,
  &:focus {
    color: inherit;
    background: none;
    outline: none !important;
  }

  #{mod(right)} {
    transform: rotate(180deg);
    align-items: center;
  }

  #{mod(bottom)} {
    justify-content: center;
    align-items: end;
    transform: rotate(270deg);
  }
}

.#{$icon} {
  width: auto;
  height: auto;
}

.#{$path} {
  fill: #222;

  #{mod(filter)} {
    fill: var(--boost-sd-app-filter-option-text-color, #222);
  }
}

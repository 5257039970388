@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(quick-view);
$price: el($root, price);
$quantity: el($root, quantity);
$quantity-title: el($root, quantity-title);
$details-link: el($root, details-link);
$title: el($root, title);
$vendor: el($root, vendor);
$description: el($root, description);
$product-info: el($root, product-info);
$product-image: el($root, product-image);
$product-image-img: ns(product-image-img);
$btn: el($root, btn);
$price-compare: ns(format-currency--price-compare);

.#{$root} {
  display: flex;
  overflow: hidden;
  min-width: 60%;
  max-width: 960px;
  height: 640px;
  margin: 0 auto;

  @include mobile {
    @include box(100%);

    flex-direction: column;
    padding: 48px 24px 24px;
  }

  .slick-list {
    height: auto !important;
  }
}

.#{$product-image} {
  width: 100%;
  margin-bottom: 24px;

  @include tablet {
    width: 396px;
    margin-bottom: 0;
  }

  @include desktop {
    width: 480px;
  }

  .#{$product-image-img} {
    background: transparent;
    object-position: top;
  }
}

.#{$product-info} {
  width: 100%;
  padding: 0;
  overflow: initial;
  word-break: break-word;

  @include tablet {
    width: 480px;
    padding: 32px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  #{mod(in-qv-mini)} {
    width: 480px;
    max-width: 100%;
  }
}

.#{$title} {
  @include font-size(
    get-theme-var(quick-view-title, font-size),
    get-theme-var(quick-view-title, line-height),
    get-theme-var(quick-view-title, font-weight),
    get-theme-var(quick-view-title, letter-spacing)
  );

  font-family: get-theme-var(quick-view-title, font-family);
  margin: get-theme-var(quick-view-title, margin);
  color: get-theme-var(quick-view-title, color);
  text-transform: get-theme-var(quick-view-title, text-transform);
  word-break: break-word;
}

.#{$vendor} {
  @include font-size(
    get-theme-var(quick-view-vendor, font-size),
    get-theme-var(quick-view-vendor, line-height),
    get-theme-var(quick-view-vendor, font-weight),
    get-theme-var(quick-view-vendor, letter-spacing)
  );

  margin: 0 0 24px;
  color: get-theme-var(quick-view-vendor, color);
  text-transform: get-theme-var(quick-view-vendor, text-transform);

  @include tablet {
    margin: 0 0 get-theme-var(quick-view-vendor, margin-bottom);
  }
}

.#{$price} {
  @include font-size(
    get-theme-var(quick-view-price, font-size),
    get-theme-var(quick-view-price, line-height),
    get-theme-var(quick-view-price, font-weight),
    get-theme-var(quick-view-price, letter-spacing)
  );

  @include flex-center-vertical;

  gap: 5px;
  flex-wrap: wrap;
  margin-bottom: get-theme-var(quick-view-price, margin-bottom);
  color: get-theme-var(quick-view-price, color);

  .#{$price-compare} {
    font-size: get-theme-var(quick-view-price, compare-font-size);
  }
}

.#{$quantity} {
  margin-bottom: get-theme-var(quick-view-quantity, margin-bottom);
}

.#{$quantity-title} {
  font-family: get-theme-var(quick-view-quantity-title, font-family);

  @include font-size(
    get-theme-var(quick-view-quantity-title, font-size),
    get-theme-var(quick-view-quantity-title, line-height),
    get-theme-var(quick-view-quantity-title, font-weight),
    get-theme-var(quick-view-quantity-title, letter-spacing)
  );

  margin: get-theme-var(quick-view-quantity-title, margin);
  color: get-theme-var(quick-view-quantity-title, color);
  text-transform: get-theme-var(quick-view-quantity-title, text-transform);
}

.#{$btn} {
  @include flex-column;

  gap: get-theme-var(quick-view-btn, btn-column-gap);
  margin-bottom: get-theme-var(quick-view-btn, margin-bottom-mobile);

  @include tablet {
    flex-direction: row;
    grid-row-gap: get-theme-var(quick-view-btn, btn-row-gap);
    margin-bottom: get-theme-var(quick-view-btn, margin-bottom-desktop);
  }
}

.#{$details-link} {
  @include font-size(
    get-theme-var(quick-view-details-link, font-size),
    get-theme-var(quick-view-details-link, line-height),
    get-theme-var(quick-view-details-link, font-weight),
    get-theme-var(quick-view-details-link, letter-spacing)
  );

  position: relative;
  display: none;
  padding-right: get-theme-var(quick-view-details-link, padding-right);
  color: get-theme-var(quick-view-details-link, color);
  text-decoration: underline;

  @include tablet {
    display: inline-block;
  }

  &::before,
  &::after {
    top: 50%;
    right: 0;
  }

  &::before {
    @include pseudo(
      get-theme-var(quick-view-details-link, pseudo-size-before),
      get-theme-var(quick-view-details-link, pseudo-size-before)
    );

    margin-top: -3px;
    border-right: 1px solid get-theme-var(quick-view-details-link, pseudo-color);
    border-bottom: 1px solid get-theme-var(quick-view-details-link, pseudo-color);
    transform: rotate(-45deg);
  }

  &::after {
    @include pseudo(
      get-theme-var(quick-view-details-link, pseudo-size-after-width),
      get-theme-var(quick-view-details-link, pseudo-size-after-height)
    );

    margin-top: 1px;
    background-color: get-theme-var(quick-view-details-link, pseudo-color);
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-item-list-view-layout);
$image: el($root, image);
$info-group: el($root, info-group);
$info: el($root, info);
$product-title: ns(product-title);
$product-vendor: ns(product-vendor);
$price: el($root, price);
$description: el($root, description);
$cta-buttons: el($root, cta-buttons);
$button: ns(button);
$radio-label: ns(radio-label);
$product-swatch-more: ns(product-swatch-more);

.#{$root} {
  @include flex-row;

  width: 100%;
  padding: get-theme-var(product-item-list-view, padding);
  border-bottom: get-theme-var(border-list-view, border-bottom);
  border-left: get-theme-var(border-list-view, border-left);
  border-right: get-theme-var(border-list-view, border-right);
  border-top: get-theme-var(border-list-view, border-top);
  box-shadow: get-theme-var(product-item-list-view, box-shadow);

  &:first-child {
    border-top: get-theme-var(product-item-list-view, border-top);
  }

  .#{$radio-label},
  .#{$product-swatch-more} {
    cursor: default;
  }

  &:last-child {
    margin-bottom: get-theme-var(product-list, margin-bottom);
  }
}

.#{$image} {
  flex-shrink: 0;
  width: 120px;

  @include tablet {
    flex: 160px;
    width: auto;
    max-width: 160px;
  }
}

.#{$info-group} {
  @include flex-column;

  padding: 0 0 0 16px;
  width: calc(100% - 120px);
  max-width: max-content;

  @include tablet {
    @include flex-space-between;

    flex-wrap: wrap;
    flex-direction: inherit;
    max-width: calc(100% - 160px);
  }
}

.#{$info} {
  width: 100%;
  margin-bottom: 5px;

  @include tablet {
    flex: calc(100% - 168px);
    width: auto;
    max-width: calc(100% - 168px);
  }

  @include desktop {
    flex: 75%;
    max-width: 75%;
  }

  .#{$product-title} {
    @include font-size(
      get-theme-var(product-title, font-size),
      inherit,
      get-theme-var(product-title, font-weight)
    );

    font-family: get-theme-var(product-title, font-family);
    font-style: get-theme-var(product-title, font-style);
    text-transform: get-theme-var(product-title, text-transform);
    color: get-theme-var(product-title, color);
    margin-bottom: get-theme-var(product-title, margin-bottom-item-list);
  }

  .#{$product-vendor} {
    @include font-size(
      get-theme-var(product-vendor, font-size),
      inherit,
      get-theme-var(product-vendor, font-weight)
    );

    font-family: get-theme-var(product-vendor, font-family);
    font-style: get-theme-var(product-vendor, font-style);
    text-transform: get-theme-var(product-vendor, text-transform);
    color: get-theme-var(product-vendor, color);
    margin-bottom: get-theme-var(product-vendor, margin-bottom-item-list);
  }

  .#{$price} {
    margin-bottom: get-theme-var(product-price, margin-bottom-item-list);
  }

  .#{$description} {
    margin-bottom: get-theme-var(product-description, margin-bottom-item-list);
  }
}

.#{$price} {
  display: flex;
  flex-wrap: wrap;
}

.#{$cta-buttons} {
  @include flex-column;

  width: 100%;

  @include tablet {
    width: auto;
    max-width: 168px;
    margin-top: 0;
  }

  @include desktop {
    flex: 25%;
    max-width: 25%;
  }

  .#{$button} {
    @include font-size(inherit, calc(42 / 14), 500);
    @include box(auto);

    min-width: min(168px, 100%);
    min-height: 42px;
    margin: 10px 0;
    border-radius: get-theme-var(product-item-list-view, cta-border-radius);

    @include tablet {
      width: 100%;
      min-width: 168px;
      margin: 5px 0;
    }
  }
}

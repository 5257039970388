@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(recommendation-bundle);
$images: el($root, images);
$image: ns(product-image);
$link: ns(product-link);
$image-wrapper: ns(product-image-wrapper);
$image-item: el($root, image-item);
$plus-icon-wrapper: el($root, plus-icon-wrapper);
$left: el($root, left);
$right: el($root, right);
$button: ns(button);
$total-price: el($root, total-price);
$checkbox: el($root, checkbox);
$checkbox-input: el($root, checkbox-input);
$item-info: el($root, item-info);
$item-info-content: el($root, item-info-content);
$item-title: el($root, item-title);
$dropdown-wrapper: el($root, dropdown-wrapper);
$dropdown-option: ns(dropdown-selected-option);
$dropdown-option-list: ns(dropdown-option-list);
$dropdown-option-text: ns(dropdown-option-text);
$format-currency: ns(format-currency);
$product-link: ns(product-link);

// Define local variable here as break line format of stylint
$checkbox-width: get-theme-var(bundle-style, checkbox-width);
$checkbox-right-spacing: get-theme-var(bundle-style, checkbox-margin-right);
$bundle-right-max-width: get-theme-var(bundle-style, right-max-width);
$bundle-gutter: get-theme-var(bundle-style, gutter);

.#{$root} {
  @include font-size(
    get-theme-var(bundle-style, font-size),
    get-theme-var(bundle-style, line-height)
  );

  display: flex;
  flex-wrap: wrap;
  column-gap: $bundle-gutter;

  #{mod(style1)} {
    @include tablet {
      .#{$item-title} {
        margin-right: get-theme-var(bundle-style, item-title-margin-right-style1);
      }
    }
  }

  #{mod(style2)} {
    @include tablet {
      align-items: center;
    }
  }

  .#{$format-currency} {
    display: inline-flex;
    font-weight: bold;
  }

  .#{$product-link} {
    color: get-theme-var(bundle-style, product-link-color);
  }
}

.#{$image-item} {
  width: get-theme-var(bundle-style, image-item-width-mobile);
  height: get-theme-var(bundle-style, image-item-height-mobile);
  flex: 0 0 auto;

  @include tablet {
    width: get-theme-var(bundle-style, image-item-width);
    height: get-theme-var(bundle-style, image-item-height);
  }
}

.#{$images} {
  display: flex;
  max-width: 100%;
  gap: get-theme-var(bundle-style, images-gap-mobile);
  padding-bottom: get-theme-var(bundle-style, images-padding-bottom);
  margin-bottom: get-theme-var(bundle-style, images-margin-bottom);
  overflow-x: auto;

  @include tablet {
    gap: get-theme-var(bundle-style, images-gap);
  }

  #{mod(style1)} {
    .#{$image} {
      border-radius: get-theme-var(bundle-style, image-border-radius-mobile);
      overflow: hidden;

      @include tablet {
        border-radius: get-theme-var(bundle-style, image-border-radius);
      }
    }

    .#{$link} {
      height: 100%;
    }
  }

  #{mod(style2)} {
    padding-bottom: get-theme-var(bundle-style, images-padding-bottom-style2);

    .#{$image-item} {
      width: get-theme-var(bundle-style, image-item-width-style2);
      height: auto;
    }

    // We need to override by this way as no specific classname for image on style-2
    .#{$image-wrapper} {
      height: get-theme-var(bundle-style, image-item-height-style2);
    }

    .#{$checkbox} {
      position: static;
      display: flex;
      align-items: center;
    }
  }
}

.#{$left},
.#{$right} {
  width: 100%;

  @include tablet {
    width: auto;
    max-width: 100%;

    .#{$button} {
      flex: 0;
      transition: none;
    }
  }
}

.#{$left} {
  @include tablet {
    max-width: calc(100% - $bundle-right-max-width - $bundle-gutter);
  }
}

.#{$right} {
  padding: get-theme-var(bundle-style, right-padding);
  text-align: center;

  @include tablet {
    width: auto;
    max-width: $bundle-right-max-width;
    height: calc(get-theme-var(bundle-style, image-item-width) * 4 / 3);
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
  }

  @include tablet {
    width: $bundle-right-max-width;
  }
}

.#{$plus-icon-wrapper} {
  margin: 0;
  display: flex;
  align-items: center;

  #{mod(style2)} {
    height: 220px;
  }
}

.#{$checkbox} {
  position: absolute;
  top: 0;
  left: calc(-1 * ($checkbox-width + $checkbox-right-spacing));
}

.#{$checkbox-input} {
  accent-color: get-theme-var(bundle-style, checkbox-background-color);
  margin: 0;

  &[type='checkbox'] {
    @include box($checkbox-width);

    margin-right: get-theme-var(bundle-style, checkbox-margin-right-style2);
  }
}

.#{$item-info} {
  #{mod(style1)} {
    padding: 0 0 get-theme-var(bundle-style, item-info-spacing)
      calc($checkbox-width + $checkbox-right-spacing);
    margin-bottom: get-theme-var(bundle-style, item-info-spacing);
    border-bottom: get-theme-var(bundle-style, item-info-border);

    @include tablet {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  #{mod(style2)} {
    width: 165px;
    text-align: center;
    margin-top: get-theme-var(bundle-style, item-info-margin-top-style2);

    .#{$item-info-content} {
      justify-content: center;
    }

    .#{$item-title} {
      width: 100%;
    }
  }
}

.#{$item-info-content} {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  #{mod(blur)} {
    opacity: 0.5;
  }
}

.#{$item-title} {
  display: block;
  width: 100%;
  margin-bottom: get-theme-var(bundle-style, item-title-margin-bottom-mobile);
  position: relative;
  word-wrap: break-word;

  @include tablet {
    width: auto;
    max-width: 100%;
    display: inline;
    margin-bottom: get-theme-var(bundle-style, item-title-margin-bottom);
  }
}

.#{$total-price} {
  margin: get-theme-var(bundle-style, total-price-margin);
}

.#{$dropdown-wrapper} {
  margin-right: get-theme-var(bundle-style, dropdown-margin-right-mobile);
  display: block;
  max-width: 100%;

  @include tablet {
    margin-right: get-theme-var(bundle-style, dropdown-margin-right);
    margin-left: get-theme-var(bundle-style, dropdown-margin-left);
  }

  .#{$dropdown-option} {
    padding: get-theme-var(bundle-style, dropdown-padding);
  }

  .#{$dropdown-option-text} {
    margin-right: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 22px);
  }

  #{mod(style2)} {
    max-width: unset;
    width: 100%;
    margin: get-theme-var(bundle-style, dropdown-margin-style2-mobile);

    @include tablet {
      margin: get-theme-var(bundle-style, dropdown-margin-style2);
    }

    // Temp fixed for case hide variants dropdown
    .#{$dropdown-option-list} {
      top: auto;
      bottom: 100%;
      max-height: 200px;
      border-top-width: 1px;
    }
  }

  // Temp fixed to matching other theme render the same as Dawn theme on case dropdown variant empty
  &:empty {
    display: none;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(sorting);
$label: el($root, label);
$button: el($root, button);
$list: el($root, list);
$option: el($root, option);
$value: el($root, value);
$toolbar-item: ns(toolbar-item);

.#{$root} {
  @include flex-center-vertical;
}

.#{$label} {
  margin-right: 10px;
  text-transform: get-theme-var(toolbar-sorting, label-text-transform);
  font-size: get-theme-var(toolbar-sorting, label-font-size);
  font-weight: get-theme-var(toolbar-sorting, label-font-weight);
  line-height: get-theme-var(toolbar-sorting, label-line-height);
  max-width: 160px;
  word-break: break-word;
  color: get-theme-var(toolbar, color);

  @include mobile {
    display: none;
  }
}

.#{$button} {
  @include flex-center;

  position: relative;
  padding: get-theme-var(toolbar-sorting, padding-desktop);
  font-size: get-theme-var(toolbar-sorting, button-font-size);
  font-weight: get-theme-var(toolbar-sorting, button-font-weight);
  line-height: get-theme-var(toolbar-sorting, button-line-height);
  cursor: pointer;
  word-break: break-word;
  color: get-theme-var(toolbar, color);

  @include mobile {
    width: 100%;
    justify-content: space-between;
  }

  @include tablet {
    max-width: get-theme-var(toolbar-sorting, max-width);
  }

  &::after {
    @include box(7px);

    content: '';
    display: inline-block;
    margin-top: -5px;
    border-right: 1px solid get-theme-var(toolbar, color);
    border-bottom: 1px solid get-theme-var(toolbar, color);
    transform: rotate(45deg);
    transition: transform 0.1s ease-in-out;
  }

  #{mod(active)} {
    &::after {
      margin-top: 2px;
      transform: rotate(-135deg);
    }
  }
}

.#{$list} {
  position: absolute;
  top: calc(100% + 10px);
  z-index: 2;
  display: none;
  width: 250px;
  height: auto;
  max-height: 400px;
  margin: 0;
  padding-top: 5px;
  padding-left: 0;
  border: 1px solid #eee;
  background: get-theme-var(toolbar, background-color);
  color: get-theme-var(toolbar, color);
  list-style: none;
  cursor: pointer;
  overflow-y: auto;
  word-break: break-word;

  &::-webkit-scrollbar {
    width: get-theme-var(toolbar-sorting, width);
  }

  &::-webkit-scrollbar-track {
    background: get-theme-var(toolbar-sorting, scrollbar-track-color);
  }

  &::-webkit-scrollbar-thumb {
    background: get-theme-var(toolbar-sorting, scrollbar-thumb-color);
  }

  #{mod(active)} {
    display: block;
  }
}

.#{$option} {
  min-width: 200px;
  padding: 9px 20px;
  margin: 0;

  &:hover,
  #{mod(active)} {
    text-decoration: underline;
  }

  :first-child {
    padding-top: 20px;
  }
}

.#{$value} {
  min-width: 20px;
  margin-right: 10px;

  @include mobile {
    width: 100%;
    display: inline-block;
  }
}

.#{$toolbar-item} {
  &:last-child {
    .#{$list} {
      right: 0;
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(no-search-result-page);
$empty: el($root, empty);
$title: el($root, title);
$box-heading: el($root, box-heading);
$heading: el($root, heading);
$suggestion-list: el($root, suggestion-list);
$suggestion-item: el($root, suggestion-item);
$search-tip: el($root, search-tip);
$search-result: el($root, search-result);
$popular-products: el($root, popular-products);
$product-item: ns(product-item);
$slider: ns(slider);
$slider-button: ns(button);
$active: el($root, active);

.#{$root} {
  padding: get-theme-var(no-search-result-page-empty, padding-mobile);

  @include tablet {
    padding: get-theme-var(no-search-result-page-empty, padding-desktop);
  }
}

.#{$empty} {
  @include font-size(
    get-theme-var(no-search-result-page-empty, font-size),
    get-theme-var(no-search-result-page-empty, line-height),
    get-theme-var(no-search-result-page-empty, font-weight),
    get-theme-var(no-search-result-page-empty, letter-spacing)
  );

  text-align: center;
}

.#{$search-result} {
  @include font-size(
    get-theme-var(no-search-result-page-empty, search-result-font-size),
    normal,
    get-theme-var(no-search-result-page-empty, search-result-font-weight)
  );

  color: get-theme-var(no-search-result-page-empty, search-result-color);
  word-break: break-word;
}

.#{$box-heading} {
  position: relative;
  margin-top: 60px;
  margin-bottom: 64px;
  text-align: center;

  &::before {
    @include pseudo(100%, 1px);

    top: 50%;
    left: 0;
    background: rgba(34, 34, 34, 0.2);
  }
}

.#{$heading} {
  @include font-size(
    get-theme-var(no-search-result-page-search-heading, font-size),
    get-theme-var(no-search-result-page-search-heading, line-height),
    get-theme-var(no-search-result-page-search-heading, font-weight),
    get-theme-var(no-search-result-page-search-heading, letter-spacing)
  );

  display: inline-block;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 0;
  margin-bottom: 0;
  color: get-theme-var(no-search-result-page-search-heading, color);
  background: get-theme-var(no-search-result-page-search-heading, background-color);
  text-transform: get-theme-var(no-search-result-page-search-heading, text-transform);
}

.#{$popular-products} {
  .#{$slider} {
    @include tablet {
      padding: 0 get-theme-var(recommendation, button-width);
    }

    .#{$slider-button} {
      &:hover {
        background-color: get-theme-var(recommendation, button-background-color-active);

        svg {
          filter: invert(100%);
        }
      }
    }
  }

  .#{$product-item} {
    #{mod(no-border)},
    #{mod(border-has-padding)} {
      margin-left: calc(get-theme-var(product-list, gap-mobile) * 0.5);
      margin-right: calc(get-theme-var(product-list, gap-mobile) * 0.5);

      @include tablet {
        margin-left: calc(get-theme-var(product-list, gap-desktop) * 0.5);
        margin-right: calc(get-theme-var(product-list, gap-desktop) * 0.5);
      }
    }
  }
}

.#{$search-tip} {
  margin: auto;
  width: get-theme-var(no-search-result-page-search-tip, width);
  max-width: 100%;
  font-size: get-theme-var(no-search-result-page-search-tip, font-size);
}

.#{$title} {
  @include font-size(
    get-theme-var(no-search-result-page-search-tip-title, font-size),
    get-theme-var(no-search-result-page-search-tip-title, line-height),
    get-theme-var(no-search-result-page-search-tip-title, font-weight),
    get-theme-var(no-search-result-page-search-tip-title, letter-spacing)
  );

  margin: 48px 0 18px;
  text-align: center;
  color: get-theme-var(no-search-result-page-search-tip-title, color-title);
}

.#{$suggestion-list} {
  @include flex-center;

  flex-wrap: wrap;
  gap: 12px;
  padding: 0;
  list-style: none;
  overflow-y: hidden;
}

.#{$suggestion-item} {
  @include font-size(
    get-theme-var(no-search-result-page-suggestion-item, font-size),
    get-theme-var(no-search-result-page-suggestion-item, line-height),
    get-theme-var(no-search-result-page-suggestion-item, font-weight),
    get-theme-var(no-search-result-page-suggestion-item, letter-spacing)
  );

  display: inline-block;
  padding: get-theme-var(no-search-result-page-suggestion-item, padding);
  color: get-theme-var(no-search-result-page-suggestion-item, color);
  background-color: get-theme-var(no-search-result-page-suggestion-item, background-color);
  border: get-theme-var(no-search-result-page-suggestion-item, border);
  border-radius: get-theme-var(no-search-result-page-suggestion-item, border-radius);
  text-decoration: none;

  &:hover {
    opacity: 0.7;
  }
}

.#{$active} {
  .boost-sd-left,
  .boost-sd__toolbar-container {
    display: none;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-title);

.#{$root} {
  @include font-size(
    get-theme-var(product-title, font-size),
    get-theme-var(product-title, line-height),
    get-theme-var(product-title, font-weight),
    get-theme-var(product-title, letter-spacing)
  );

  color: get-theme-var(product-title, color);
  font-family: get-theme-var(product-title, font-family);
  text-transform: get-theme-var(product-title, text-transform);
  text-align: get-theme-var(product-title, text-align);
  word-break: break-word;
  margin-bottom: get-theme-var(product-title, margin-bottom);
  width: 100%;

  &:hover {
    color: get-theme-var(product-title, color-hover);
    text-decoration: get-theme-var(product-title, text-decoration-hover);
  }
}

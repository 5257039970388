@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-tree-horizontal-placeholder);
$list: el($root, list);
$item: el($root, item);
$btn: el($root, btn);
$icon: el($root, icon);
$placeholder-item: ns(placeholder-item);

.#{$root} {
  display: inline-block;
  width: 100%;
  max-width: 100%;
  background: transparent;

  @include tablet {
    padding-left: calc(get-theme-var(general, gutter-desktop) * 0.5);
    padding-right: calc(get-theme-var(general, gutter-desktop) * 0.5);
  }
}

.#{$list} {
  margin: 0;
  padding: 0;
  list-style: none;
  border-bottom: 1px solid #e8e9eb;
}

.#{$item} {
  display: inline-block;
  max-width: 100%;
  padding: 20px 0;
}

.#{$btn} {
  @include box(auto);

  display: inline-block;
  position: relative;
  padding: 5px 15px 5px 0;
  margin: 0;
  min-height: auto;
  min-width: 0;
  border-radius: 0;
  box-shadow: none;
  background: none;
  border: none;
  letter-spacing: normal;
  text-align: left;
  cursor: pointer;

  &:hover,
  &:focus {
    color: inherit;
    background: none;
  }

  .#{$placeholder-item} {
    @include box(80px, 10px);

    display: inline-block;
    margin: 0 15px 0 0;
  }
}

.#{$icon} {
  transform: rotate(-90deg);
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-item);

.#{$root} {
  position: relative;
  vertical-align: top;
  margin: 0 0 12px;
  list-style: none;
  cursor: pointer;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-list-pagination);
$empty: el($root, in-collection-search-empty-message);

.#{$empty} {
  margin: get-theme-var(in-collection-search-result, margin);
  font-size: get-theme-var(in-collection-search-result, font-size);
  text-align: center;
}

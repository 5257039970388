@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(toolbar);
$container: el($root, container);
$sticky: el($root, sticky);
$no-products: el($root, no-products);
$inner: el($root, inner);
$sorting: ns(sorting);
$sorting-list: el($sorting, list);
$product-count: el($root, product-count);
$top-mobile: el($root, top-mobile);
$item: el($root, item);
$content: el($root, content);
$tooltip: ns(tooltip);
$tooltipContent: el($tooltip, content);
$text-product-count: ns(product-count);
$border-line: get-theme-var(toolbar, border-width) get-theme-var(toolbar, border-style)
  get-theme-var(toolbar, border-color);
$border-line-mobile: get-theme-var(toolbar, border-width-mobile)
  get-theme-var(toolbar, border-style-mobile) get-theme-var(toolbar, border-color-mobile);

.#{$root} {
  @include flex-center-vertical;

  width: 100%;

  @include mobile {
    flex-wrap: wrap;
  }

  @include tablet {
    column-gap: get-theme-var(toolbar, gap);
  }

  #{mod(1_1)} {
    .#{$item} {
      @include tablet {
        flex: 1;
        justify-content: end;
      }
    }
  }

  #{mod(1_2)} {
    .#{$item} {
      @include tablet {
        flex: 1;
        justify-content: start;
      }
    }
  }

  #{mod(1_3)} {
    .#{$item} {
      @include tablet {
        flex: 1;
        justify-content: center;
      }
    }
  }

  #{mod(2_1)} {
    @include tablet {
      justify-content: space-between;
    }
  }

  #{mod(2_2)} {
    @include tablet {
      justify-content: end;
    }
  }

  #{mod(2_3)} {
    @include tablet {
      justify-content: start;
    }
  }

  #{mod(2_4)} {
    .#{$item} {
      @include tablet {
        flex: 1;
        justify-content: end;
      }
    }
  }

  #{mod(3_1)} {
    .#{$item} {
      @include tablet {
        flex: 1;

        &:nth-child(2) {
          justify-content: center;
        }

        &:nth-child(3) {
          justify-content: end;
        }
      }
    }
  }

  #{mod(3_2)} {
    .#{$item} {
      @include tablet {
        &:nth-child(1) {
          flex: 1;
        }

        &:nth-child(2),
        &:nth-child(3) {
          justify-content: end;
        }
      }
    }
  }

  #{mod(3_3)} {
    .#{$item} {
      @include tablet {
        &:nth-child(1),
        &:nth-child(2) {
          justify-content: start;
        }

        &:nth-child(3) {
          flex: 1;
          justify-content: end;
        }
      }
    }
  }

  #{mod(3_4)} {
    @include tablet {
      justify-content: flex-end;

      .#{$item} {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
          justify-content: end;
        }
      }
    }
  }

  #{mod(4_1)} {
    .#{$item} {
      @include tablet {
        &:nth-child(2),
        &:nth-child(3) {
          flex: 1;
        }

        &:nth-child(2) {
          justify-content: start;
        }

        &:nth-child(3) {
          justify-content: end;
        }
      }
    }
  }

  #{mod(4_2)} {
    @include tablet {
      justify-content: space-between;
    }
  }
}

.#{$sticky} {
  background: get-theme-var(toolbar, background-color-sticky);

  &::before {
    @include pseudo(100vw, 100%, block);

    top: 0;
    left: calc(50% - 16px);
    right: 0;
    margin-left: calc(-50vw + 8px);
    background: inherit;
  }

  .#{$inner} {
    position: relative;
  }

  .#{$item} {
    #{mod(view-as)} {
      .#{$tooltipContent} {
        transform: translate(calc(-50% + 14px), 28px) !important;
      }
    }
  }
}

.#{$container} {
  width: 100%;

  @include mobile {
    position: relative;
  }

  @include tablet {
    padding: get-theme-var(toolbar, padding-container);
  }
}

.#{$inner} {
  margin: 0 auto;
  max-width: get-theme-var(general, page-width-desktop);
}

.#{$top-mobile} {
  @include mobile {
    @include flex-center;

    flex: 0 0 100%;
    border-top: $border-line-mobile;
    border-bottom: $border-line-mobile;
    align-items: center;

    .#{$sorting} {
      @include font-size(14px, calc(20 / 14));

      flex: 0 0 50%;
      max-width: 50%;
      padding: 12px calc(get-theme-var(general, gutter-mobile));
      border-right: $border-line-mobile;
    }

    .#{$sorting-list} {
      left: -16px;
    }
  }
}

.#{$item} {
  display: flex;

  @include mobile {
    flex-basis: 50%;
    padding-bottom: 20px;

    #{mod(view-as)} {
      padding-right: calc(get-theme-var(general, gutter-mobile));
      justify-content: flex-end;
      order: 3;
    }

    #{mod(product-count)} {
      padding-left: calc(get-theme-var(general, gutter-mobile));
      order: 2;
    }

    #{mod(show-limit-list)} {
      display: none;
    }

    #{mod(sorting)} {
      flex-basis: 100%;
      display: block;
    }
  }
}

.#{$product-count} {
  font-size: get-theme-var(toolbar-product-count, font-size);
  font-weight: get-theme-var(toolbar-product-count, font-weight);
  line-height: get-theme-var(toolbar-product-count, line-height);
}

.#{$content} {
  border-bottom: $border-line;
  padding-top: get-theme-var(toolbar, padding-top);

  @include tablet {
    display: flex;
    align-items: center;
    border-bottom: none;
    padding-bottom: get-theme-var(toolbar, padding-bottom);
  }
}

.#{$no-products} {
  .#{$container} {
    @include tablet {
      border-top: none;
      border-bottom: none;
      padding: 0;
    }
  }

  .#{$content} {
    @include tablet {
      padding: 0;
    }
  }
}

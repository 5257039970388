@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search);
$autocomplete: el($root, autocomplete);
$no-product: el($root, no-product);
$no-other: el($root, no-other);
$results: el($root, results);
$no-result: ns(no-search-result);
$column: el($root, column);
$group: ns(suggestion-queries-group);
$item: ns(suggestion-queries-item);
$product: el($item, product);
$dym: ns(spelling-correction);
$header: ns(header-item);
$product-list: el($root, product-list);
$product-header: el($root, product-list-header);

@import '../SearchContentResultVariables';

.#{$results} {
  // mobile style2 - non full width
  #{mod(mobile-style2)} {
    width: $mobile-width-1;
    background-color: #fff;

    .#{$autocomplete} {
      padding-top: 10px;

      &.#{$no-product}.#{$no-other} {
        padding-top: 0;
      }
    }

    .#{$dym} {
      margin-bottom: 10px;
      padding: 10px 20px 14px;

      &::after {
        left: 20px;
        right: 20px;
        opacity: 1;
      }
    }

    .#{$group} {
      padding-bottom: 14px;

      &:not(:last-child) {
        position: relative;
        margin-bottom: 10px;

        &::after {
          @include pseudo(auto, 1px);

          left: 20px;
          right: 20px;
          bottom: 0;
          background-color: #f5f5f5;
        }
      }
    }

    .#{$header} {
      padding-bottom: 6px;
      text-align: left;
    }

    .#{$product-header} {
      padding-bottom: 4px;
    }

    .#{$no-result} {
      padding: 20px 16px;
    }

    .#{$product-list} + .#{$group} {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      margin-top: 10px;

      &::before {
        @include pseudo(auto, 1px);

        top: 0;
        left: 20px;
        right: 20px;
        background-color: #f5f5f5;
      }
    }

    &.#{$results} {
      #{mod(product-type-grid)} {
        width: $mobile-width-2;

        .#{$column} {
          padding-left: 4px;
          padding-right: 4px;
        }

        .#{$item} {
          #{mod(product)} {
            &:nth-child(even) {
              padding-right: 12px;
            }

            &:nth-child(odd) {
              padding-left: 12px;
            }
          }
        }

        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-3;
            }
          }
        }
      }

      #{mod(product-type-list)} {
        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-1;
            }
          }
        }
      }
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(pagination-infinite-scroll-container);
$product-count: ns(product-count);
$button: el($root, button);

.#{$root} {
  .#{$product-count} {
    margin-bottom: 30px;
    font-family: get-theme-var(pagination-load-more, font-family);
    font-size: get-theme-var(pagination-load-more, font-size);
  }
}

.#{$button} {
  @include flex-center-horizontal;

  margin-top: 10px;
  margin-bottom: 20px;
}

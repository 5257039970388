@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(slide);
$product-image: ns(product-image-img);

.#{$root} {
  width: 100%;
  scroll-snap-align: start;

  .#{$product-image} {
    border-radius: get-theme-var(slide, border-radius);
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;@import 'nouislider/dist/nouislider.css';
@import './NoUISliderOverwrite';

$root: ns(filter-option-range-slider);
$item-wrapper: ns(filter-option-itemwrapper);
$slider: el($root, slider);
$range-amount: el($root, range-amount);
$input-wrapper: el($root, 'input-wrapper');
$input-split: el($root, 'input-split');
$unit: el($root, unit);
$input: el($root, 'input');

.#{$root} {
  overflow: visible;

  .#{$item-wrapper} {
    #{mod(style1)} {
      width: 532px;

      .#{$slider} {
        margin-bottom: 59px;
      }
    }
  }
}

.#{$slider} {
  @include box(calc(100% - 22px), 2px);

  overflow: visible;
  margin: 25px auto 35px;
  border: none;
  background-color: #fff;
  box-shadow: none;
}

.#{$range-amount} {
  @include flex-space-between;

  margin-bottom: 20px;

  @include mobile {
    width: inherit;
  }
}

.#{$input-wrapper} {
  @include flex-center-vertical;
  @include box(calc(50% - 15px), 36px);

  position: relative;
  margin: 0 0 10px;
  padding: 0;
  border: 1px solid #e3e3e3;
  line-height: calc(24 / 14);
}

.#{$input-split} {
  margin: 0 0 10px;
}

.#{$unit} {
  @include font-size(14px, calc(20 / 14));

  padding-left: 6px;
  color: var(--boost-sd-app-filter-option-text-color, inherit);
  white-space: nowrap;
}

.#{$input} {
  &[type='text'],
  &[type='number'] {
    @include box(100%);
    @include font-size(
      var(--boost-sd-app-filter-option-font-size, 14px),
      get-theme-var(filter-option-item-button, line-height)
    );

    padding: 5px 6px 5px 0;
    margin: 0;
    color: var(--boost-sd-app-filter-option-text-color, inherit);
    font-family: var(--boost-sd-app-filter-option-font-family, inherit);
    text-align: center;
    text-indent: 0;
    border: none;
    background: none;
    box-shadow: none;
    outline: none;

    &:focus {
      outline: none !important;
      box-shadow: none;
    }
  }

  #{mod(right)} {
    text-align: right;
  }
}

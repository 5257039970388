@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-bar);
$wrapper: el($root, wrapper);
$submit: el($root, submit);
$btn: el($root, btn);
$input: el($root, input);
$icon: el($root, icon);
$autocomplete: el($root, autocomplete);

// z-index ISW layers - cover all themes
$zIndexWrapper: 99999999;
$zIndexInputSearchBar: 999999998;

.#{$root} {
  @include box(100%, 64px);

  position: fixed;
  top: 0;
  right: 0;
  z-index: $zIndexInputSearchBar;
  display: flex;
  align-items: center;
  padding: 10px 16px 10px 0;
  background-color: #fff;
}

.#{$input} {
  @include font-size(16px, calc(24 / 16));
  @include box(calc(100vw - 68px), 44px);

  display: inline-block;
  padding: 10px 46px 10px 18px;
  margin: 0;
  color: #202223;
  background-color: #fbfbfb;
  border: 1px solid rgba(34, 34, 34, 0.2);
  border-radius: 50px;
  text-align: left;
  text-transform: none;

  @include tablet {
    padding: 10px 40px 10px 18px;
  }

  &:focus-visible {
    box-shadow: none;
    outline: none;
    border: 1px solid rgba(34, 34, 34, 0.2);
  }
}

.#{$wrapper} {
  @include box(100%, 100vh);

  position: fixed;
  top: 0;
  right: 0;
  z-index: $zIndexWrapper;
  background-color: rgba(#222, 0.05);

  // mobile style full-width (Style 1)
  #{mod(mobile-style1)} {
    .#{$autocomplete} {
      @include box(100%, calc(100% - 64px));

      position: fixed !important;
      top: 64px !important;
      left: 0 !important;
      background-color: #f7f8f9;
      list-style: none;
    }
  }

  // desktop style full-width (Overlay Fullwidth)
  #{mod(desktop-style3)} {
    background-color: #f7f8f9;

    .#{$root} {
      @include box(calc(100% - 48px), 88px);

      margin: 24px;
      padding: 20px 24px;
      border-radius: 4px;
    }

    .#{$autocomplete} {
      @include box(100%, calc(100% - 132px));

      position: fixed !important;
      top: 132px !important;
      left: 0 !important;
      padding: 0 24px;
      list-style: none;
      overflow-y: auto;
    }

    .#{$submit} {
      @include box(48px);

      position: absolute;
      top: 20px;
      left: 24px;
      border: none;
      border-radius: 50%;
      min-width: auto;
      background: transparent
        url("data:image/svg+xml,%3Csvg width='19' height='18' viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.2812 16L13.2488 11.1C15.3542 8.40003 15.0974 4.50003 12.5812 2.05002C9.80823 -0.649976 5.28931 -0.649976 2.46498 2.05002C-0.307989 4.75003 -0.307989 9.15002 2.46498 11.9C3.80012 13.2 5.59742 13.95 7.49742 13.95C9.03796 13.95 10.5271 13.45 11.7596 12.55L16.792 17.45C16.9974 17.65 17.2542 17.75 17.5109 17.75C17.7677 17.75 18.0244 17.65 18.2298 17.45C18.692 17 18.692 16.4 18.2812 16ZM7.54877 11.9C6.21363 11.9 4.92985 11.4 3.95417 10.45C1.95147 8.50002 1.95147 5.40002 3.95417 3.45002C4.92985 2.50002 6.26498 2.00002 7.54877 2.00002C8.83255 2.00002 10.1677 2.50002 11.1434 3.45002C13.1461 5.40002 13.1461 8.50002 11.1434 10.45C10.1677 11.4 8.8839 11.9 7.54877 11.9Z' fill='%23222222'/%3E%3C/svg%3E%0A")
        center no-repeat;
      font-size: 0;
      cursor: pointer;

      &:focus {
        outline: none !important;
      }
    }

    .#{$input} {
      @include box(calc(100% - 60px), 48px);

      padding: 12px 48px;
      margin: 0;
      background-color: #f8f8f8;
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(view-all);
$link: el($root, link);
$text: el($root, text);
$icon: el($root, icon);

.#{$root} {
  flex: 0 0 auto;
  height: 52px;
  background-color: #fff;
  box-shadow: inset 0 1px 0 #e4e5e7;
  text-align: center;
  overflow: auto;

  &:hover,
  &[aria-selected='true'] {
    background-color: #e8e9eb;
    border-radius: 0;
    box-shadow: none;
  }
}

.#{$link} {
  @include font-size(12px, calc(52 / 12), 500);

  display: block;
  margin: 0;
  color: #222;
  text-decoration: none;

  &:hover {
    color: #222;
    background-color: #e8e9eb;
  }
}

.#{$text} {
  @include flex-center;

  max-width: 100%;
  color: inherit;
  word-break: break-word;
}

.#{$icon} {
  margin-left: 10px;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-result-panel-content);
$item: el($root, item);
$image: el($item, image);
$text: el($item, text);
$title: el($item, title);
$description: el($item, description);

.#{$root} {
  margin: get-theme-var(search-result-panel-content, margin-mobile);
  max-width: get-theme-var(general, page-width-desktop);

  @include tablet {
    margin: 0 auto;
  }
}

.#{$item} {
  @include flex-center-vertical;

  padding: get-theme-var(search-result-panel-content, padding-item-mobile);
  text-decoration: none;

  @include mobile {
    flex-direction: row-reverse;
  }

  @include tablet {
    padding: get-theme-var(search-result-panel-content, padding-item);
  }

  &:not(:last-of-type) {
    border-bottom: get-theme-var(search-result-panel-content, border-item);
  }
}

.#{$image} {
  float: left;
  width: get-theme-var(search-result-panel-content, width-image);
  height: get-theme-var(search-result-panel-content, height-image);
  margin-left: get-theme-var(search-result-panel-content, margin-left-image-mobile);
  margin-right: 0;

  @include tablet {
    margin-right: get-theme-var(search-result-panel-content, margin-right-image);
    margin-left: 0;
  }

  img {
    @include box(100%);

    object-fit: cover;
  }
}

.#{$text} {
  flex: 1;
}

.#{$title},
.#{$description} {
  margin: 0;
}

.#{$title} {
  @include font-size(
    get-theme-var(search-result-panel-content, font-size-title),
    get-theme-var(search-result-panel-content, line-height-title),
    get-theme-var(search-result-panel-content, font-weight-title)
  );

  font-family: get-theme-var(search-result-panel-content, font-family-title);
  color: get-theme-var(search-result-panel-content, color-title);
}

.#{$description} {
  @include font-size(
    get-theme-var(search-result-panel-content, font-size-description),
    get-theme-var(search-result-panel-content, line-height-description),
    get-theme-var(search-result-panel-content, font-weight-description)
  );

  font-family: get-theme-var(search-result-panel-content, font-family-description);
  color: get-theme-var(search-result-panel-content, color-description);

  @include mobile {
    display: none;
  }
}

$root: ns(filter-option-range-slider);

.#{$root} {
  .noUi-marker {
    display: block;
  }

  #{mod(style-1)} {
    .noUi-pips-horizontal {
      @include font-size(12px, calc(14 / 12));

      top: 100%;
      height: auto;
      padding: 10px 0;
      color: var(--boost-sd-app-filter-option-text-color, #222);

      > .noUi-value {
        &:last-child {
          left: auto !important;
          right: 0;
          transform: translate(12px, 50%);
        }

        &:first-child {
          transform: translate(-12px, 50%);
        }
      }

      .noUi-marker {
        @include box(1px, 6px);

        top: -4px;
        margin: 0;

        &.noUi-marker-large {
          border-left: 1px solid;
          background-color: var(--boost-sd-app-filter-option-text-color, #3d4246);
        }
      }
    }

    .noUi-base {
      color: var(--boost-sd-app-filter-option-text-color, #3d4246);
    }

    .noUi-connects {
      background-color: var(--boost-sd-app-filter-option-text-color, #3d4246);
    }

    .noUi-connect {
      left: auto;
      border: 1px solid;
    }

    .noUi-origin {
      left: auto;
      background: none;

      .noUi-handle {
        @include box(20px);

        top: -10px;
        left: auto;
        right: -10px;
        border: 1px solid var(--boost-sd-app-filter-option-text-color, #3d4246);
        background: #fff;
        border-radius: 50%;
        box-shadow: none;
        cursor: pointer;
      }
    }

    .noUi-handle {
      top: -7px;
      right: -1px;

      &::before,
      &::after {
        content: none;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

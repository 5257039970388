@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;.boost-sd__search-widget-init-enabled {
  // Dawn, Colorblock, Craft, Crave, Icon, Refresh, Ride, Sense, Studio
  .predictive-search,
  // impulse 7.3.1
  #predictive-search,
  // Broadcast
  .header__search__results,
  #search-popdown-results,
  .search__popdown__upper,
  #search-popdown .search__popdown__upper, // no search result theme
  // Blockshop
  .search--loading,
  .search--results,

  // Debut
  .predictive-search-wrapper,
  // Ella
  .quickSearchResultsWrap,
  #search-form-mobile .quickSearchResultsWrap,
  // Empire, Supper store
  .search-flydown--results,
  .search-flydown,
  // Flex, Turbo
  .search__results-wrapper,
  // Flow
  #search-results,
  // Impulse, Motion
  .predictive-results,
  // Prestige
  #header-predictive-search, // Version > 7.0
  .Search__Results,
  // Symmetry
  .main-search__results,
  // Warehouse
  .search-bar__results,
  .search-bar__menu-wrapper {
    &:not(.#{boost-sd_disable-instant-search}) {
      display: none !important;
    }
  }

  // override position of ISW on some theme has search popup
  &.slideout-right--open,
  &.fancybox-active {
    .boost-sd__search-widget-init-wrapper {
      position: fixed !important;
    }
  }
}

// Fix sticky header have z index higher filter and search - Fix filter tree floated on main menu
.section-header.shopify-section-group-header-group {
  z-index: 4;
}

.boost-sd-toolbar-product-tab {
  @include mobile {
    margin-top: -1px;
  }
}

// Flex theme (desktop)'s input located in the center of viewport
.fancybox-active {
  .boost-sd__search-widget-init-wrapper:not(.boost-sd__g-hide) {
    left: 50% !important;
    transform: translateX(-50%);
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option-list-option-item);
$arrow-button: ns(arrow-button);
$sublist: el($root, sublist);

.#{$root} {
  .#{$arrow-button} {
    position: absolute;
    right: 0;
    top: 0;
  }

  #{mod(disabled)} {
    opacity: 0.3;
    pointer-events: none;
  }
}

.#{$sublist} {
  padding-left: 12px;
  margin: 12px 0 0;
  list-style: none;
}

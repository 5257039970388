@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search);
$autocomplete: el($root, autocomplete);
$results: el($root, results);
$no-result: ns(no-search-result);
$item: ns(suggestion-queries-item);
$product: el($item, product);
$dym: ns(spelling-correction);
$product-items: el($root, product-list-items);
$loading: el($root, loading);

@import '../SearchContentResultVariables';

.#{$results} {
  // mobile style1 - full width
  #{mod(mobile-style1)} {
    max-height: unset !important; // override max-height inline css of other style

    .#{$autocomplete} {
      height: 100vh;
    }

    .#{$loading} {
      width: 100vw;
    }

    .#{$no-result},
    .#{$dym} {
      width: calc(100% - 16px);
      margin: 10px auto;
      border-radius: 4px;
    }

    .#{$product-items} {
      padding-bottom: 10px;
      border-radius: 4px;
      background-color: #fff;
    }

    &.#{$results} {
      #{mod(product-type-list)} {
        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-1;
            }
          }
        }
      }

      #{mod(product-type-grid)} {
        .#{$item} {
          #{mod(product)} {
            &:nth-child(even) {
              padding-left: 10.25px;
              padding-right: 12.25px;
            }

            &:nth-child(odd) {
              padding-left: 12.25px;
              padding-right: 10.25px;
            }
          }
        }

        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-2;
            }
          }
        }
      }
    }
  }
}

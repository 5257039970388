@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-tree-vertical-placeholder);
$list: el($root, list);
$item: el($root, item);

.#{$root} {
  display: none;
  width: 100%;
  max-width: 100%;
  background: transparent;

  @include tablet {
    display: inline-block;
  }
}

.#{$list} {
  margin: 30px 10px 0;
  padding: 0;
  list-style: none;
}

.#{$item} {
  display: block;
  max-width: 100%;
  padding: 20px 0;

  &:first-child {
    margin-top: 10px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  &::before,
  &::after {
    display: none;
  }
}

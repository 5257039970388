@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(cart);
$btn-group: el($root, btn-group);
$btn: ns(button--truncate);
$subtotal: el($root, subtotal);
$label: el($root, label);
$price: el($root, price);
$body: el($root, body);
$header: el($root, header);
$items-count: el($root, items-count);
$item-empty: el($root, item-empty);
$footer: el($root, footer);
$clear-all-btn: el($root, clear-all-btn);

.#{$root} {
  @include flex-column;
  @include box(100%, 640px);

  font-family: get-theme-var(cart, font-family);

  @include desktop {
    width: 960px;
  }

  #{mod(side)} {
    @include box(320px, 100%);

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: get-theme-var(cart-side, background-color);
  }
}

.#{$header} {
  @include flex-center-vertical;

  position: relative;
  padding: get-theme-var(cart-header, padding);
  border-bottom: 1px solid get-theme-var(cart-header, border-color);

  #{mod(bigger-space)} {
    padding: get-theme-var(cart-header, padding-medium);
  }
}

.#{$items-count} {
  @include font-size(
    get-theme-var(cart-item-count, font-size),
    get-theme-var(cart-item-count, line-height),
    get-theme-var(cart-item-count, font-weight),
    get-theme-var(cart-item-count, letter-spacing)
  );

  color: get-theme-var(cart-item-count, color);
  text-transform: get-theme-var(cart-item-count, text-transform);

  #{mod(medium)} {
    font-size: get-theme-var(cart-item-count, font-size-medium);
    line-height: get-theme-var(cart-item-count, line-height-medium);
  }
}

.#{$item-empty} {
  margin: 0;
  color: get-theme-var(cart-item-empty, color);
  font-size: get-theme-var(cart-item-empty, font-size);
}

.#{$body} {
  @include flex-column;

  flex-grow: 1;
  row-gap: get-theme-var(cart-body, row-gap);
  padding: get-theme-var(cart-body, padding);
  overflow: auto;

  #{mod(bigger-space)} {
    row-gap: get-theme-var(cart-body, row-gap-medium);
    padding: get-theme-var(cart-body, padding-medium);
  }
}

.#{$footer} {
  padding: get-theme-var(cart-footer, padding);
  border-top: 1px solid get-theme-var(cart-footer, border-color);

  #{mod(right)} {
    padding: get-theme-var(cart-footer, right-padding);
  }
}

.#{$subtotal} {
  @include flex-space-between;

  margin: get-theme-var(cart-subtotal, margin);

  #{mod(right)} {
    justify-content: flex-end;
    gap: get-theme-var(cart-subtotal, gap-right);
    margin: get-theme-var(cart-subtotal, margin-right);
  }
}

.#{$label} {
  @include font-size(
    get-theme-var(cart-label, font-size),
    get-theme-var(cart-label, line-height),
    get-theme-var(cart-label, font-weight),
    get-theme-var(cart-label, letter-spacing)
  );

  color: get-theme-var(cart-label, color);
  text-transform: get-theme-var(cart-label, text-transform);

  #{mod(medium)} {
    @include font-size(
      get-theme-var(cart-label, font-size-medium),
      get-theme-var(cart-label, line-height-medium),
      get-theme-var(cart-label, font-weight-medium),
      get-theme-var(cart-label, letter-spacing)
    );
  }
}

.#{$price} {
  @include font-size(
    get-theme-var(cart-price, font-size),
    get-theme-var(cart-price, line-height),
    get-theme-var(cart-price, font-weight),
    get-theme-var(cart-price, letter-spacing)
  );

  color: get-theme-var(cart-price, color);

  #{mod(medium)} {
    @include font-size(
      get-theme-var(cart-price, font-size-medium),
      get-theme-var(cart-price, line-height-medium),
      get-theme-var(cart-price, font-weight-medium),
      get-theme-var(cart-price, letter-spacing)
    );
  }
}

.#{$clear-all-btn} {
  cursor: pointer;
}

.#{$btn-group} {
  @include flex-column;

  gap: get-theme-var(cart-btn-group, gap);

  #{mod(right)} {
    @include flex-row;

    justify-content: flex-end;
    min-width: 416px;

    .#{$btn} {
      width: 200px;
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-main-4);
$breadcrumb: ns(breadcrumb);
$wrapper: el($root, wrapper);
$content: el($root, content);

.#{$wrapper} {
  display: flex;
  position: relative;
  width: get-theme-var(header-main-4, header-wrapper-width);
  overflow: hidden;

  #{mod(top-left)} {
    align-items: flex-start;
    justify-content: flex-start;
  }

  #{mod(top-center)} {
    align-items: flex-start;
    justify-content: center;
  }

  #{mod(top-right)} {
    align-items: flex-start;
    justify-content: flex-end;
  }

  #{mod(middle-left)} {
    align-items: center;
    justify-content: flex-start;
  }

  #{mod(middle-center)} {
    align-items: center;
    justify-content: center;
  }

  #{mod(middle-right)} {
    align-items: center;
    justify-content: flex-end;
  }

  #{mod(bottom-left)} {
    align-items: flex-end;
    justify-content: flex-start;
  }

  #{mod(bottom-center)} {
    align-items: flex-end;
    justify-content: center;
  }

  #{mod(bottom-right)} {
    align-items: flex-end;
    justify-content: flex-end;
  }
}

.#{$content} {
  position: absolute;
  z-index: 2;
  width: get-theme-var(header-main-4, header-content-width);
  padding: get-theme-var(header-main-4, header-content-padding);

  @include tablet {
    padding: get-theme-var(header-main-4, header-content-padding-desktop);
    width: get-theme-var(header-main-4, header-content-width-desktop);
  }

  #{mod(relative)} {
    position: relative;
  }

  #{mod(full-width)} {
    width: 100%;
  }

  > .#{$breadcrumb} {
    position: static;
    transform: none;
  }
}

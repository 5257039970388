@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;/* stylelint-disable scss/operator-no-newline-after */
$root: ns(recommendation);
$title: el($root, title);
$title-text: el($root, title-text);
$slider: ns(slider);
$slider-button: ns(button);
$slider-next-button: ns(next-button);
$slider-prev-button: ns(prev-button);
$slider-dots: ns(dots);
$product-item: ns(product-item);
$product-item-img: ns(product-image-img);
$popup-select-option: ns(popup-select-option);

.#{$root} {
  max-width: get-theme-var(general, page-width-desktop);
  width: get-theme-var(recommendation, width);
  margin-left: auto;
  margin-right: auto;

  .#{$slider} {
    @include tablet {
      padding: 0 calc(get-theme-var(recommendation, button-width) + 14px);
    }

    #{mod(disable-arrow-buttons)} {
      padding: 0;
    }

    .#{$slider-button} {
      &:hover {
        background-color: get-theme-var(recommendation, button-background-color-active);

        svg {
          filter: invert(100%);
        }
      }
    }
  }

  .#{$slider-prev-button},
  .#{$slider-next-button} {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .#{$slider-dots} {
    pointer-events: none;
  }

  .#{$product-item} {
    #{mod(no-border)},
    #{mod(border-has-padding)} {
      margin-left: calc(get-theme-var(product-list, gap-mobile) * 0.5);
      margin-right: calc(get-theme-var(product-list, gap-mobile) * 0.5);

      @include tablet {
        margin-left: calc(get-theme-var(product-list, gap-desktop) * 0.5);
        margin-right: calc(get-theme-var(product-list, gap-desktop) * 0.5);
      }
    }
  }

  .#{$product-item-img} {
    border-radius: 0;
  }

  .#{$popup-select-option} {
    @include tablet {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.#{$title} {
  margin: get-theme-var(recommendation-title, margin-mobile);
  display: flex;

  @include tablet {
    margin: get-theme-var(recommendation-title, margin);
  }

  &::before,
  &::after {
    width: 50%;
    min-width: 28px;
  }

  &::before {
    position: relative;
    width: 50%;
    border-top: get-theme-var(recommendation-title, border-width)
      get-theme-var(recommendation-title, border-style)
      get-theme-var(recommendation-title, border-color);
    transform: translateY(50%);
    content: '';
  }

  &::after {
    position: relative;
    width: 50%;
    border-top: get-theme-var(recommendation-title, border-width)
      get-theme-var(recommendation-title, border-style)
      get-theme-var(recommendation-title, border-color);
    transform: translateY(50%);
    content: '';
  }

  #{mod(left)} {
    &::before {
      width: 3%;
    }

    &::after {
      width: 97%;
    }

    .#{$title-text} {
      text-align: left;
    }
  }
}

.#{$title-text} {
  max-width: calc(100% - 60px);
  padding: get-theme-var(recommendation-title, padding-mobile);
  flex: 0 0 auto;
  text-align: center;
  word-break: break-word;

  @include tablet {
    padding: get-theme-var(recommendation-title, padding);
  }
}

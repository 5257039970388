@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-main-1);
$container: el($root, container);
$content: el($root, content);
$breadcrumb: ns(breadcrumb);

.#{$root} {
  max-width: get-theme-var(general, page-width-desktop);
  margin: 0 auto;
}

.#{$container} {
  @include flex-center-vertical;

  padding: get-theme-var(header-main-1, header-padding);
  gap: get-theme-var(header-main-1, header-container-gap);

  @include mobile {
    flex-direction: column;
    padding: get-theme-var(header-main-1, header-padding-mobile);
  }

  #{mod(middle-right)} {
    flex-direction: column-reverse;

    @include tablet {
      flex-direction: row-reverse;
    }
  }
}

.#{$content} {
  width: 100%;

  > .#{$breadcrumb} {
    position: static;
    transform: none;
  }
}

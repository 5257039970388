@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-image);
$inner: el($root, inner);
$overlay: el($root, overlay);

.#{$root} {
  width: 100%;
  overflow: hidden;

  @include mobile {
    @include flex-center;
  }

  #{mod(small)} {
    height: get-theme-var(header-image, size-small-mobile);

    @include tablet {
      height: get-theme-var(header-image, size-small);
    }
  }

  #{mod(medium)} {
    height: get-theme-var(header-image, size-medium-mobile);

    @include tablet {
      height: get-theme-var(header-image, size-medium);
    }
  }

  #{mod(large)} {
    height: get-theme-var(header-image, size-large-mobile);

    @include tablet {
      height: get-theme-var(header-image, size-large);
    }
  }

  #{mod(original)} {
    height: auto;
  }

  #{mod(as-main-1)} {
    align-self: stretch;
    position: relative;
    min-height: get-theme-var(header-main-1, header-image-min-height);
  }

  #{mod(as-main-3)} {
    position: relative;
  }

  #{mod(absolute)} {
    position: absolute;
    height: 100%;
  }
}

.#{$inner} {
  @include box(100%);

  display: block;
  object-fit: cover;
  object-position: center;
  max-width: none;
  max-height: none;
  min-height: 100%;
  min-width: 100%;

  @include mobile {
    flex: 1;
    height: auto;
  }
}

.#{$overlay} {
  @include pseudo(100%);

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  font-size: 0;
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-input);
$cursor-pointer: el($root, cursor-pointer);
$search-opening: ns(search-opening);

.#{$cursor-pointer} {
  cursor: pointer;
}

.#{$search-opening} {
  width: 100%;
  min-height: 100vh;
  touch-action: none;
  overflow: hidden !important;
}

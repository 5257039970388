@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(view-as);
$text: el($root, text);
$icon: el($root, icon);
$tooltip: ns(tooltip);
$tooltip-content: el($tooltip, content);
$icon-active: ns(view-as-icon--active);
$icon-grid: ns(view-as-icon--grid);
$icon-list: ns(view-as-icon--list);
$box-shadow-color: get-theme-var(view-as, background-color-multi-column);
$box-shadow-color-active: get-theme-var(view-as, background-color-active-multi-column);

.#{$root} {
  @include flex-center-vertical;

  justify-content: flex-start;

  .#{$tooltip} {
    &:last-child {
      .#{$icon} {
        margin-right: 0;
      }
    }
  }

  .#{$tooltip-content} {
    z-index: 5;
  }
}

.#{$text} {
  display: none;

  @include tablet {
    display: inline-block;
    margin-right: get-theme-var(view-as, margin-right-text);
    font-size: get-theme-var(view-as, title-font-size);
    font-weight: get-theme-var(view-as, title-font-weight);
    line-height: get-theme-var(view-as, title-line-height);
    color: get-theme-var(toolbar, color);
    white-space: nowrap;
  }
}

.#{$icon} {
  margin-left: get-theme-var(view-as, margin-left-icon);
  opacity: get-theme-var(view-as, icon-opacity);
  cursor: pointer;

  &::before {
    content: '';
    display: inline-block;
    width: get-theme-var(view-as, width-icon);
    height: get-theme-var(view-as, height-icon);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  @include tablet {
    @include flex-center;

    margin-left: 0;
    margin-right: get-theme-var(view-as, margin-right-icon);
  }

  &:hover,
  #{mod(active)} {
    opacity: 1;
  }

  #{mod(grid)} {
    &::before {
      background-image: get-theme-var(view-as, icon-grid-background-image);
    }

    &.#{$icon-active} {
      &::before {
        background-image: get-theme-var(view-as, icon-grid-background-image-active);
      }
    }

    #{mod(2)} {
      width: 19.5px;

      &::before {
        box-shadow: 7px 0 0 $box-shadow-color;
      }

      &:hover,
      &.#{$icon-active} {
        &::before {
          box-shadow: 7px 0 0 $box-shadow-color-active;
        }
      }
    }

    #{mod(3)} {
      width: 26px;

      &::before {
        box-shadow: 7px 0 0 $box-shadow-color, 14px 0 0 $box-shadow-color;
      }

      &:hover,
      &.#{$icon-active} {
        &::before {
          box-shadow: 7px 0 0 $box-shadow-color-active, 14px 0 0 $box-shadow-color-active;
        }
      }
    }

    #{mod(4)} {
      width: 33px;

      &::before {
        box-shadow: 7px 0 0 $box-shadow-color, 14px 0 0 $box-shadow-color,
          21px 0 0 $box-shadow-color;
      }

      &:hover,
      &.#{$icon-active} {
        &::before {
          box-shadow: 7px 0 0 $box-shadow-color-active, 14px 0 0 $box-shadow-color-active,
            21px 0 0 $box-shadow-color-active;
        }
      }
    }

    #{mod(5)} {
      width: 40px;

      &::before {
        box-shadow: 7px 0 0 $box-shadow-color, 14px 0 0 $box-shadow-color,
          21px 0 0 $box-shadow-color, 28px 0 0 $box-shadow-color;
      }

      &:hover,
      &.#{$icon-active} {
        &::before {
          box-shadow: 7px 0 0 $box-shadow-color-active, 14px 0 0 $box-shadow-color-active,
            21px 0 0 $box-shadow-color-active, 28px 0 0 $box-shadow-color-active;
        }
      }
    }

    #{mod(6)} {
      width: 47px;

      &::before {
        box-shadow: 7px 0 0 $box-shadow-color, 14px 0 0 $box-shadow-color,
          21px 0 0 $box-shadow-color, 28px 0 0 $box-shadow-color, 35px 0 0 $box-shadow-color;
      }

      &:hover,
      &.#{$icon-active} {
        &::before {
          box-shadow: 7px 0 0 $box-shadow-color-active, 14px 0 0 $box-shadow-color-active,
            21px 0 0 $box-shadow-color-active, 28px 0 0 $box-shadow-color-active,
            35px 0 0 $box-shadow-color-active;
        }
      }
    }
  }

  #{mod(list)} {
    &::before {
      background-image: get-theme-var(view-as, icon-list-background-image);
    }

    &.#{$icon-active} {
      &::before {
        background-image: get-theme-var(view-as, icon-list-background-image-active);
      }
    }
  }

  &:hover {
    &.#{$icon-grid} {
      &::before {
        background-image: get-theme-var(view-as, icon-grid-background-image-hover);
      }

      &.#{$icon-active} {
        &::before {
          background-image: get-theme-var(view-as, icon-grid-background-image-hover-active);
        }
      }
    }

    &.#{$icon-list} {
      &::before {
        background-image: get-theme-var(view-as, icon-list-background-image-hover);
      }

      &.#{$icon-active} {
        &::before {
          background-image: get-theme-var(view-as, icon-list-background-image-hover-active);
        }
      }
    }
  }

  #{mod(list-grid-multi-col)} {
    position: relative;
    display: flex;
    flex-shrink: 0;
    min-width: 19px;
    height: 26px;
    margin-right: get-theme-var(view-as, margin-right-multi-column);
    border: 1px solid get-theme-var(view-as, border-color-multi-column);
    opacity: 1;

    &::before {
      content: '';
      position: absolute;
      top: 3px;
      left: 3px;
      background: get-theme-var(view-as, background-color-multi-column);
      vertical-align: middle;
      width: 4px;
      height: 18px;
    }

    &.#{$icon-list} {
      width: 26px;

      &::before {
        width: 18px;
        height: 4px;
        box-shadow: 0 7px 0 $box-shadow-color, 0 14px 0 $box-shadow-color;
      }

      &:hover,
      &.#{$icon-active} {
        &::before {
          box-shadow: 0 7px 0 $box-shadow-color-active, 0 14px 0 $box-shadow-color-active;
          opacity: 1;
        }
      }
    }

    &:hover,
    &.#{$icon-active} {
      border-color: get-theme-var(view-as, border-color-active-multi-column);

      &::before {
        background: get-theme-var(view-as, background-color-active-multi-column);
        opacity: 1;
      }
    }
  }
}

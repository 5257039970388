@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-option);
$style-expand: ns(filter-option--style-expand);
$label: el($root, label);
$itemwrapper: el($root, itemwrapper);
$content-inner: el($root, content-inner);
$content-inner-view-more: el($root, content-inner--viewMoreHorizontal);
$option-listitem: el($root, listitem);
$list-option-item: el($root, list-option-item);
$option-item: el($root, item);
$option-item-amount: el($option-item, amount);
$option-item-label: el($option-item, label);
$option-box-item: el($root, box-item);
$option-swatch: el($root, swatch);
$option-range: el($root, range-slider);
$option-swatch-item: el($option-swatch, item);
$option-item-button: el($root, item-button);
$view-more-button: el($root, viewmorebtn);
$title: el($root, title);
$icon: el($root, icon);
$path: el($root, path);
$mobile-items-selected: el($root, mobile-items-selected);
$search-option: el($root, search-option);
$clear-button: ns(button--clear);
$clear-button-collection: ns(button--clear-collection);
$button-filter-option-color: ns(button--btn-filter-option-color);
$apply-button: ns(button--apply);

.#{$root} {
  box-sizing: border-box;
  padding: get-theme-var(filter-option, padding);
  border-bottom: 1px solid get-theme-var(filter-option, border-color);

  &:last-of-type {
    border-bottom: none;

    .#{$label} {
      border-bottom: none;
    }
  }

  &:first-of-type {
    @include tablet {
      padding-top: 0;
    }
  }

  // mode of filter tree horizontal
  #{mod(horizontal)} {
    position: static;
    display: inline-block;
    padding: 0;
    border: none;

    .#{$title} {
      font-weight: var(--boost-sd-app-filter-title-font-weight, 400);

      &::before {
        display: none;
      }
    }

    &:not(.#{$style-expand}) {
      .#{$icon} {
        top: calc(50% - 4px);
        right: 3px;
        display: inline-block;
        transform: rotate(270deg);
      }

      .#{$title} {
        #{mod(opening)} {
          .#{$icon} {
            transform: rotate(90deg);
          }

          &::after {
            @include pseudo(10px, 10px);

            bottom: -12px;
            left: 20px;
            transform: rotate(45deg);
            z-index: 2;
            background-color: get-theme-var(filter-tree-horizontal, background-color);
            box-shadow: -1px -1px 6px rgba(0, 0, 0, 0.12);
            border-radius: 0.5px;
          }
        }
      }
    }
  }

  #{mod(style3)} {
    position: static;
  }

  #{mod(style-expand)} {
    display: inline-block;
    vertical-align: top;
    width: 25%;
    margin-right: 0;
    margin-bottom: 43px;
    padding-right: 15px;
    padding-left: 15px;

    .#{$title} {
      font-weight: 600;
      text-transform: uppercase;

      &::before,
      &::after {
        display: none;
      }
    }

    .#{$label} {
      #{mod(inline-block)} {
        margin-bottom: 14px;
      }
    }
  }
}

.#{$label} {
  @include font-size(
    get-theme-var(filter-option, font-size-label),
    get-theme-var(filter-option, line-height-label)
  );

  padding: get-theme-var(filter-option, label-padding-mobile);
  margin-bottom: get-theme-var(filter-option, label-margin-bottom);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include tablet {
    display: flow-root;
    padding: 0;
    cursor: pointer;
    position: relative;
  }

  #{mod(collapsed)} {
    margin-bottom: 0;

    .#{$title} {
      &::before {
        margin-bottom: 1px;
        border-top-color: transparent;
        border-left-color: currentColor;
      }
    }

    .#{$icon} {
      transform: rotate(180deg);
    }
  }

  #{mod(hidden)} {
    display: none !important;
  }

  // style option title of horizontal
  #{mod(inline-block)} {
    margin-right: get-theme-var(filter-tree-horizontal, option-label-margin-right);
    margin-bottom: 7px;

    .#{$title} {
      padding: 5px 15px 5px 0;
    }
  }
}

.#{$title} {
  @include box(auto);
  @include font-size(
    var(--boost-sd-app-filter-title-font-size, 14px),
    get-theme-var(filter-option, title-line-height),
    var(--boost-sd-app-filter-title-font-weight, 400)
  );

  display: inline-block;
  position: relative;
  padding: 0;
  margin: 0;
  min-height: auto;
  min-width: 0;
  border: none;
  border-radius: 0;
  box-shadow: none;
  background: none;
  cursor: pointer;
  text-align: left;
  text-transform: var(--boost-sd-app-filter-title-font-transform, unset);
  color: var(--boost-sd-app-filter-title-text-color, inherit);
  font-family: var(--boost-sd-app-filter-title-font-family, inherit);

  @include tablet {
    font-weight: var(--boost-sd-app-filter-title-font-weight, 700);
    word-break: break-word;

    &::before {
      @include pseudo(0, 0, inline-block, static);

      background: 0 0;
      border: 5px solid transparent;
      border-top-color: currentColor;
      margin: 0 10px -2px 0;
      transition: none;
    }
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--boost-sd-app-filter-title-text-color, inherit);
    background-color: inherit;
    border: none;
    box-shadow: none;
    outline: none !important;
  }

  #{mod(hidden-before)} {
    &::before {
      display: none;
    }
  }
}

.#{$icon} {
  @include box(auto);

  position: absolute;
  top: 4px;
  right: 1px;
  transform: rotate(-90deg);
  transition: transform 0.2s ease-in-out;

  @include tablet {
    display: none;
  }
}

.#{$path} {
  fill: var(--boost-sd-app-filter-title-text-color, #222);
}

.#{$mobile-items-selected} {
  @include font-size(
    get-theme-var(filter-option, mobile-item-selected-font-size),
    get-theme-var(filter-option, mobile-item-selected-line-height),
    get-theme-var(filter-option, mobile-item-selected-font-weight)
  );

  color: var(--boost-sd-app-filter-option-text-color, #222);
}

.#{$content-inner} {
  @include scrollbars(
    get-theme-var(filter-option-scrollbar, width),
    get-theme-var(filter-option-scrollbar, scrollbar-track-color),
    get-theme-var(filter-option-scrollbar, scrollbar-thumb-color)
  );

  #{mod(scrollbar)} {
    padding-right: 10px;
    overflow-x: hidden;
    overflow-y: auto;
    width: auto;
    max-height: 214px;
    scrollbar-width: thin;
    position: relative;
    top: 0;
    left: 0;

    @include tablet {
      padding-right: 10px;
      margin-right: 0;
    }
  }

  #{mod(viewMoreHorizontal)} {
    max-height: 60vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 10px;
  }
}

.#{$itemwrapper} {
  overflow: inherit;
  width: 100%;
  max-height: max-content;

  #{mod(collapsed)} {
    overflow: hidden;
    max-height: 0;
    padding-bottom: 0;
  }

  #{mod(hidden)} {
    display: none !important;
  }

  #{mod(horizontal)} {
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
    position: absolute;
    background-color: get-theme-var(filter-tree-horizontal, background-color);
    z-index: 2;
    padding: 24px 20px;
    max-height: unset;

    .#{$content-inner} {
      #{mod(scrollbar)} {
        max-height: 224px;

        &.#{$content-inner-view-more} {
          max-height: 45vh;
        }
      }
    }

    .#{$option-item-button} {
      #{mod(with-checkbox)} {
        &::before {
          @include box(16px);
        }
      }
    }

    .#{$option-item-amount} {
      display: inline-block;
      float: none;
    }

    .#{$apply-button} {
      @include tablet {
        margin-top: 12px;
      }
    }

    &.#{$itemwrapper} {
      #{mod(style-expand)} {
        &::before {
          display: none;
        }
      }
    }
  }

  #{mod(right)} {
    right: get-theme-var(filter-tree-horizontal, option-label-margin-right);
    left: auto;
  }

  #{mod(style1)},
  #{mod(style2)},
  #{mod(style3)} {
    border-radius: 8px;

    .#{$clear-button} {
      margin-left: 20px;
      margin-top: 24px;
      text-decoration: underline;
      float: inherit;
    }

    .#{$clear-button-collection} {
      margin-left: 0;
    }

    .#{$button-filter-option-color} {
      color: var(--boost-sd-app-filter-option-text-color, inherit);

      &.#{$apply-button} {
        &:hover,
        &:active,
        &:focus {
          color: get-theme-var(button-apply, color-hover);
        }
      }
    }
  }

  #{mod(style1)} {
    width: 240px;

    .#{$option-item} {
      width: 100%;

      &.#{$option-box-item} {
        width: auto;
      }
    }
  }

  #{mod(style2)} {
    width: 532px;

    .#{$option-listitem} {
      > .#{$option-item} {
        &:not(.#{$option-box-item}):not(.#{$option-swatch-item}) {
          display: inline-block;
          width: calc(50% - 10px);

          &:nth-child(odd) {
            margin-right: 20px;
          }
        }
      }

      #{mod(list)} {
        .#{$option-swatch-item} {
          display: inline-block;
          width: calc(50% - 10px);
          margin-bottom: 5px;

          &:nth-child(odd) {
            margin-right: 20px;
          }
        }
      }
    }
  }

  #{mod(style3)} {
    width: 100%;
    left: 0;

    .#{$option-listitem} {
      > .#{$option-item} {
        &:not(.#{$option-box-item}):not(.#{$option-swatch-item}) {
          display: inline-block;
          width: calc(33.333% - 30px);
          margin-right: 28px;
        }
      }

      #{mod(list)} {
        .#{$option-swatch-item} {
          display: inline-block;
          width: calc(33.333% - 12px);
          margin-right: 10px;
          margin-bottom: 5px;
        }
      }
    }
  }

  #{mod(style-expand)} {
    position: relative;
    z-index: unset;
    box-shadow: unset;
    padding: 0;
    background-color: inherit;

    .#{$option-item} {
      width: 100%;
    }

    .#{$option-box-item},
    .#{$option-swatch-item} {
      width: auto;
    }
  }
}

.#{$search-option} {
  @include font-size(16px, get-theme-var(filter-search-option, line-height));

  padding: get-theme-var(filter-search-option, padding);
  margin: get-theme-var(filter-search-option, margin);
  width: 100%;
  min-height: 36px;
  background-color: get-theme-var(filter-search-option, background-color);
  border: 1px solid get-theme-var(filter-search-option, border-color);
  border-radius: get-theme-var(filter-search-option, border-radius);
  color: get-theme-var(filter-search-option, color);

  @include tablet {
    font-size: get-theme-var(filter-search-option, font-size);
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
  }
}

.#{$option-listitem} {
  margin: 0;
  padding-left: 0;
  list-style: none;

  #{mod(inline)} {
    margin: 0 -15px;

    @include tablet {
      margin: 0 -6px;
    }

    .#{$option-item} {
      display: inline-grid;
      margin: 5px 15px;

      @include tablet {
        margin: 5px 6px;
      }

      &.#{$option-swatch-item} {
        @include tablet {
          width: auto;
        }

        .#{$option-item-button} {
          text-align: left;
        }
      }
    }

    .#{$option-item-button} {
      padding: 8px;
      text-align: center;

      #{mod(selected)},
      &:hover,
      &:focus {
        font-weight: 400;
        color: inherit;
      }

      .#{$option-item-label} {
        margin-right: 0;
      }
    }
  }
}

.#{$list-option-item} {
  &:last-child {
    margin-bottom: 0;
  }
}

.#{$view-more-button} {
  @include font-size(
    get-theme-var(filter-option, viewmore-font-size),
    get-theme-var(filter-option, viewmore-line-height)
  );
  @include box(auto);

  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  margin: get-theme-var(filter-option, viewmore-margin);
  transition: none;

  #{mod(horizontal)} {
    display: block;
  }

  &:focus {
    outline: 0 !important;
  }

  &:hover,
  &:focus {
    color: inherit;
    background: inherit;
    opacity: 0.7;
  }
}

/* stylelint-disable-next-line no-duplicate-selectors */
.#{$root} {
  #{mod(multi-level-tag)} {
    .#{$option-item-amount} {
      display: none;
    }
  }
}

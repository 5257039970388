@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-image);
$wrapper: el($root, wrapper);
$row: el($root, row);
$column: el($root, column);
$layout: el($root, layout);
$img: el($root, img);
$product-swatch-options: ns(product-swatch-options);
$button: ns(button);

.#{$root} {
  @include box(100%);

  overflow: hidden;
  position: relative;

  #{mod(has-border)} {
    border: 1px solid #222;
  }

  #{mod(blur)} {
    opacity: 0.5;
  }
}

.#{$wrapper} {
  position: relative;

  #{mod(square)} {
    aspect-ratio: 1/1;
  }

  #{mod(aspect-ratio-3-4)} {
    aspect-ratio: 3/4;
  }

  #{mod(portrait)},
  #{mod(manual)} {
    aspect-ratio: 2/3;
  }

  #{mod(landscape)} {
    aspect-ratio: 4/3;
  }

  #{mod(full-box)} {
    @include box(100%);
  }

  #{mod(crop-top)} {
    .#{$img} {
      object-position: top;
    }
  }

  #{mod(crop-center)} {
    .#{$img} {
      object-position: center;
    }
  }

  #{mod(crop-bottom)} {
    .#{$img} {
      object-position: bottom;
    }
  }

  #{mod(has-second-image)} {
    &:hover {
      .#{$img} {
        #{mod(second)} {
          opacity: 0;
        }

        @include tablet {
          #{mod(second)} {
            opacity: 1;
          }
        }
      }
    }
  }

  #{mod(zoom-in)} {
    &:hover {
      .#{$img} {
        // zoom-in but only one image
        &:only-child {
          transform: scale(get-theme-var(product-image, scale-number));
        }

        #{mod(second)} {
          transform: scale(get-theme-var(product-image, scale-number));
        }
      }
    }
  }
}

.#{$img} {
  @include box(100%);

  background: get-theme-var(product-image, background);
  display: block;
  object-fit: cover;
  transition: transform get-theme-var(product-image, transition-time) ease;

  #{mod(display-block)} {
    display: block !important;
  }

  #{mod(main)} {
    position: relative;
  }

  #{mod(second)} {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
  }

  #{mod(as-slider)} {
    border-radius: get-theme-var(product-image, border-radius-as-slider);
  }
}

// This row and column part handle the possition of elements on image
.#{$row} {
  gap: get-theme-var(product-image, gap);
  width: 100%;
  position: absolute;
  z-index: 1;

  #{mod(top)} {
    top: 0;
  }

  #{mod(middle)} {
    top: 50%;
  }

  #{mod(bottom)} {
    bottom: 0;
  }

  #{mod(vertical)} {
    padding: get-theme-var(product-image-layout, gap-vertical);

    .#{$column} {
      flex-direction: column;
      gap: get-theme-var(product-image-layout, gap);

      > * {
        margin: 0;
      }

      > div {
        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .#{$button} {
        flex-shrink: 0;
        max-width: 100%;
      }

      #{mod(in-top)} {
        justify-content: flex-start;
      }

      #{mod(in-middle)} {
        justify-content: center;
      }

      #{mod(in-bottom)} {
        justify-content: flex-end;
      }

      #{mod(left)} {
        align-items: flex-start;
      }

      #{mod(center)} {
        align-items: center;
      }

      #{mod(right)} {
        align-items: flex-end;
      }
    }
  }
}

.#{$column} {
  @include box(100%, 0);

  display: flex;

  > div {
    margin: get-theme-var(product-image-layout, gap)
      calc(get-theme-var(product-image-layout, gap) * 0.5);

    &:first-child {
      margin-left: calc(
        get-theme-var(product-image-layout, gap) *
          get-theme-var(product-image-layout, padding-image)
      );
    }

    &:last-child {
      margin-right: calc(
        get-theme-var(product-image-layout, gap) *
          get-theme-var(product-image-layout, padding-image)
      );
    }
  }

  #{mod(in-top)} {
    align-items: flex-start;
  }

  #{mod(in-middle)} {
    align-items: center;
  }

  #{mod(in-bottom)} {
    align-items: flex-end;
  }

  #{mod(left)} {
    justify-content: flex-start;

    .#{$product-swatch-options} {
      justify-content: flex-start;
    }
  }

  #{mod(center)} {
    justify-content: center;

    .#{$product-swatch-options} {
      justify-content: center;
    }
  }

  #{mod(right)} {
    justify-content: flex-end;

    .#{$product-swatch-options} {
      justify-content: flex-end;
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(suggestion-queries);
$group: el($root, group);
$list: el($root, list);
$item: el($root, item);
$link: el($root, link);

.#{$group} {
  @include tablet {
    position: relative;
    padding: 14px 16px;

    &::after {
      @include pseudo(auto, 1px);

      left: 24px;
      right: 24px;
      bottom: 0;
      background-color: #f5f5f5;
    }
  }

  &:last-child {
    &::after {
      @include tablet {
        display: none;
      }
    }
  }

  .#{$link} {
    @include tablet {
      padding: 6px 8px;
    }
  }

  .#{$item} {
    @include tablet {
      padding: 0;
    }
  }
}

.#{$list} {
  width: 100%;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  list-style: none;
  overflow: hidden;

  .#{$list} {
    .#{$item} {
      padding-left: 16px;

      @include tablet {
        padding-left: 8px;
      }
    }
  }
}

.#{$item} {
  #{mod(suggestion)} {
    margin: 0;
  }

  #{mod(mobile-style1)} {
    background-color: #fff;
    box-shadow: inset 0 -1px 0 #e1e3e5;

    &:last-child {
      box-shadow: none;
    }
  }
}

.#{$link} {
  @include font-size(14px, calc(20 / 14));

  display: block;
  width: 100%;
  color: #222;
  text-decoration: none;
  word-break: break-word;

  &:hover,
  &:focus,
  &:visited {
    color: #222;
    background-color: #e8e9eb;
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.02);
    outline: none;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(filter-tree-toggle-button);
$icon: el($root, icon);
$title: el($root, title);

.#{$root} {
  @include flex-space-between;
  @include box(auto);
  @include font-size(inherit, inherit);

  flex: 0 0 50%;
  min-height: auto;
  margin: 0;
  max-width: 50%;
  padding: 12px calc(get-theme-var(general, gutter-mobile));
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;

  &:hover,
  &:focus {
    background: inherit;
    outline: none !important;
  }

  @include tablet {
    border-right: 1px solid get-theme-var(filter-tree-toggle-button, border-color);
    padding: 0 24px 0 0;
    max-width: 100%;
    margin-right: 24px;

    &:hover,
    &:focus {
      border-right: 1px solid get-theme-var(filter-tree-toggle-button, border-color);
    }
  }
}

.#{$icon} {
  display: flex;
  order: 2;

  @include tablet {
    @include flex-center;

    order: 0;
    margin-right: 8px;
  }

  svg {
    @include box(20px);

    max-width: none;
  }
}

.#{$title} {
  @include font-size(
    get-theme-var(filter-tree-toggle-button, font-size),
    get-theme-var(filter-tree-toggle-button, line-height)
  );

  color: get-theme-var(toolbar, color);
  text-align: left;

  @include mobile {
    margin-right: 10px;
    display: inline-block;
    width: 100%;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search);
$autocomplete: el($root, autocomplete);
$no-product: el($root, no-product);
$no-other: el($root, no-other);
$results: el($root, results);
$no-result: ns(no-search-result);
$column: el($root, column);
$item: ns(suggestion-queries-item);
$product: el($item, product);
$dym: ns(spelling-correction);
$header: ns(header-item);
$product-list: el($root, product-list);
$product-header: el($root, product-list-header);
$product-items: el($root, product-list-items);
$loading: el($root, loading);

@import '../SearchContentResultVariables';

// common spacing between columns
$gutter: 24px;

.#{$results} {
  // desktop style 3 - full width
  #{mod(full-width)} {
    height: 100%;
    max-height: unset !important; // override max-height inline css of other style
    box-shadow: inherit;

    .#{$loading} {
      width: 100vw;
    }

    .#{$header},
    .#{$product-header} {
      @include font-size(14px, calc(20 / 14), 600);
    }

    .#{$dym} {
      padding: 20px 24px;

      & + .#{$product-list} {
        margin-top: -6px;
      }
    }

    .#{$column} {
      background-color: #fff;
      border-radius: 4px;

      #{mod(others)} {
        width: $column-width-1;
        padding-top: 4px;
        background-color: #fff;

        @include desktop {
          width: $column-width-6;
        }
      }

      .#{$product-list} {
        padding: 6px 16px 14px;
        background-color: #fff;
      }
    }

    .#{$autocomplete} {
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      padding: 0;
      background-color: transparent;
      border-radius: 4px;

      &.#{$no-product} {
        flex-direction: column;

        .#{$column} {
          #{mod(others)} {
            width: 100%;
          }
        }

        .#{$dym} {
          &::after {
            opacity: 1;
          }
        }
      }

      &.#{$no-other} {
        .#{$column} {
          #{mod(products)} {
            @include box(100%);
          }
        }
      }
    }

    .#{$no-result} {
      &::after {
        display: none;
      }
    }

    &.#{$results} {
      #{mod(product-position-right)} {
        .#{$autocomplete} {
          &:not(.#{$no-product}):not(.#{$no-other}) {
            .#{$column} {
              #{mod(products)} {
                width: calc(100% - $column-width-1 - $gutter);
                margin-left: $gutter;

                @include desktop {
                  width: calc(100% - $column-width-6 - $gutter);
                }
              }
            }
          }
        }
      }

      #{mod(product-position-left)} {
        .#{$autocomplete} {
          &:not(.#{$no-product}):not(.#{$no-other}) {
            .#{$column} {
              #{mod(products)} {
                width: calc(100% - $column-width-1 - $gutter);
                margin-right: $gutter;

                @include desktop {
                  width: calc(100% - $column-width-6 - $gutter);
                }
              }
            }
          }
        }
      }

      #{mod(product-type-grid)} {
        .#{$item} {
          #{mod(product)} {
            flex: 0 0 20%;
            max-width: 20%;
          }
        }

        .#{$product} {
          #{mod(left)} {
            margin-bottom: 8px;

            &::before {
              padding-top: $product-image-size-5;
            }
          }
        }
      }

      #{mod(product-type-list)} {
        .#{$product-items} {
          display: flex;
          flex-flow: row wrap;
        }

        .#{$product-header} {
          flex: 0 0 100%;
          max-width: 100%;
        }

        .#{$product} {
          #{mod(left)} {
            width: 84px;

            &::before {
              padding-top: $product-image-size-4;
            }
          }

          #{mod(right)} {
            width: calc(100% - 100px);
          }
        }

        &.#{$results} {
          #{mod(product-per-row-2)} {
            .#{$item} {
              #{mod(product)} {
                flex: 0 0 50%;
                max-width: 50%;
              }
            }
          }

          #{mod(product-per-row-3)} {
            .#{$item} {
              #{mod(product)} {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
              }
            }
          }
        }
      }
    }
  }
}

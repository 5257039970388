@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-form);
$title: el($root, title);
$wrapper: el($root, wrapper);
$input: el($root, input);
$scope: el($root, scope);
$scope-label: el($root, scope-label);
$scope-item: el($root, scope-item);
$button-x: ns(button-text-x);

.#{$root} {
  @include flex-center-vertical;

  position: relative;
  max-width: 592px;
  width: 100%;
  margin: 0 auto;
}

.#{$wrapper} {
  padding: get-theme-var(search-form-wrapper, padding);
  background-color: get-theme-var(search-form-wrapper, background-color);
  border-bottom: get-theme-var(search-form-wrapper, border-bottom);
}

.#{$title} {
  @include font-size(
    get-theme-var(search-form-title, font-size),
    get-theme-var(search-form-title, line-height),
    get-theme-var(search-form-title, font-weight),
    get-theme-var(search-form-title, letter-spacing)
  );

  margin: get-theme-var(search-form-title, margin);
  color: get-theme-var(search-form-title, color-title);
  word-break: break-word;
  text-align: center;
}

.#{$input} {
  &[type='search'] {
    @include font-size(
      get-theme-var(search-form-input, font-size),
      get-theme-var(search-form-input, line-height)
    );

    font-family: inherit;
    padding: get-theme-var(search-form-input, padding);
    margin: 0;
    width: 100%;
    max-width: none;
    color: get-theme-var(search-form-input, color);
    text-align: left;
    background-color: get-theme-var(search-form-input, background-color);
    border: 1px solid get-theme-var(search-form-input, border-color);
    border-radius: get-theme-var(search-form-input, border-radius);
    box-sizing: border-box;
    cursor: pointer;
  }

  &:focus {
    box-shadow: none;
    outline: 0;
    outline-offset: 0;
  }

  &::-webkit-search-cancel-button,
  &::-webkit-search-decoration {
    appearance: none;
  }
}

.#{$scope} {
  @include flex-center-vertical;

  flex-wrap: wrap;
  padding: 7px 16px;
  margin: 0 auto;
  max-width: 592px;
  text-align: left;
}

.#{$scope-label} {
  @include font-size(14px, calc(20 / 14));

  margin-right: 3px;
}

.#{$scope-item} {
  @include flex-center;
  @include font-size(12px, calc(18 / 12), 400);

  padding: 5px 12px;
  margin: 3px 6px;
  color: #222;
  background-color: #e8e9eb;
  border-radius: 20px;

  .#{$button-x} {
    @include box(12px);

    margin-left: 5px;

    &::before,
    &::after {
      width: inherit;
      height: 1px;
    }
  }
}

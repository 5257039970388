@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(tooltip);
$arrow: el($root, arrow);
$container: el($root, container);
$content: el($root, content);

.#{$root} {
  position: relative;
  display: inline;

  #{mod(filter-tree)} {
    &::before {
      content: '?';
      width: 16px;
      height: 16px;
      border: 1px solid #bbb;
      border-radius: 50%;
      display: inline-block;
      text-align: center;
      line-height: calc(14 / 11);
      font-size: 11px;
      margin-left: 5px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}

.#{$content} {
  width: max-content;
  max-width: 180px;
  display: none;
  z-index: 2;
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  color: #454545;
  font-size: 13px;
  padding: 10px 15px;
  line-height: calc(20 / 13);
  text-transform: initial;
  font-weight: 400;
  text-align: center;

  @include desktop {
    max-width: 200px;
  }
}

.#{$arrow} {
  width: 7px;
  height: 7px;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-top: none;
  border-left: none;
  position: absolute;
  left: calc(50% - 4px);
  bottom: -4px;
  transform: rotate(45deg);
  z-index: 2;
}

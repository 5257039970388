@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(search-result-toolbar);
$container: el($root, container);
$item: el($root, item);
$panel-wrapper: el($root, panel-wrapper);
$panel: el($root, panel);

.#{$root} {
  @include flex-center-vertical;

  max-width: get-theme-var(general, page-width-desktop);
  margin: 0 auto get-theme-var(search-result-toolbar, margin-bottom);
  background-color: get-theme-var(search-result-toolbar, background-color);
  border: get-theme-var(search-result-toolbar, border);

  @include mobile {
    flex-wrap: wrap;
    padding: 0;
    border-left: get-theme-var(search-result-toolbar, border-left-mobile);
    border-right: get-theme-var(search-result-toolbar, border-right-mobile);
    border-bottom: get-theme-var(search-result-toolbar, border-bottom-mobile);
  }

  @include tablet {
    padding: get-theme-var(search-result-toolbar, padding);
    border-bottom: get-theme-var(search-result-toolbar, border-bottom-width)
      get-theme-var(search-result-toolbar, border-bottom-style)
      get-theme-var(search-result-toolbar, border-bottom-color);
  }
}
.#{$container} {
  width: 100%;
}

.#{$item} {
  font-size: get-theme-var(search-result-toolbar, font-size);
  font-weight: get-theme-var(search-result-toolbar, font-weight);
  line-height: get-theme-var(search-result-toolbar, line-height);
  color: get-theme-var(search-result-toolbar, color);

  @include mobile {
    flex: 0 0 100%;
    padding: get-theme-var(search-result-toolbar, padding-item);
    border-bottom: get-theme-var(search-result-toolbar, border-bottom-width-item)
      get-theme-var(search-result-toolbar, border-bottom-style-item)
      get-theme-var(search-result-toolbar, border-bottom-color-item);

    &:first-child {
      padding: get-theme-var(search-result-toolbar, padding-item-mobile);
    }
  }

  @include tablet {
    &:first-child {
      flex: 1;
    }
  }
}

.#{$panel-wrapper} {
  list-style: none;
  display: flex;

  @include mobile {
    justify-content: space-between;
  }
}

.#{$panel} {
  cursor: pointer;

  #{mod(active)} {
    font-weight: get-theme-var(search-result-toolbar, font-weight-active);
    color: get-theme-var(search-result-toolbar, color-active);
  }

  &:not(:last-child) {
    margin-right: get-theme-var(search-result-toolbar, margin-right-panel);
  }
}

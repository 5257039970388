@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(header-main-3);
$content: el($root, content);
$breadcrumb: ns(breadcrumb);

.#{$root} {
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid get-theme-var(header-main-3, border-color);
}

.#{$content} {
  @include flex-column;

  padding: get-theme-var(header-main-3, header-content-padding);

  #{mod(middle-left)} {
    text-align: left;
  }

  #{mod(middle-center)} {
    text-align: center;
  }

  #{mod(middle-right)} {
    text-align: right;
  }

  @include mobile {
    padding: get-theme-var(header-main-3, header-content-mobile-padding);
  }

  > .#{$breadcrumb} {
    position: static;
    transform: none;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(button-text-x);

.#{$root} {
  @include flex-center;
  @include box(16px);

  position: relative;
  margin: 0;
  padding: 0;
  min-width: auto;
  min-height: auto;
  border: none;
  background: transparent;
  box-shadow: none;
  font-size: 0;
  cursor: pointer;

  &::before,
  &::after {
    @include box(16px, 2px);

    content: '';
    position: absolute;
    inset: auto;
    display: block !important;
    border-radius: 2px;
    background-color: get-theme-var(button-text-x, background-color);
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &:hover,
  &:focus {
    outline: none !important;
    background-color: transparent;
  }

  #{mod(close-style1)} {
    @include box(48px, 64px);

    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M19 9.0007H3.661L9.658 3.7537C10.074 3.3897 10.116 2.7577 9.753 2.3427C9.387 1.9247 8.756 1.8847 8.342 2.2477L0.342 9.2477C0.334 9.2547 0.331 9.2657 0.323 9.2727C0.25 9.3417 0.195 9.4247 0.146 9.5127C0.128 9.5427 0.101 9.5667 0.087 9.5997C0.032 9.7227 0 9.8567 0 10.0007C0 10.1447 0.032 10.2787 0.087 10.4017C0.101 10.4347 0.128 10.4587 0.146 10.4897C0.196 10.5767 0.25 10.6597 0.323 10.7287C0.331 10.7357 0.334 10.7467 0.342 10.7537L8.342 17.7537C8.531 17.9197 8.766 18.0007 9 18.0007C9.278 18.0007 9.555 17.8857 9.753 17.6587C10.116 17.2437 10.074 16.6117 9.658 16.2477L3.661 11.0007H19C19.553 11.0007 20 10.5537 20 10.0007C20 9.4477 19.553 9.0007 19 9.0007Z' fill='%23637381'/%3E%3Cmask id='mask0' mask-type='alpha' maskUnits='userSpaceOnUse' x='0' y='2' width='20' height='17'%3E%3Cpath fillRule='evenodd' clipRule='evenodd' d='M19 9.0007H3.661L9.658 3.7537C10.074 3.3897 10.116 2.7577 9.753 2.3427C9.387 1.9247 8.756 1.8847 8.342 2.2477L0.342 9.2477C0.334 9.2547 0.331 9.2657 0.323 9.2727C0.25 9.3417 0.195 9.4247 0.146 9.5127C0.128 9.5427 0.101 9.5667 0.087 9.5997C0.032 9.7227 0 9.8567 0 10.0007C0 10.1447 0.032 10.2787 0.087 10.4017C0.101 10.4347 0.128 10.4587 0.146 10.4897C0.196 10.5767 0.25 10.6597 0.323 10.7287C0.331 10.7357 0.334 10.7467 0.342 10.7537L8.342 17.7537C8.531 17.9197 8.766 18.0007 9 18.0007C9.278 18.0007 9.555 17.8857 9.753 17.6587C10.116 17.2437 10.074 16.6117 9.658 16.2477L3.661 11.0007H19C19.553 11.0007 20 10.5537 20 10.0007C20 9.4477 19.553 9.0007 19 9.0007Z' fill='white'/%3E%3C/mask%3E%3Cg mask='url(%23mask0)'%3E%3C/g%3E%3C/svg%3E%0A");
    background-position: center center;
    background-size: 16px;
    background-repeat: no-repeat;

    &::before,
    &::after {
      display: none !important;
    }
  }

  #{mod(close-style3)} {
    @include box(84px, 88px);

    position: absolute;
    right: 0;
    order: 3;
    border-radius: 4px;

    &::before,
    &::after {
      @include box(32px, 2px);

      background-color: get-theme-var(button-text-x, background-color-close-style3);
    }

    &:hover {
      border-radius: 4px;

      &::before,
      &::after {
        background-color: get-theme-var(button-text-x, background-color-hover-close-style3);
      }
    }
  }

  #{mod(clear-style1)} {
    @include box(44px);

    position: absolute;
    right: 24px;

    @include tablet {
      right: 40px;
    }
  }

  #{mod(clear-style3)} {
    @include box(48px);

    position: absolute;
    right: 100px;
  }

  #{mod(as-close-modal)} {
    @include box(24px);

    position: absolute;
    top: 16px;
    right: 16px;

    @include tablet {
      top: 20px;
      right: 20px;
    }

    &::before,
    &::after {
      @include pseudo(1px, 20px);

      border-radius: 0;
      background-color: get-theme-var(button-text-x, background-color-close-modal);
    }
  }

  #{mod(as-close-mini-popup)} {
    @include box(16px);

    position: absolute;
    top: 5px;
    right: 5px;

    &::before,
    &::after {
      @include pseudo(1px, 12px);

      border-radius: 0;
      background-color: get-theme-var(button-text-x, background-color-close-modal);
    }
  }

  #{mod(as-close-modal-side-cart)} {
    @include box(get-theme-var(button-text-x, size-close));

    position: fixed;
    top: 0;
    right: 0;

    &::before,
    &::after {
      @include pseudo(2px, 22px);

      top: 50%;
      border-radius: 0;
    }

    &::before {
      transform: translateY(-50%) rotate(45deg);
    }

    &::after {
      transform: translateY(-50%) rotate(-45deg);
    }
  }

  #{mod(clear-input-search-form)} {
    position: absolute;
    width: 28px;
    right: 86px;

    &::before,
    &::after {
      width: 20px;
      background-color: get-theme-var(button-text-x, background-color-clear-search-form);
      border-radius: 0;
    }
  }

  #{mod(clear-input-collection-search)} {
    width: 28px;

    &::before,
    &::after {
      width: get-theme-var(button-text-x, width-clear-input-collection-search);
      height: get-theme-var(button-text-x, height-clear-input-collection-search);
      border-radius: 0;
    }
  }
}

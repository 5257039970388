@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(cart-item);
$info: el($root, info);
$image: el($root, image);
$content: el($root, content);
$title: el($root, title);
$price: el($root, price);
$price-total: el($root, price-total);
$option: el($root, option);
$option-title: el($root, option-title);
$option-value: el($root, option-value);
$quantity: el($root, quantity);
$quantity-price: el($root, quantity-price);
$quantity-character: el($root, quantity-character);

.#{$root} {
  display: flex;
  justify-content: space-between;
  position: relative;
  column-gap: 16px;
}

.#{$quantity} {
  @include flex-center-vertical;

  flex-wrap: wrap;
  justify-content: get-theme-var(cart-item-quantity, justify-content);
  color: get-theme-var(cart-item-quantity, color);
  font-size: get-theme-var(cart-item-quantity, font-size);
}

.#{$quantity-price} {
  @include font-size(
    get-theme-var(cart-item-quantity, price-font-size),
    get-theme-var(cart-item-quantity, price-line-height),
    get-theme-var(cart-item-quantity, price-font-weight),
    get-theme-var(cart-item-quantity, price-letter-spacing)
  );

  display: flex;
  color: get-theme-var(cart-item-quantity, price-color);
}

.#{$quantity-character} {
  margin: get-theme-var(cart-item-quantity, margin);
}

.#{$image} {
  width: get-theme-var(cart-item, image-width);
  cursor: pointer;
}

.#{$content} {
  width: get-theme-var(cart-item, content-width);

  #{mod(wider-width)} {
    @include flex-space-between;

    column-gap: 16px;
  }
}

.#{$title} {
  @include font-size(
    get-theme-var(cart-item-title, font-size),
    get-theme-var(cart-item-title, line-height),
    get-theme-var(cart-item-title, font-weight),
    get-theme-var(cart-item-title, letter-spacing)
  );

  margin-bottom: get-theme-var(cart-item-title, margin-bottom);
  padding-right: get-theme-var(cart-item-title, padding-right);
  color: get-theme-var(cart-item-title, color);
  cursor: pointer;
  word-break: break-word;

  #{mod(non-space)} {
    padding-right: 0;
  }
}

.#{$option} {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: get-theme-var(cart-item-option, margin-bottom);
}

.#{$option-title} {
  @include font-size(
    get-theme-var(cart-item-option-title, font-size),
    get-theme-var(cart-item-option-title, line-height),
    get-theme-var(cart-item-option-title, font-weight),
    get-theme-var(cart-item-option-title, letter-spacing)
  );

  color: get-theme-var(cart-item-option-title, color);
  margin-right: get-theme-var(cart-item-option-title, margin-right);
}

.#{$option-value} {
  @include font-size(
    get-theme-var(cart-item-option-value, font-size),
    get-theme-var(cart-item-option-value, line-height),
    get-theme-var(cart-item-option-value, font-weight),
    get-theme-var(cart-item-option-value, letter-spacing)
  );

  color: get-theme-var(cart-item-option-value, color);
}

.#{$price} {
  @include font-size(
    get-theme-var(cart-item-price, font-size),
    get-theme-var(cart-item-price, line-height),
    get-theme-var(cart-item-price, font-weight),
    get-theme-var(cart-item-price, letter-spacing)
  );

  width: get-theme-var(cart-item-price, width);
  color: get-theme-var(cart-item-price, color);
  text-align: right;
}

.#{$price-total} {
  @include font-size(
    get-theme-var(cart-item-price-total, font-size),
    get-theme-var(cart-item-price-total, line-height),
    get-theme-var(cart-item-price-total, font-weight),
    get-theme-var(cart-item-price-total, letter-spacing)
  );

  width: get-theme-var(cart-item-price-total, width);
  color: get-theme-var(cart-item-price-total, color);
  text-align: right;
}

.#{$info} {
  @include flex-column;

  justify-content: center;
  position: relative;
  letter-spacing: normal;

  @include tablet {
    align-self: flex-start;
  }

  #{mod(wider-width)} {
    width: 26.805%;
  }
}

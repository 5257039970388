@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search);
$autocomplete: el($root, autocomplete);
$results: el($root, results);
$group: ns(suggestion-queries-group);
$item: ns(suggestion-queries-item);
$product: el($item, product);
$dym: ns(spelling-correction);
$product-list: el($root, product-list);
$product-header: el($root, product-list-header);
$loading: el($root, loading);

@import '../SearchContentResultVariables';

.#{$results} {
  // desktop style 1 - one column
  #{mod(one-column)} {
    @include mobile {
      background-color: #fff;
    }

    .#{$autocomplete} {
      position: relative;
      width: $mobile-width-1;
    }

    .#{$dym} {
      @include tablet {
        padding: 20px 24px;

        &::after {
          opacity: 1;
        }
      }
    }

    .#{$product-header} {
      padding-bottom: 4px;
    }

    .#{$product-list} + .#{$group} {
      position: relative;

      &::before {
        @include pseudo(auto, 1px);

        top: 0;
        left: 24px;
        right: 24px;
        background-color: #f5f5f5;
      }
    }

    &.#{$results} {
      #{mod(product-type-grid)} {
        .#{$autocomplete} {
          width: $mobile-width-2;
        }

        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-3;
            }
          }
        }
      }

      #{mod(product-type-list)} {
        .#{$product} {
          #{mod(left)} {
            &::before {
              padding-top: $product-image-size-1;
            }
          }
        }
      }
    }

    .#{$loading} {
      width: $mobile-width-2;
    }
  }
}

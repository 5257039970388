@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(instant-search-product-list);
$items: el($root, items);

.#{$root} {
  padding: 0 8px;

  @include tablet {
    padding: 14px;
  }
}

.#{$items} {
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

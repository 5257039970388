@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(radio);
$tooltip: ns(tooltip-content);
$label: el($root, label);
$input: el($root, input);
$hide-text: el($root, label--hide-text);

.#{$root} {
  display: flex;
  position: relative;

  // avoid override tooltip component styling is used in other place
  .#{$tooltip} {
    @include font-size(
      get-theme-var(tooltip, font-size),
      get-theme-var(tooltip, line-height),
      get-theme-var(tooltip, font-weight)
    );

    font-family: get-theme-var(tooltip, font-family);
    white-space: normal;
    min-width: 24px;
    max-width: 140px;
    padding: get-theme-var(tooltip, padding-y) get-theme-var(tooltip, padding-x);
    color: get-theme-var(tooltip, color);
    border-radius: get-theme-var(tooltip, border-radius);
    box-shadow: get-theme-var(tooltip, box-shadow-x-offset),
      get-theme-var(tooltip, box-shawdow-y-offset);

    @include tablet {
      max-width: 180px;
    }
  }
}

.#{$label} {
  @include font-size(
    get-theme-var(radio-label, font-size),
    get-theme-var(radio-label, line-height)
  );
  @include truncate;

  display: inline-block;
  position: relative;
  min-width: get-theme-var(radio-label, min-width);
  max-width: 140px;
  padding: 0 10px;
  font-family: get-theme-var(radio-label, font-family);
  text-transform: get-theme-var(radio-label, text-transform);
  text-align: center;
  border: 1px solid get-theme-var(radio-label, border-color);
  border-radius: get-theme-var(radio-label, border-radius);
  background-color: get-theme-var(radio-label, background-color);
  outline: none;
  cursor: pointer;

  @include tablet {
    max-width: 180px;
  }

  &:not(#{mod(hide-text)}) {
    &:hover {
      color: get-theme-var(radio-label, color-selected);
      border-color: get-theme-var(radio-label, border-color-selected);
      background-color: get-theme-var(radio-label, background-color-selected);
    }
  }

  #{mod(non-radius)} {
    min-width: get-theme-var(radio-label-non-radius, min-width);
    padding: get-theme-var(radio-label-non-radius, padding);
    border-radius: 0;
  }

  #{mod(hide-text)} {
    @include cover-background;
    @include box(get-theme-var(radio-label-hide-text, width));

    min-width: get-theme-var(radio-label-hide-text, min-width);
    max-width: inherit;
    font-size: 0 !important;
    box-sizing: border-box;
    border: get-theme-var(radio-label-hide-text, border-width) solid
      get-theme-var(radio-label-hide-text, border-color);
    box-shadow: 0 0 0 1px get-theme-var(radio-label-hide-text, box-shadow);

    &:hover {
      box-shadow: 0 0 0 1px get-theme-var(radio-label, box-shadow-hover);
    }

    @include tablet {
      max-width: inherit;
    }
  }

  #{mod(selected)} {
    &:not(.#{$hide-text}) {
      color: get-theme-var(radio-label, color-selected);
      border-color: get-theme-var(radio-label, border-color-selected);
      background-color: get-theme-var(radio-label, background-color-selected);
    }

    &.#{$hide-text} {
      box-shadow: 0 0 0 get-theme-var(radio-label, spread-radius-selected)
        get-theme-var(radio-label, box-shadow-selected);
      border-color: #fff;
    }
  }

  #{mod(large)} {
    @include box(get-theme-var(radio-label-large, width));

    line-height: get-theme-var(radio-label-large, line-height);
    border-width: get-theme-var(radio-label-large, border-width);

    &.#{$hide-text} {
      width: get-theme-var(radio-label-large-hide-text, width);
      min-width: get-theme-var(radio-label-large-hide-text, min-width);
      line-height: get-theme-var(radio-label-large-hide-text, line-height);
      border-width: get-theme-var(radio-label-large-hide-text, border-width);
    }
  }

  #{mod(in-quick-view)} {
    min-width: get-theme-var(radio-label-large, min-width);
    max-width: 295px;
    border-color: get-theme-var(radio-label, border-color-light);
    font-size: get-theme-var(radio-label-large, font-size);
    line-height: get-theme-var(radio-label-large, line-height);
    letter-spacing: get-theme-var(radio-label-large, letter-spacing) !important;
    border-radius: get-theme-var(radio-label-large, border-radius);

    @include tablet {
      max-width: 400px;
    }
  }
}

.#{$input} {
  #{mod(hidden)} {
    display: none !important;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(product-list);
$product-item: ns(product-item);
$product-list-margin: ns(product-list--margin);
$product-list-border-no-margin: ns(product-list--border-no-margin);
$list-col: el($root, list-col);

.#{$root} {
  display: flex;
  flex-wrap: wrap;
  padding-left: get-theme-var(product-list, padding-left);
  padding-right: get-theme-var(product-list, padding-right);
  margin-bottom: get-theme-var(product-list, margin-bottom);

  &:last-child {
    margin-bottom: 0;
  }

  #{mod(margin)} {
    margin-left: calc(get-theme-var(product-list, gap-mobile) * -0.5);
    margin-right: calc(get-theme-var(product-list, gap-mobile) * -0.5);

    @include tablet {
      margin-left: calc(get-theme-var(product-list, gap-desktop) * -0.5);
      margin-right: calc(get-theme-var(product-list, gap-desktop) * -0.5);
    }
  }

  &.#{$list-col} {
    margin-left: 0;
    margin-right: 0;
    gap: get-theme-var(product-list, gap-list-view-mobile);

    @include tablet {
      gap: get-theme-var(product-list, gap-list-view-desktop);
    }
  }

  .#{$product-item} {
    #{mod(no-padding)},
    #{mod(border-no-padding-image)} {
      margin-bottom: -1px;
      margin-left: -1px;
    }
  }
}

@for $i from 1 through 6 {
  .#{$root}-grid--#{$i}-col {
    &.#{$product-list-border-no-margin} {
      margin-right: calc((calc(0.5 + $i)) * -1px);
    }

    .#{$product-item} {
      width: calc(100% / $i);

      @include tablet {
        width: calc(100% / $i);
      }
    }

    &.#{$product-list-margin} {
      .#{$product-item} {
        width: calc((100% / $i) - get-theme-var(product-list, gap-mobile));
        margin-left: calc(get-theme-var(product-list, gap-mobile) * 0.5);
        margin-right: calc(get-theme-var(product-list, gap-mobile) * 0.5);
        margin-bottom: get-theme-var(product-item, margin-bottom-mobile);

        @include tablet {
          width: calc((100% / $i) - get-theme-var(product-list, gap-desktop));
          margin-left: calc(get-theme-var(product-list, gap-desktop) * 0.5);
          margin-right: calc(get-theme-var(product-list, gap-desktop) * 0.5);
          margin-bottom: get-theme-var(product-item, margin-bottom);
        }
      }
    }
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(show-limit-list);
$label: el($root, label);
$button: el($root, button);
$list: el($root, list);
$option: el($root, option);
$border-value: 1px solid get-theme-var(toolbar, color);

.#{$root} {
  @include flex-center-vertical;

  position: relative;
  font-size: get-theme-var(toolbar-per-page, list-font-size);
  font-weight: get-theme-var(toolbar-per-page, list-font-weight);
  line-height: get-theme-var(toolbar-per-page, list-line-height);

  #{mod(inline)} {
    .#{$button} {
      display: none;
    }

    .#{$list} {
      @include flex-center-vertical;

      position: relative;
      top: 0;
      margin-left: 5px;
      padding-top: 0;
      border: 0;
    }

    .#{$option} {
      padding: 5px 10px;
      line-height: 1;
    }
  }
}

.#{$label} {
  text-transform: get-theme-var(toolbar-per-page, label-text-transform);
  font-size: get-theme-var(toolbar-per-page, label-font-size);
  font-weight: get-theme-var(toolbar-per-page, label-font-weight);
  line-height: get-theme-var(toolbar-per-page, label-line-height);
}

.#{$button} {
  position: relative;
  padding-right: 20px;
  border: 0;
  background: none;
  font-size: get-theme-var(toolbar-per-page, button-font-size);
  font-weight: get-theme-var(toolbar-per-page, button-font-weight);
  line-height: get-theme-var(toolbar-per-page, button-line-height);
  text-align: center;
  cursor: pointer;
  color: get-theme-var(toolbar, color);

  &::after {
    @include box(7px);

    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    overflow: hidden;
    margin-top: -5px;
    border-right: $border-value;
    border-bottom: $border-value;
    font-size: inherit;
    line-height: inherit;
    text-transform: none;
    white-space: nowrap;
    transition: transform 0.1s ease-in-out;
    transform: rotate(45deg);
  }

  #{mod(active)} {
    &::after {
      margin-top: -2px;
      transform: rotate(-135deg);
    }
  }
}

.#{$list} {
  position: absolute;
  top: calc(100% + 10px);
  right: 0;
  z-index: 2;
  display: none;
  height: auto;
  margin: 0;
  padding-top: get-theme-var(toolbar-per-page, padding-top-list);
  padding-left: 0;
  padding-bottom: get-theme-var(toolbar-per-page, padding-bottom-list);
  border: 1px solid get-theme-var(toolbar-per-page, border-color-list);
  border-radius: get-theme-var(toolbar-per-page, border-radius-list);
  background-color: get-theme-var(toolbar, background-color);
  color: get-theme-var(toolbar, color);
  list-style: none;

  #{mod(active)} {
    display: block;
  }
}

.#{$option} {
  padding: get-theme-var(toolbar-per-page, padding-option);
  text-align: center;
  cursor: pointer;

  &:hover,
  #{mod(active)} {
    text-decoration: get-theme-var(toolbar-per-page, text-decoration-option);
    background-color: get-theme-var(toolbar-per-page, background-color-option);
  }
}

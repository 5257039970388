@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(next-button);
$icon: el($root, icon);
$custom-el: el($root, customEl);
$button: ns(button);

.#{$root} {
  @include flex-center;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  margin: 0;
  min-width: get-theme-var(slider-button, width);
  min-height: get-theme-var(slider-button, height);

  #{mod(inside)} {
    right: 0;
  }

  #{mod(outside)} {
    right: -54px;
  }

  .#{$button} {
    background-color: get-theme-var(slider-button, background-color);
  }
}

.#{$icon} {
  @include box(16px);

  transform: rotate(90deg);
}

.#{$custom-el} {
  cursor: pointer;

  #{mod(disabled)} {
    cursor: default;
    pointer-events: none;
  }
}

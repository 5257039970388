@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;@import "~styles-kit/scss/reset.scss";/* Layout */
// Remove scrollbar when open filter tree sticky - Show all option - Don't remove it
html {
  overflow-x: inherit;
  overflow-y: inherit;
}

// Reset hard code position of some themes
body {
  position: static;
}

// Fix sticky header have z index higher filter and search - Fix filter tree floated on main menu
.section-header.shopify-section-group-header-group {
  z-index: 4;
}

main {
  min-height: 500px;

  // reset position static for <main> of some themes -> .boost-sd__filter-tree-horizontal-sticky-body > main
  .boost-sd__filter-tree-horizontal-sticky-body & {
    position: static;
  }
}

#template-collection {
  .dynamic-sections {
    display: block;
  }
}

.boost-sd {
  &__toolbar {
    @include mobile {
      padding-top: 0;
    }

    &-content {
      @include mobile {
        padding-top: 0;
      }

      @include tablet {
        border-top: 1px solid #ebebeb;
        border-bottom: 0;
      }
    }

    &-container {
      margin-bottom: 20px;

      &--style-expand {
        margin-bottom: 0;
      }
    }
  }

  &__sorting-list {
    right: 0;
  }

  /* Pagitiontion */
  &__pagination-number {
    &:hover,
    &:active,
    &--active {
      text-shadow: 0 0 0.5px #000;
    }
  }

  &__sticky-opening {
    &.boost-sd__sticky-style-expand {
      #smartwishlistfixedlink {
        z-index: 1;
      }
    }
  }
}

/* Reset style default Button */
.boost-sd-left {
  button {
    &::before,
    &::after {
      transition: none;
      background: 0 0;
      box-shadow: none;
    }
  }
}

.boost-sd-layout {
  margin: 20px auto 0;

  @include tablet {
    display: flex;
    margin: 24px auto 0;
    max-width: get-theme-var(general, page-width-desktop);
  }

  @include mobile {
    width: 100%;
    padding-left: get-theme-var(general, gutter-mobile);
    padding-right: get-theme-var(general, gutter-mobile);
  }

  .boost-sd-left {
    @include mobile {
      width: 100%;
    }

    .boost-sd__filter-tree-vertical {
      @include tablet {
        margin-left: calc(get-theme-var(filter-tree-vertical, gutter) * (-1));
        margin-right: get-theme-var(filter-tree-vertical, gutter);
      }
    }
  }

  .boost-sd-right {
    margin-bottom: 20px;
    width: 100%;

    @include tablet {
      flex: auto;
      max-width: 100%;
    }
  }

  &--has-filter-horizontal {
    @include tablet {
      margin-top: 0;
      flex-wrap: wrap;
    }

    .boost-sd__toolbar-content {
      @include tablet {
        border-top: 0;
      }
    }

    .boost-sd-left,
    .boost-sd-right {
      @include tablet {
        flex: 0 0 100%;
      }
    }

    &.boost-sd-layout--has-horizontal-style-expand {
      .boost-sd-right {
        @include tablet {
          margin-top: 40px;
        }
      }
    }
  }

  &--has-filter-vertical {
    &.boost-sd-layout--has-vertical-style-default {
      @include tablet {
        margin-top: 40px;
      }

      .boost-sd__toolbar-container {
        @include tablet {
          margin-bottom: 32px;
        }
      }

      .boost-sd__toolbar-content {
        @include tablet {
          border-top: 0;
          border-bottom: 1px solid #ebebeb;
        }
      }
    }
  }
}

/* Search Page */
.boost-sd-container-search-page {
  .boost-sd-layout {
    margin-top: -1px;

    &--has-filter-vertical {
      @include tablet {
        margin-top: 32px;
      }
    }
  }
}

.boost-sd-toolbar-product-tab {
  @include mobile {
    margin-top: -1px;
  }
}

@use "~styles-kit/scss/mixins" as *;
@use "~styles-kit/scss/functions" as *;
@use "~styles-kit/scss/theme-vars" as *;$root: ns(integration-product-rating);

.#{$root} {
  #{mod(left)} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  #{mod(center)} {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  #{mod(right)} {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .spr-badge {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  span {
    white-space: nowrap;
  }

  @include tablet {
    font-size: 15px;
  }

  @include tablet-down {
    font-size: 12px;
  }

  @include mobile {
    font-size: 10px;
  }
}
